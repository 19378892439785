/* ==========================================================================
   Borders
   ========================================================================== */


/* Centered borders
   ========================================================================== */

$amounts: (10, 20, 30, 40, 50, 60, 70, 80, 90, 100);
$unit: unquote("%");

@each $amount in $amounts {
    .border-top-centered-#{$amount},
    .border-end-centered-#{$amount},
    .border-bottom-centered-#{$amount},
    .border-start-centered-#{$amount} {
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            background: $border-color !important;
        }
    }

    .border-top-centered-#{$amount} {
        &::before {
            top: 0;
            left: calc((100 - $amount) / 2) + $unit;
            width: #{$amount} + $unit;
            height: $border-width !important;
        }

        &.border-top-centered-faded {
            &::before {
                @include gradient-x-three-colors-bg(rgba($border-color, 0), rgba($border-color, 1), 50%, rgba($border-color, 0));
            }
        }
    }

    .border-end-centered-#{$amount} {
        &::after {
            right: 0;
            top: calc((100 - $amount) / 2) + $unit;
            height: #{$amount} + $unit;
            width: $border-width !important;
        }

        &.border-end-centered-faded {
            &::after {
                @include gradient-y-three-colors-bg(rgba($border-color, 0), rgba($border-color, 1), 50%, rgba($border-color, 0));
            }
        }
    }

    .border-bottom-centered-#{$amount} {
        &::after {
            bottom: 0;
            left: calc((100 - $amount) / 2) + $unit;
            width: #{$amount} + $unit;
            height: $border-width !important;
        }

        &.border-bottom-centered-faded {
            &::after {
                @include gradient-x-three-colors-bg(rgba($border-color, 0), rgba($border-color, 1), 50%, rgba($border-color, 0));
            }
        }
    }

    .border-start-centered-#{$amount} {
        &::before {
            top: calc((100 - $amount) / 2) + $unit;
            left: 0;
            height: #{$amount} + $unit;
            width: $border-width !important;
        }

        &.border-start-centered-faded {
            &::before {
                @include gradient-y-three-colors-bg(rgba($border-color, 0), rgba($border-color, 1), 50%, rgba($border-color, 0));
            }
        }
    }
}