/* ==========================================================================
   Background video
   ========================================================================== */

.bg-video {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-width: 100%;
    pointer-events: none;
    object-fit: cover;
}