/* ==========================================================================
   Spacing
   ========================================================================== */

/* Negative margins
   ========================================================================== */
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (margin: m) {
            @each $size, $length in $spacers {
                
                .#{$abbrev}#{$infix}-neg-#{$size} { #{$prop}: $length !important; }
                .#{$abbrev}t#{$infix}-neg-#{$size},
                .#{$abbrev}y#{$infix}-neg-#{$size} {
                    #{$prop}-top: -$length !important;
                }
                .#{$abbrev}r#{$infix}-neg-#{$size},
                .#{$abbrev}x#{$infix}-neg-#{$size} {
                    #{$prop}-right: -$length !important;
                }
                .#{$abbrev}b#{$infix}-neg-#{$size},
                .#{$abbrev}y#{$infix}-neg-#{$size} {
                    #{$prop}-bottom: -$length !important;
                }
                .#{$abbrev}l#{$infix}-neg-#{$size},
                .#{$abbrev}x#{$infix}-neg-#{$size} {
                    #{$prop}-left: -$length !important;
                }
            }
        }
    }
}