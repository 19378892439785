/* ==========================================================================
   Custom variables
   ========================================================================== */


/**
 * Copy and paste the variables that...
 *
 * Example for the most commom variables:
 *
 * $primary: #d138f2;
 * $secondary: #191919;
 * $font-family-base: 'Merriweather', serif;
 */