/* ==========================================================================
   Rellax - Parallax library
   ========================================================================== */

@if $disable-parallax-on-mobile {
    @include media-breakpoint-down(lg) {
        .parallax {
            transform: translate3d(0,0,0) !important;
        }
    }
}