/* ==========================================================================
   Position
   ========================================================================== */

$shifts: 10;

@for $i from -10 through $shifts {
    .top-#{$i} {
        top: #{$i}px;
    }
    .end-#{$i} {
        top: #{$i}px;
    }
    .bottom-#{$i} {
        top: #{$i}px;
    }
    .start-#{$i} {
        top: #{$i}px;
    }
}