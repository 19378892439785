/* ==========================================================================
   Material Icons
   ========================================================================== */

.zmdi {
    display: inline-block;
    font-family: 'Material-Design-Iconic-Font';
    font-weight: normal;
    font-style: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}