/* ==========================================================================
   Faq
   ========================================================================== */

.faq {
    .card {
        padding-bottom: .5rem;
        border-left: none;
        border-right: none;
    }

    .card-header {
        padding-left: 0;
        padding-right: 0;
        border: none;
        background-color: transparent;

        button {
            padding: 0;
            font-size: 1.5rem;
            font-weight: medium;
            text-transform: none;
            color: $secondary;
            text-align: left;
            text-decoration: none;

            &::before {
                @extend .zmdi;
                content: '\f273';
                display: inline !important;
                margin-right: .35em;
                vertical-align: baseline;
                font-size: 1.5rem;
            }

            &.collapsed {
                &::before {
                    content: '\f278';
                }
            }
        }
    }
}