/*

Theme: Spyre - Slick contemporary multipurpose theme
Product Page: https://themes.getbootstrap.com/product/spyre-slick-contemporary-multipurpose-theme
Author: Webinning
Author URI: https://webinning.co.uk

---

Copyright 2018 Webinning

*/


// Functions
@import "../../node_modules/bootstrap/scss/functions";

// Variables
@import "custom/variables";
@import "spyre/variables";
@import "../../node_modules/bootstrap/scss/variables";

// Maps
@import "../../node_modules/bootstrap/scss/maps";

// Mixins
@import "../../node_modules/bootstrap/scss/mixins";
@import "spyre/mixins";
@import "spyre/utilities";
@import "../../node_modules/bootstrap/scss/utilities";

// Bootstrap components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";

// Bootstrap Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Bootstrap utilities API
@import "../../node_modules/bootstrap/scss/utilities/api";

// Spyre components
@import "spyre/background";
@import "spyre/background-text";
@import "spyre/buttons";
@import "spyre/card";
@import "spyre/faq";
@import "spyre/footer";
@import "spyre/forms";
@import "spyre/icons";
@import "spyre/links";
@import "spyre/move";
@import "spyre/navbar";
@import "spyre/preloader";
@import "spyre/reboot";
@import "spyre/root";
@import "spyre/section";
@import "spyre/shapes";
@import "spyre/type";
@import "spyre/utilities";
@import "spyre/video";

// Vendor
@import "vendor/aos";
@import "vendor/material-icons";
@import "vendor/prism";
@import "vendor/carousel";
@import "vendor/fancybox";
@import "vendor/rellax";

// Custom styles
@import "custom/styles";