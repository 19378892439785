/* ==========================================================================
   Links
   ========================================================================== */

ul {
    li {
        a {
            &.active {
                text-decoration: underline;
            }
        }
    }
}