/* ==========================================================================
   Containers calculations
   ========================================================================== */

/* Calculate container width */
@mixin make-widths-container($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            width: #{$container-max-width - $grid-gutter-width};
        }
    }
}


/* Calculate outside space of container */
@mixin make-widths-outside-container($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            width: calc(50vw - ($container-max-width / 2) + 2px);
        }
    }
}

@mixin make-widths-outside-container-no-gutters($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            width: calc(50vw - ($container-max-width / 2) + ($grid-gutter-width / 2) + 2px);
        }
    }
}