/* ==========================================================================
   Forms
   ========================================================================== */

.form-control {
    font-family: $headings-font-family;
    font-size: $input-font-size;
}

.form-control-sm {
    font-size: $input-font-size-sm;
  }
  
.form-control-lg {
    font-size: $input-font-size-lg;
}