@charset "UTF-8";
/*

Theme: Spyre - Slick contemporary multipurpose theme
Product Page: https://themes.getbootstrap.com/product/spyre-slick-contemporary-multipurpose-theme
Author: Webinning
Author URI: https://webinning.co.uk

---

Copyright 2018 Webinning

*/
/* ==========================================================================
   Custom variables
   ========================================================================== */
/**
 * Copy and paste the variables that...
 *
 * Example for the most commom variables:
 *
 * $primary: #d138f2;
 * $secondary: #191919;
 * $font-family-base: 'Merriweather', serif;
 */
/* ==========================================================================
   Bootstrap and Custom variables
   ========================================================================== */
/* ==========================================================================
   Custom and vendor variables
   ========================================================================== */
/* ==========================================================================
   Mixins
   ========================================================================== */
/* ==========================================================================
   Containers calculations
   ========================================================================== */
/* Calculate container width */
/* Calculate outside space of container */
/* ==========================================================================
   Gradients for backgrounds
   ========================================================================== */
/* ==========================================================================
   Utilities
   ========================================================================== */
/* ==========================================================================
   Borders
   ========================================================================== */
/* Centered borders
   ========================================================================== */
.border-top-centered-10,
.border-end-centered-10,
.border-bottom-centered-10,
.border-start-centered-10 {
  position: relative;
}
.border-top-centered-10::before, .border-top-centered-10::after,
.border-end-centered-10::before,
.border-end-centered-10::after,
.border-bottom-centered-10::before,
.border-bottom-centered-10::after,
.border-start-centered-10::before,
.border-start-centered-10::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-10::before {
  top: 0;
  left: 45%;
  width: 10%;
  height: 1px !important;
}
.border-top-centered-10.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-10::after {
  right: 0;
  top: 45%;
  height: 10%;
  width: 1px !important;
}
.border-end-centered-10.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-10::after {
  bottom: 0;
  left: 45%;
  width: 10%;
  height: 1px !important;
}
.border-bottom-centered-10.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-10::before {
  top: 45%;
  left: 0;
  height: 10%;
  width: 1px !important;
}
.border-start-centered-10.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-20,
.border-end-centered-20,
.border-bottom-centered-20,
.border-start-centered-20 {
  position: relative;
}
.border-top-centered-20::before, .border-top-centered-20::after,
.border-end-centered-20::before,
.border-end-centered-20::after,
.border-bottom-centered-20::before,
.border-bottom-centered-20::after,
.border-start-centered-20::before,
.border-start-centered-20::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-20::before {
  top: 0;
  left: 40%;
  width: 20%;
  height: 1px !important;
}
.border-top-centered-20.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-20::after {
  right: 0;
  top: 40%;
  height: 20%;
  width: 1px !important;
}
.border-end-centered-20.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-20::after {
  bottom: 0;
  left: 40%;
  width: 20%;
  height: 1px !important;
}
.border-bottom-centered-20.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-20::before {
  top: 40%;
  left: 0;
  height: 20%;
  width: 1px !important;
}
.border-start-centered-20.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-30,
.border-end-centered-30,
.border-bottom-centered-30,
.border-start-centered-30 {
  position: relative;
}
.border-top-centered-30::before, .border-top-centered-30::after,
.border-end-centered-30::before,
.border-end-centered-30::after,
.border-bottom-centered-30::before,
.border-bottom-centered-30::after,
.border-start-centered-30::before,
.border-start-centered-30::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-30::before {
  top: 0;
  left: 35%;
  width: 30%;
  height: 1px !important;
}
.border-top-centered-30.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-30::after {
  right: 0;
  top: 35%;
  height: 30%;
  width: 1px !important;
}
.border-end-centered-30.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-30::after {
  bottom: 0;
  left: 35%;
  width: 30%;
  height: 1px !important;
}
.border-bottom-centered-30.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-30::before {
  top: 35%;
  left: 0;
  height: 30%;
  width: 1px !important;
}
.border-start-centered-30.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-40,
.border-end-centered-40,
.border-bottom-centered-40,
.border-start-centered-40 {
  position: relative;
}
.border-top-centered-40::before, .border-top-centered-40::after,
.border-end-centered-40::before,
.border-end-centered-40::after,
.border-bottom-centered-40::before,
.border-bottom-centered-40::after,
.border-start-centered-40::before,
.border-start-centered-40::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-40::before {
  top: 0;
  left: 30%;
  width: 40%;
  height: 1px !important;
}
.border-top-centered-40.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-40::after {
  right: 0;
  top: 30%;
  height: 40%;
  width: 1px !important;
}
.border-end-centered-40.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-40::after {
  bottom: 0;
  left: 30%;
  width: 40%;
  height: 1px !important;
}
.border-bottom-centered-40.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-40::before {
  top: 30%;
  left: 0;
  height: 40%;
  width: 1px !important;
}
.border-start-centered-40.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-50,
.border-end-centered-50,
.border-bottom-centered-50,
.border-start-centered-50 {
  position: relative;
}
.border-top-centered-50::before, .border-top-centered-50::after,
.border-end-centered-50::before,
.border-end-centered-50::after,
.border-bottom-centered-50::before,
.border-bottom-centered-50::after,
.border-start-centered-50::before,
.border-start-centered-50::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-50::before {
  top: 0;
  left: 25%;
  width: 50%;
  height: 1px !important;
}
.border-top-centered-50.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-50::after {
  right: 0;
  top: 25%;
  height: 50%;
  width: 1px !important;
}
.border-end-centered-50.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-50::after {
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 1px !important;
}
.border-bottom-centered-50.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-50::before {
  top: 25%;
  left: 0;
  height: 50%;
  width: 1px !important;
}
.border-start-centered-50.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-60,
.border-end-centered-60,
.border-bottom-centered-60,
.border-start-centered-60 {
  position: relative;
}
.border-top-centered-60::before, .border-top-centered-60::after,
.border-end-centered-60::before,
.border-end-centered-60::after,
.border-bottom-centered-60::before,
.border-bottom-centered-60::after,
.border-start-centered-60::before,
.border-start-centered-60::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-60::before {
  top: 0;
  left: 20%;
  width: 60%;
  height: 1px !important;
}
.border-top-centered-60.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-60::after {
  right: 0;
  top: 20%;
  height: 60%;
  width: 1px !important;
}
.border-end-centered-60.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-60::after {
  bottom: 0;
  left: 20%;
  width: 60%;
  height: 1px !important;
}
.border-bottom-centered-60.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-60::before {
  top: 20%;
  left: 0;
  height: 60%;
  width: 1px !important;
}
.border-start-centered-60.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-70,
.border-end-centered-70,
.border-bottom-centered-70,
.border-start-centered-70 {
  position: relative;
}
.border-top-centered-70::before, .border-top-centered-70::after,
.border-end-centered-70::before,
.border-end-centered-70::after,
.border-bottom-centered-70::before,
.border-bottom-centered-70::after,
.border-start-centered-70::before,
.border-start-centered-70::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-70::before {
  top: 0;
  left: 15%;
  width: 70%;
  height: 1px !important;
}
.border-top-centered-70.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-70::after {
  right: 0;
  top: 15%;
  height: 70%;
  width: 1px !important;
}
.border-end-centered-70.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-70::after {
  bottom: 0;
  left: 15%;
  width: 70%;
  height: 1px !important;
}
.border-bottom-centered-70.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-70::before {
  top: 15%;
  left: 0;
  height: 70%;
  width: 1px !important;
}
.border-start-centered-70.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-80,
.border-end-centered-80,
.border-bottom-centered-80,
.border-start-centered-80 {
  position: relative;
}
.border-top-centered-80::before, .border-top-centered-80::after,
.border-end-centered-80::before,
.border-end-centered-80::after,
.border-bottom-centered-80::before,
.border-bottom-centered-80::after,
.border-start-centered-80::before,
.border-start-centered-80::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-80::before {
  top: 0;
  left: 10%;
  width: 80%;
  height: 1px !important;
}
.border-top-centered-80.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-80::after {
  right: 0;
  top: 10%;
  height: 80%;
  width: 1px !important;
}
.border-end-centered-80.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-80::after {
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 1px !important;
}
.border-bottom-centered-80.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-80::before {
  top: 10%;
  left: 0;
  height: 80%;
  width: 1px !important;
}
.border-start-centered-80.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-90,
.border-end-centered-90,
.border-bottom-centered-90,
.border-start-centered-90 {
  position: relative;
}
.border-top-centered-90::before, .border-top-centered-90::after,
.border-end-centered-90::before,
.border-end-centered-90::after,
.border-bottom-centered-90::before,
.border-bottom-centered-90::after,
.border-start-centered-90::before,
.border-start-centered-90::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-90::before {
  top: 0;
  left: 5%;
  width: 90%;
  height: 1px !important;
}
.border-top-centered-90.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-90::after {
  right: 0;
  top: 5%;
  height: 90%;
  width: 1px !important;
}
.border-end-centered-90.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-90::after {
  bottom: 0;
  left: 5%;
  width: 90%;
  height: 1px !important;
}
.border-bottom-centered-90.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-90::before {
  top: 5%;
  left: 0;
  height: 90%;
  width: 1px !important;
}
.border-start-centered-90.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-100,
.border-end-centered-100,
.border-bottom-centered-100,
.border-start-centered-100 {
  position: relative;
}
.border-top-centered-100::before, .border-top-centered-100::after,
.border-end-centered-100::before,
.border-end-centered-100::after,
.border-bottom-centered-100::before,
.border-bottom-centered-100::after,
.border-start-centered-100::before,
.border-start-centered-100::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-100::before {
  top: 0;
  left: 0%;
  width: 100%;
  height: 1px !important;
}
.border-top-centered-100.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-100::after {
  right: 0;
  top: 0%;
  height: 100%;
  width: 1px !important;
}
.border-end-centered-100.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-100::after {
  bottom: 0;
  left: 0%;
  width: 100%;
  height: 1px !important;
}
.border-bottom-centered-100.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-100::before {
  top: 0%;
  left: 0;
  height: 100%;
  width: 1px !important;
}
.border-start-centered-100.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

/* ==========================================================================
   Position
   ========================================================================== */
.top--10 {
  top: -10px;
}

.end--10 {
  top: -10px;
}

.bottom--10 {
  top: -10px;
}

.start--10 {
  top: -10px;
}

.top--9 {
  top: -9px;
}

.end--9 {
  top: -9px;
}

.bottom--9 {
  top: -9px;
}

.start--9 {
  top: -9px;
}

.top--8 {
  top: -8px;
}

.end--8 {
  top: -8px;
}

.bottom--8 {
  top: -8px;
}

.start--8 {
  top: -8px;
}

.top--7 {
  top: -7px;
}

.end--7 {
  top: -7px;
}

.bottom--7 {
  top: -7px;
}

.start--7 {
  top: -7px;
}

.top--6 {
  top: -6px;
}

.end--6 {
  top: -6px;
}

.bottom--6 {
  top: -6px;
}

.start--6 {
  top: -6px;
}

.top--5 {
  top: -5px;
}

.end--5 {
  top: -5px;
}

.bottom--5 {
  top: -5px;
}

.start--5 {
  top: -5px;
}

.top--4 {
  top: -4px;
}

.end--4 {
  top: -4px;
}

.bottom--4 {
  top: -4px;
}

.start--4 {
  top: -4px;
}

.top--3 {
  top: -3px;
}

.end--3 {
  top: -3px;
}

.bottom--3 {
  top: -3px;
}

.start--3 {
  top: -3px;
}

.top--2 {
  top: -2px;
}

.end--2 {
  top: -2px;
}

.bottom--2 {
  top: -2px;
}

.start--2 {
  top: -2px;
}

.top--1 {
  top: -1px;
}

.end--1 {
  top: -1px;
}

.bottom--1 {
  top: -1px;
}

.start--1 {
  top: -1px;
}

.top-0 {
  top: 0px;
}

.end-0 {
  top: 0px;
}

.bottom-0 {
  top: 0px;
}

.start-0 {
  top: 0px;
}

.top-1 {
  top: 1px;
}

.end-1 {
  top: 1px;
}

.bottom-1 {
  top: 1px;
}

.start-1 {
  top: 1px;
}

.top-2 {
  top: 2px;
}

.end-2 {
  top: 2px;
}

.bottom-2 {
  top: 2px;
}

.start-2 {
  top: 2px;
}

.top-3 {
  top: 3px;
}

.end-3 {
  top: 3px;
}

.bottom-3 {
  top: 3px;
}

.start-3 {
  top: 3px;
}

.top-4 {
  top: 4px;
}

.end-4 {
  top: 4px;
}

.bottom-4 {
  top: 4px;
}

.start-4 {
  top: 4px;
}

.top-5 {
  top: 5px;
}

.end-5 {
  top: 5px;
}

.bottom-5 {
  top: 5px;
}

.start-5 {
  top: 5px;
}

.top-6 {
  top: 6px;
}

.end-6 {
  top: 6px;
}

.bottom-6 {
  top: 6px;
}

.start-6 {
  top: 6px;
}

.top-7 {
  top: 7px;
}

.end-7 {
  top: 7px;
}

.bottom-7 {
  top: 7px;
}

.start-7 {
  top: 7px;
}

.top-8 {
  top: 8px;
}

.end-8 {
  top: 8px;
}

.bottom-8 {
  top: 8px;
}

.start-8 {
  top: 8px;
}

.top-9 {
  top: 9px;
}

.end-9 {
  top: 9px;
}

.bottom-9 {
  top: 9px;
}

.start-9 {
  top: 9px;
}

.top-10 {
  top: 10px;
}

.end-10 {
  top: 10px;
}

.bottom-10 {
  top: 10px;
}

.start-10 {
  top: 10px;
}

/* ==========================================================================
   Helpers
   ========================================================================== */
/* Overflows
   ========================================================================== */
.overflow-scroll,
.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-scroll,
.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-hidden,
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-hidden,
.overflow-y-hidden {
  overflow-y: hidden !important;
}

.oveflow-auto,
.oveflow-x-auto {
  overflow-x: auto !important;
}

.oveflow-auto,
.oveflow-y-auto {
  overflow-y: auto !important;
}

.overflow-visible,
.overflow-x-visible {
  overflow: visible !important;
}

.overflow-visible,
.overflow-y-visible {
  overflow: visible !important;
}

@media (min-width: 576px) {
  .overflow-sm-scroll,
  .overflow-x-sm-scroll {
    overflow-x: scroll !important;
  }
  .overflow-sm-scroll,
  .overflow-y-sm-scroll {
    overflow-y: scroll !important;
  }
  .overflow-sm-hidden,
  .overflow-x-sm-hidden {
    overflow-x: hidden !important;
  }
  .overflow-sm-hidden,
  .overflow-y-sm-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-sm-auto,
  .oveflow-x-sm-auto {
    overflow-x: auto !important;
  }
  .oveflow-sm-auto,
  .oveflow-y-sm-auto {
    overflow-y: auto !important;
  }
  .overflow-sm-visible,
  .overflow-x-sm-visible {
    overflow: visible !important;
  }
  .overflow-sm-visible,
  .overflow-y-sm-visible {
    overflow: visible !important;
  }
}
@media (min-width: 768px) {
  .overflow-md-scroll,
  .overflow-x-md-scroll {
    overflow-x: scroll !important;
  }
  .overflow-md-scroll,
  .overflow-y-md-scroll {
    overflow-y: scroll !important;
  }
  .overflow-md-hidden,
  .overflow-x-md-hidden {
    overflow-x: hidden !important;
  }
  .overflow-md-hidden,
  .overflow-y-md-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-md-auto,
  .oveflow-x-md-auto {
    overflow-x: auto !important;
  }
  .oveflow-md-auto,
  .oveflow-y-md-auto {
    overflow-y: auto !important;
  }
  .overflow-md-visible,
  .overflow-x-md-visible {
    overflow: visible !important;
  }
  .overflow-md-visible,
  .overflow-y-md-visible {
    overflow: visible !important;
  }
}
@media (min-width: 992px) {
  .overflow-lg-scroll,
  .overflow-x-lg-scroll {
    overflow-x: scroll !important;
  }
  .overflow-lg-scroll,
  .overflow-y-lg-scroll {
    overflow-y: scroll !important;
  }
  .overflow-lg-hidden,
  .overflow-x-lg-hidden {
    overflow-x: hidden !important;
  }
  .overflow-lg-hidden,
  .overflow-y-lg-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-lg-auto,
  .oveflow-x-lg-auto {
    overflow-x: auto !important;
  }
  .oveflow-lg-auto,
  .oveflow-y-lg-auto {
    overflow-y: auto !important;
  }
  .overflow-lg-visible,
  .overflow-x-lg-visible {
    overflow: visible !important;
  }
  .overflow-lg-visible,
  .overflow-y-lg-visible {
    overflow: visible !important;
  }
}
@media (min-width: 1200px) {
  .overflow-xl-scroll,
  .overflow-x-xl-scroll {
    overflow-x: scroll !important;
  }
  .overflow-xl-scroll,
  .overflow-y-xl-scroll {
    overflow-y: scroll !important;
  }
  .overflow-xl-hidden,
  .overflow-x-xl-hidden {
    overflow-x: hidden !important;
  }
  .overflow-xl-hidden,
  .overflow-y-xl-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-xl-auto,
  .oveflow-x-xl-auto {
    overflow-x: auto !important;
  }
  .oveflow-xl-auto,
  .oveflow-y-xl-auto {
    overflow-y: auto !important;
  }
  .overflow-xl-visible,
  .overflow-x-xl-visible {
    overflow: visible !important;
  }
  .overflow-xl-visible,
  .overflow-y-xl-visible {
    overflow: visible !important;
  }
}
@media (min-width: 1600px) {
  .overflow-xxl-scroll,
  .overflow-x-xxl-scroll {
    overflow-x: scroll !important;
  }
  .overflow-xxl-scroll,
  .overflow-y-xxl-scroll {
    overflow-y: scroll !important;
  }
  .overflow-xxl-hidden,
  .overflow-x-xxl-hidden {
    overflow-x: hidden !important;
  }
  .overflow-xxl-hidden,
  .overflow-y-xxl-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-xxl-auto,
  .oveflow-x-xxl-auto {
    overflow-x: auto !important;
  }
  .oveflow-xxl-auto,
  .oveflow-y-xxl-auto {
    overflow-y: auto !important;
  }
  .overflow-xxl-visible,
  .overflow-x-xxl-visible {
    overflow: visible !important;
  }
  .overflow-xxl-visible,
  .overflow-y-xxl-visible {
    overflow: visible !important;
  }
}
/* Extends
   ========================================================================== */
/**
 * Extend the area outside of the container.
 * For example good for extend background color
 */
.ext-s::before, .ext-s::after,
.ext-e::before,
.ext-e::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: inherit;
}

.ext-s::before {
  right: 100%;
}
@media (min-width: 576px) {
  .ext-s::before {
    width: calc(50vw - 270px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-s::before {
    width: calc(50vw - 360px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-s::before {
    width: calc(50vw - 480px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-s::before {
    width: calc(50vw - 570px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-s::before {
    width: calc(50vw - 650px + 2px);
  }
}
.ext-s.ext-t::after, .ext-s.ext-b::after {
  right: 100%;
  bottom: 100%;
  top: auto;
  height: 100%;
}
@media (min-width: 576px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 270px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 360px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 480px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 570px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 650px + 2px);
  }
}
.ext-s.ext-t.ext-h-100::after, .ext-s.ext-b.ext-h-100::after {
  height: 100%;
}
.ext-s.ext-t.ext-h-125::after, .ext-s.ext-b.ext-h-125::after {
  height: 125%;
}
.ext-s.ext-t.ext-h-150::after, .ext-s.ext-b.ext-h-150::after {
  height: 150%;
}
.ext-s.ext-t.ext-h-175::after, .ext-s.ext-b.ext-h-175::after {
  height: 175%;
}
.ext-s.ext-t.ext-h-200::after, .ext-s.ext-b.ext-h-200::after {
  height: 200%;
}
.ext-s.ext-b::after {
  bottom: auto;
  top: 100%;
}
.ext-s.ext-s-no-gutters::before {
  right: 100%;
}
@media (min-width: 576px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 270px + 15px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 360px + 15px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 480px + 15px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 570px + 15px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 650px + 15px + 2px);
  }
}
.ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
  right: 100%;
}
@media (min-width: 576px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 270px + 15px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 360px + 15px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 480px + 15px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 570px + 15px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 650px + 15px + 2px);
  }
}

.ext-e::after {
  left: 100%;
}
@media (min-width: 576px) {
  .ext-e::after {
    width: calc(50vw - 270px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-e::after {
    width: calc(50vw - 360px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-e::after {
    width: calc(50vw - 480px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-e::after {
    width: calc(50vw - 570px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-e::after {
    width: calc(50vw - 650px + 2px);
  }
}
.ext-e.ext-t::before, .ext-e.ext-b::before {
  left: 100%;
  bottom: 100%;
  top: auto;
  height: 100%;
}
@media (min-width: 576px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 270px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 360px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 480px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 570px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 650px + 2px);
  }
}
.ext-e.ext-t.ext-h-100::before, .ext-e.ext-b.ext-h-100::before {
  height: 100%;
}
.ext-e.ext-t.ext-h-125::before, .ext-e.ext-b.ext-h-125::before {
  height: 125%;
}
.ext-e.ext-t.ext-h-150::before, .ext-e.ext-b.ext-h-150::before {
  height: 150%;
}
.ext-e.ext-t.ext-h-175::before, .ext-e.ext-b.ext-h-175::before {
  height: 175%;
}
.ext-e.ext-t.ext-h-200::before, .ext-e.ext-b.ext-h-200::before {
  height: 200%;
}
.ext-e.ext-b::before {
  bottom: auto;
  top: 100%;
}
.ext-e.ext-e-no-gutters::after {
  left: 100%;
}
@media (min-width: 576px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 270px + 15px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 360px + 15px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 480px + 15px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 570px + 15px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 650px + 15px + 2px);
  }
}
.ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
  left: 100%;
}
@media (min-width: 576px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 270px + 15px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 360px + 15px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 480px + 15px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 570px + 15px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 650px + 15px + 2px);
  }
}

.release-container {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.unrelease-container {
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 576px) {
  .release-sm-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-sm-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .release-md-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-md-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .release-lg-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-lg-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .release-xl-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-xl-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1600px) {
  .release-xxl-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-xxl-container {
    margin-left: 0;
    margin-right: 0;
  }
}
/* ==========================================================================
   Opacity
   ========================================================================== */
.opacity-0 {
  opacity: 0;
}

.opacity-0-hover {
  transition: opacity 0.35s ease;
}
.opacity-0-hover:hover {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-1-hover {
  transition: opacity 0.35s ease;
}
.opacity-1-hover:hover {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-2-hover {
  transition: opacity 0.35s ease;
}
.opacity-2-hover:hover {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-3-hover {
  transition: opacity 0.35s ease;
}
.opacity-3-hover:hover {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-4-hover {
  transition: opacity 0.35s ease;
}
.opacity-4-hover:hover {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-5-hover {
  transition: opacity 0.35s ease;
}
.opacity-5-hover:hover {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-6-hover {
  transition: opacity 0.35s ease;
}
.opacity-6-hover:hover {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-7-hover {
  transition: opacity 0.35s ease;
}
.opacity-7-hover:hover {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-8-hover {
  transition: opacity 0.35s ease;
}
.opacity-8-hover:hover {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-9-hover {
  transition: opacity 0.35s ease;
}
.opacity-9-hover:hover {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

.opacity-10-hover {
  transition: opacity 0.35s ease;
}
.opacity-10-hover:hover {
  opacity: 1;
}

/* ==========================================================================
   Background overlays
   ========================================================================== */
.overlay-0::before {
  background-color: rgba(31, 39, 73, 0) !important;
}

.overlay-primary-0::before {
  background-color: rgba(241, 38, 57, 0) !important;
}

.overlay-secondary-0::before {
  background-color: rgba(31, 39, 73, 0) !important;
}

.overlay-success-0::before {
  background-color: rgba(46, 205, 117, 0) !important;
}

.overlay-info-0::before {
  background-color: rgba(65, 198, 242, 0) !important;
}

.overlay-warning-0::before {
  background-color: rgba(251, 204, 93, 0) !important;
}

.overlay-danger-0::before {
  background-color: rgba(245, 84, 84, 0) !important;
}

.overlay-light-0::before {
  background-color: rgba(245, 245, 245, 0) !important;
}

.overlay-dark-0::before {
  background-color: rgba(52, 58, 64, 0) !important;
}

.overlay-blue-light-0::before {
  background-color: rgba(243, 248, 252, 0) !important;
}

.overlay-facebook-0::before {
  background-color: rgba(59, 89, 152, 0) !important;
}

.overlay-twitter-0::before {
  background-color: rgba(29, 161, 242, 0) !important;
}

.overlay-google-plus-0::before {
  background-color: rgba(219, 68, 55, 0) !important;
}

.overlay-instagram-0::before {
  background-color: rgba(195, 42, 163, 0) !important;
}

.overlay-pinterest-0::before {
  background-color: rgba(189, 8, 28, 0) !important;
}

.overlay-youtube-0::before {
  background-color: rgba(255, 0, 0, 0) !important;
}

.overlay-vimeo-0::before {
  background-color: rgba(26, 183, 234, 0) !important;
}

.overlay-slack-0::before {
  background-color: rgba(62, 184, 144, 0) !important;
}

.overlay-dribbble-0::before {
  background-color: rgba(234, 76, 137, 0) !important;
}

.overlay-linkedin-0::before {
  background-color: rgba(0, 123, 181, 0) !important;
}

.overlay-flickr-0::before {
  background-color: rgba(244, 0, 131, 0) !important;
}

.overlay-10::before {
  background-color: rgba(31, 39, 73, 0.1) !important;
}

.overlay-primary-10::before {
  background-color: rgba(241, 38, 57, 0.1) !important;
}

.overlay-secondary-10::before {
  background-color: rgba(31, 39, 73, 0.1) !important;
}

.overlay-success-10::before {
  background-color: rgba(46, 205, 117, 0.1) !important;
}

.overlay-info-10::before {
  background-color: rgba(65, 198, 242, 0.1) !important;
}

.overlay-warning-10::before {
  background-color: rgba(251, 204, 93, 0.1) !important;
}

.overlay-danger-10::before {
  background-color: rgba(245, 84, 84, 0.1) !important;
}

.overlay-light-10::before {
  background-color: rgba(245, 245, 245, 0.1) !important;
}

.overlay-dark-10::before {
  background-color: rgba(52, 58, 64, 0.1) !important;
}

.overlay-blue-light-10::before {
  background-color: rgba(243, 248, 252, 0.1) !important;
}

.overlay-facebook-10::before {
  background-color: rgba(59, 89, 152, 0.1) !important;
}

.overlay-twitter-10::before {
  background-color: rgba(29, 161, 242, 0.1) !important;
}

.overlay-google-plus-10::before {
  background-color: rgba(219, 68, 55, 0.1) !important;
}

.overlay-instagram-10::before {
  background-color: rgba(195, 42, 163, 0.1) !important;
}

.overlay-pinterest-10::before {
  background-color: rgba(189, 8, 28, 0.1) !important;
}

.overlay-youtube-10::before {
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.overlay-vimeo-10::before {
  background-color: rgba(26, 183, 234, 0.1) !important;
}

.overlay-slack-10::before {
  background-color: rgba(62, 184, 144, 0.1) !important;
}

.overlay-dribbble-10::before {
  background-color: rgba(234, 76, 137, 0.1) !important;
}

.overlay-linkedin-10::before {
  background-color: rgba(0, 123, 181, 0.1) !important;
}

.overlay-flickr-10::before {
  background-color: rgba(244, 0, 131, 0.1) !important;
}

.overlay-20::before {
  background-color: rgba(31, 39, 73, 0.2) !important;
}

.overlay-primary-20::before {
  background-color: rgba(241, 38, 57, 0.2) !important;
}

.overlay-secondary-20::before {
  background-color: rgba(31, 39, 73, 0.2) !important;
}

.overlay-success-20::before {
  background-color: rgba(46, 205, 117, 0.2) !important;
}

.overlay-info-20::before {
  background-color: rgba(65, 198, 242, 0.2) !important;
}

.overlay-warning-20::before {
  background-color: rgba(251, 204, 93, 0.2) !important;
}

.overlay-danger-20::before {
  background-color: rgba(245, 84, 84, 0.2) !important;
}

.overlay-light-20::before {
  background-color: rgba(245, 245, 245, 0.2) !important;
}

.overlay-dark-20::before {
  background-color: rgba(52, 58, 64, 0.2) !important;
}

.overlay-blue-light-20::before {
  background-color: rgba(243, 248, 252, 0.2) !important;
}

.overlay-facebook-20::before {
  background-color: rgba(59, 89, 152, 0.2) !important;
}

.overlay-twitter-20::before {
  background-color: rgba(29, 161, 242, 0.2) !important;
}

.overlay-google-plus-20::before {
  background-color: rgba(219, 68, 55, 0.2) !important;
}

.overlay-instagram-20::before {
  background-color: rgba(195, 42, 163, 0.2) !important;
}

.overlay-pinterest-20::before {
  background-color: rgba(189, 8, 28, 0.2) !important;
}

.overlay-youtube-20::before {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.overlay-vimeo-20::before {
  background-color: rgba(26, 183, 234, 0.2) !important;
}

.overlay-slack-20::before {
  background-color: rgba(62, 184, 144, 0.2) !important;
}

.overlay-dribbble-20::before {
  background-color: rgba(234, 76, 137, 0.2) !important;
}

.overlay-linkedin-20::before {
  background-color: rgba(0, 123, 181, 0.2) !important;
}

.overlay-flickr-20::before {
  background-color: rgba(244, 0, 131, 0.2) !important;
}

.overlay-30::before {
  background-color: rgba(31, 39, 73, 0.3) !important;
}

.overlay-primary-30::before {
  background-color: rgba(241, 38, 57, 0.3) !important;
}

.overlay-secondary-30::before {
  background-color: rgba(31, 39, 73, 0.3) !important;
}

.overlay-success-30::before {
  background-color: rgba(46, 205, 117, 0.3) !important;
}

.overlay-info-30::before {
  background-color: rgba(65, 198, 242, 0.3) !important;
}

.overlay-warning-30::before {
  background-color: rgba(251, 204, 93, 0.3) !important;
}

.overlay-danger-30::before {
  background-color: rgba(245, 84, 84, 0.3) !important;
}

.overlay-light-30::before {
  background-color: rgba(245, 245, 245, 0.3) !important;
}

.overlay-dark-30::before {
  background-color: rgba(52, 58, 64, 0.3) !important;
}

.overlay-blue-light-30::before {
  background-color: rgba(243, 248, 252, 0.3) !important;
}

.overlay-facebook-30::before {
  background-color: rgba(59, 89, 152, 0.3) !important;
}

.overlay-twitter-30::before {
  background-color: rgba(29, 161, 242, 0.3) !important;
}

.overlay-google-plus-30::before {
  background-color: rgba(219, 68, 55, 0.3) !important;
}

.overlay-instagram-30::before {
  background-color: rgba(195, 42, 163, 0.3) !important;
}

.overlay-pinterest-30::before {
  background-color: rgba(189, 8, 28, 0.3) !important;
}

.overlay-youtube-30::before {
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.overlay-vimeo-30::before {
  background-color: rgba(26, 183, 234, 0.3) !important;
}

.overlay-slack-30::before {
  background-color: rgba(62, 184, 144, 0.3) !important;
}

.overlay-dribbble-30::before {
  background-color: rgba(234, 76, 137, 0.3) !important;
}

.overlay-linkedin-30::before {
  background-color: rgba(0, 123, 181, 0.3) !important;
}

.overlay-flickr-30::before {
  background-color: rgba(244, 0, 131, 0.3) !important;
}

.overlay-40::before {
  background-color: rgba(31, 39, 73, 0.4) !important;
}

.overlay-primary-40::before {
  background-color: rgba(241, 38, 57, 0.4) !important;
}

.overlay-secondary-40::before {
  background-color: rgba(31, 39, 73, 0.4) !important;
}

.overlay-success-40::before {
  background-color: rgba(46, 205, 117, 0.4) !important;
}

.overlay-info-40::before {
  background-color: rgba(65, 198, 242, 0.4) !important;
}

.overlay-warning-40::before {
  background-color: rgba(251, 204, 93, 0.4) !important;
}

.overlay-danger-40::before {
  background-color: rgba(245, 84, 84, 0.4) !important;
}

.overlay-light-40::before {
  background-color: rgba(245, 245, 245, 0.4) !important;
}

.overlay-dark-40::before {
  background-color: rgba(52, 58, 64, 0.4) !important;
}

.overlay-blue-light-40::before {
  background-color: rgba(243, 248, 252, 0.4) !important;
}

.overlay-facebook-40::before {
  background-color: rgba(59, 89, 152, 0.4) !important;
}

.overlay-twitter-40::before {
  background-color: rgba(29, 161, 242, 0.4) !important;
}

.overlay-google-plus-40::before {
  background-color: rgba(219, 68, 55, 0.4) !important;
}

.overlay-instagram-40::before {
  background-color: rgba(195, 42, 163, 0.4) !important;
}

.overlay-pinterest-40::before {
  background-color: rgba(189, 8, 28, 0.4) !important;
}

.overlay-youtube-40::before {
  background-color: rgba(255, 0, 0, 0.4) !important;
}

.overlay-vimeo-40::before {
  background-color: rgba(26, 183, 234, 0.4) !important;
}

.overlay-slack-40::before {
  background-color: rgba(62, 184, 144, 0.4) !important;
}

.overlay-dribbble-40::before {
  background-color: rgba(234, 76, 137, 0.4) !important;
}

.overlay-linkedin-40::before {
  background-color: rgba(0, 123, 181, 0.4) !important;
}

.overlay-flickr-40::before {
  background-color: rgba(244, 0, 131, 0.4) !important;
}

.overlay-50::before {
  background-color: rgba(31, 39, 73, 0.5) !important;
}

.overlay-primary-50::before {
  background-color: rgba(241, 38, 57, 0.5) !important;
}

.overlay-secondary-50::before {
  background-color: rgba(31, 39, 73, 0.5) !important;
}

.overlay-success-50::before {
  background-color: rgba(46, 205, 117, 0.5) !important;
}

.overlay-info-50::before {
  background-color: rgba(65, 198, 242, 0.5) !important;
}

.overlay-warning-50::before {
  background-color: rgba(251, 204, 93, 0.5) !important;
}

.overlay-danger-50::before {
  background-color: rgba(245, 84, 84, 0.5) !important;
}

.overlay-light-50::before {
  background-color: rgba(245, 245, 245, 0.5) !important;
}

.overlay-dark-50::before {
  background-color: rgba(52, 58, 64, 0.5) !important;
}

.overlay-blue-light-50::before {
  background-color: rgba(243, 248, 252, 0.5) !important;
}

.overlay-facebook-50::before {
  background-color: rgba(59, 89, 152, 0.5) !important;
}

.overlay-twitter-50::before {
  background-color: rgba(29, 161, 242, 0.5) !important;
}

.overlay-google-plus-50::before {
  background-color: rgba(219, 68, 55, 0.5) !important;
}

.overlay-instagram-50::before {
  background-color: rgba(195, 42, 163, 0.5) !important;
}

.overlay-pinterest-50::before {
  background-color: rgba(189, 8, 28, 0.5) !important;
}

.overlay-youtube-50::before {
  background-color: rgba(255, 0, 0, 0.5) !important;
}

.overlay-vimeo-50::before {
  background-color: rgba(26, 183, 234, 0.5) !important;
}

.overlay-slack-50::before {
  background-color: rgba(62, 184, 144, 0.5) !important;
}

.overlay-dribbble-50::before {
  background-color: rgba(234, 76, 137, 0.5) !important;
}

.overlay-linkedin-50::before {
  background-color: rgba(0, 123, 181, 0.5) !important;
}

.overlay-flickr-50::before {
  background-color: rgba(244, 0, 131, 0.5) !important;
}

.overlay-60::before {
  background-color: rgba(31, 39, 73, 0.6) !important;
}

.overlay-primary-60::before {
  background-color: rgba(241, 38, 57, 0.6) !important;
}

.overlay-secondary-60::before {
  background-color: rgba(31, 39, 73, 0.6) !important;
}

.overlay-success-60::before {
  background-color: rgba(46, 205, 117, 0.6) !important;
}

.overlay-info-60::before {
  background-color: rgba(65, 198, 242, 0.6) !important;
}

.overlay-warning-60::before {
  background-color: rgba(251, 204, 93, 0.6) !important;
}

.overlay-danger-60::before {
  background-color: rgba(245, 84, 84, 0.6) !important;
}

.overlay-light-60::before {
  background-color: rgba(245, 245, 245, 0.6) !important;
}

.overlay-dark-60::before {
  background-color: rgba(52, 58, 64, 0.6) !important;
}

.overlay-blue-light-60::before {
  background-color: rgba(243, 248, 252, 0.6) !important;
}

.overlay-facebook-60::before {
  background-color: rgba(59, 89, 152, 0.6) !important;
}

.overlay-twitter-60::before {
  background-color: rgba(29, 161, 242, 0.6) !important;
}

.overlay-google-plus-60::before {
  background-color: rgba(219, 68, 55, 0.6) !important;
}

.overlay-instagram-60::before {
  background-color: rgba(195, 42, 163, 0.6) !important;
}

.overlay-pinterest-60::before {
  background-color: rgba(189, 8, 28, 0.6) !important;
}

.overlay-youtube-60::before {
  background-color: rgba(255, 0, 0, 0.6) !important;
}

.overlay-vimeo-60::before {
  background-color: rgba(26, 183, 234, 0.6) !important;
}

.overlay-slack-60::before {
  background-color: rgba(62, 184, 144, 0.6) !important;
}

.overlay-dribbble-60::before {
  background-color: rgba(234, 76, 137, 0.6) !important;
}

.overlay-linkedin-60::before {
  background-color: rgba(0, 123, 181, 0.6) !important;
}

.overlay-flickr-60::before {
  background-color: rgba(244, 0, 131, 0.6) !important;
}

.overlay-70::before {
  background-color: rgba(31, 39, 73, 0.7) !important;
}

.overlay-primary-70::before {
  background-color: rgba(241, 38, 57, 0.7) !important;
}

.overlay-secondary-70::before {
  background-color: rgba(31, 39, 73, 0.7) !important;
}

.overlay-success-70::before {
  background-color: rgba(46, 205, 117, 0.7) !important;
}

.overlay-info-70::before {
  background-color: rgba(65, 198, 242, 0.7) !important;
}

.overlay-warning-70::before {
  background-color: rgba(251, 204, 93, 0.7) !important;
}

.overlay-danger-70::before {
  background-color: rgba(245, 84, 84, 0.7) !important;
}

.overlay-light-70::before {
  background-color: rgba(245, 245, 245, 0.7) !important;
}

.overlay-dark-70::before {
  background-color: rgba(52, 58, 64, 0.7) !important;
}

.overlay-blue-light-70::before {
  background-color: rgba(243, 248, 252, 0.7) !important;
}

.overlay-facebook-70::before {
  background-color: rgba(59, 89, 152, 0.7) !important;
}

.overlay-twitter-70::before {
  background-color: rgba(29, 161, 242, 0.7) !important;
}

.overlay-google-plus-70::before {
  background-color: rgba(219, 68, 55, 0.7) !important;
}

.overlay-instagram-70::before {
  background-color: rgba(195, 42, 163, 0.7) !important;
}

.overlay-pinterest-70::before {
  background-color: rgba(189, 8, 28, 0.7) !important;
}

.overlay-youtube-70::before {
  background-color: rgba(255, 0, 0, 0.7) !important;
}

.overlay-vimeo-70::before {
  background-color: rgba(26, 183, 234, 0.7) !important;
}

.overlay-slack-70::before {
  background-color: rgba(62, 184, 144, 0.7) !important;
}

.overlay-dribbble-70::before {
  background-color: rgba(234, 76, 137, 0.7) !important;
}

.overlay-linkedin-70::before {
  background-color: rgba(0, 123, 181, 0.7) !important;
}

.overlay-flickr-70::before {
  background-color: rgba(244, 0, 131, 0.7) !important;
}

.overlay-80::before {
  background-color: rgba(31, 39, 73, 0.8) !important;
}

.overlay-primary-80::before {
  background-color: rgba(241, 38, 57, 0.8) !important;
}

.overlay-secondary-80::before {
  background-color: rgba(31, 39, 73, 0.8) !important;
}

.overlay-success-80::before {
  background-color: rgba(46, 205, 117, 0.8) !important;
}

.overlay-info-80::before {
  background-color: rgba(65, 198, 242, 0.8) !important;
}

.overlay-warning-80::before {
  background-color: rgba(251, 204, 93, 0.8) !important;
}

.overlay-danger-80::before {
  background-color: rgba(245, 84, 84, 0.8) !important;
}

.overlay-light-80::before {
  background-color: rgba(245, 245, 245, 0.8) !important;
}

.overlay-dark-80::before {
  background-color: rgba(52, 58, 64, 0.8) !important;
}

.overlay-blue-light-80::before {
  background-color: rgba(243, 248, 252, 0.8) !important;
}

.overlay-facebook-80::before {
  background-color: rgba(59, 89, 152, 0.8) !important;
}

.overlay-twitter-80::before {
  background-color: rgba(29, 161, 242, 0.8) !important;
}

.overlay-google-plus-80::before {
  background-color: rgba(219, 68, 55, 0.8) !important;
}

.overlay-instagram-80::before {
  background-color: rgba(195, 42, 163, 0.8) !important;
}

.overlay-pinterest-80::before {
  background-color: rgba(189, 8, 28, 0.8) !important;
}

.overlay-youtube-80::before {
  background-color: rgba(255, 0, 0, 0.8) !important;
}

.overlay-vimeo-80::before {
  background-color: rgba(26, 183, 234, 0.8) !important;
}

.overlay-slack-80::before {
  background-color: rgba(62, 184, 144, 0.8) !important;
}

.overlay-dribbble-80::before {
  background-color: rgba(234, 76, 137, 0.8) !important;
}

.overlay-linkedin-80::before {
  background-color: rgba(0, 123, 181, 0.8) !important;
}

.overlay-flickr-80::before {
  background-color: rgba(244, 0, 131, 0.8) !important;
}

.overlay-90::before {
  background-color: rgba(31, 39, 73, 0.9) !important;
}

.overlay-primary-90::before {
  background-color: rgba(241, 38, 57, 0.9) !important;
}

.overlay-secondary-90::before {
  background-color: rgba(31, 39, 73, 0.9) !important;
}

.overlay-success-90::before {
  background-color: rgba(46, 205, 117, 0.9) !important;
}

.overlay-info-90::before {
  background-color: rgba(65, 198, 242, 0.9) !important;
}

.overlay-warning-90::before {
  background-color: rgba(251, 204, 93, 0.9) !important;
}

.overlay-danger-90::before {
  background-color: rgba(245, 84, 84, 0.9) !important;
}

.overlay-light-90::before {
  background-color: rgba(245, 245, 245, 0.9) !important;
}

.overlay-dark-90::before {
  background-color: rgba(52, 58, 64, 0.9) !important;
}

.overlay-blue-light-90::before {
  background-color: rgba(243, 248, 252, 0.9) !important;
}

.overlay-facebook-90::before {
  background-color: rgba(59, 89, 152, 0.9) !important;
}

.overlay-twitter-90::before {
  background-color: rgba(29, 161, 242, 0.9) !important;
}

.overlay-google-plus-90::before {
  background-color: rgba(219, 68, 55, 0.9) !important;
}

.overlay-instagram-90::before {
  background-color: rgba(195, 42, 163, 0.9) !important;
}

.overlay-pinterest-90::before {
  background-color: rgba(189, 8, 28, 0.9) !important;
}

.overlay-youtube-90::before {
  background-color: rgba(255, 0, 0, 0.9) !important;
}

.overlay-vimeo-90::before {
  background-color: rgba(26, 183, 234, 0.9) !important;
}

.overlay-slack-90::before {
  background-color: rgba(62, 184, 144, 0.9) !important;
}

.overlay-dribbble-90::before {
  background-color: rgba(234, 76, 137, 0.9) !important;
}

.overlay-linkedin-90::before {
  background-color: rgba(0, 123, 181, 0.9) !important;
}

.overlay-flickr-90::before {
  background-color: rgba(244, 0, 131, 0.9) !important;
}

.overlay-100::before {
  background-color: #1f2749 !important;
}

.overlay-primary-100::before {
  background-color: #f12639 !important;
}

.overlay-secondary-100::before {
  background-color: #1f2749 !important;
}

.overlay-success-100::before {
  background-color: #2ecd75 !important;
}

.overlay-info-100::before {
  background-color: #41c6f2 !important;
}

.overlay-warning-100::before {
  background-color: #fbcc5d !important;
}

.overlay-danger-100::before {
  background-color: #f55454 !important;
}

.overlay-light-100::before {
  background-color: whitesmoke !important;
}

.overlay-dark-100::before {
  background-color: #343a40 !important;
}

.overlay-blue-light-100::before {
  background-color: #f3f8fc !important;
}

.overlay-facebook-100::before {
  background-color: #3b5998 !important;
}

.overlay-twitter-100::before {
  background-color: #1da1f2 !important;
}

.overlay-google-plus-100::before {
  background-color: #db4437 !important;
}

.overlay-instagram-100::before {
  background-color: #c32aa3 !important;
}

.overlay-pinterest-100::before {
  background-color: #bd081c !important;
}

.overlay-youtube-100::before {
  background-color: red !important;
}

.overlay-vimeo-100::before {
  background-color: #1ab7ea !important;
}

.overlay-slack-100::before {
  background-color: #3eb890 !important;
}

.overlay-dribbble-100::before {
  background-color: #ea4c89 !important;
}

.overlay-linkedin-100::before {
  background-color: #007bb5 !important;
}

.overlay-flickr-100::before {
  background-color: #f40083 !important;
}

/* ==========================================================================
   Sizing
   ========================================================================== */
/* Height values in vh */
.minh-100vh {
  min-height: 100vh;
}

.minh-50vh {
  min-height: 50vh;
}

.h-100vh {
  height: 100vh;
}

.h-50vh {
  height: 50vh;
}

/* ==========================================================================
   Spacing
   ========================================================================== */
/* Negative margins
   ========================================================================== */
.m-neg-0 {
  margin: 0 !important;
}

.mt-neg-0,
.my-neg-0 {
  margin-top: 0 !important;
}

.mr-neg-0,
.mx-neg-0 {
  margin-right: 0 !important;
}

.mb-neg-0,
.my-neg-0 {
  margin-bottom: 0 !important;
}

.ml-neg-0,
.mx-neg-0 {
  margin-left: 0 !important;
}

.m-neg-1 {
  margin: 0.25rem !important;
}

.mt-neg-1,
.my-neg-1 {
  margin-top: -0.25rem !important;
}

.mr-neg-1,
.mx-neg-1 {
  margin-right: -0.25rem !important;
}

.mb-neg-1,
.my-neg-1 {
  margin-bottom: -0.25rem !important;
}

.ml-neg-1,
.mx-neg-1 {
  margin-left: -0.25rem !important;
}

.m-neg-2 {
  margin: 0.5rem !important;
}

.mt-neg-2,
.my-neg-2 {
  margin-top: -0.5rem !important;
}

.mr-neg-2,
.mx-neg-2 {
  margin-right: -0.5rem !important;
}

.mb-neg-2,
.my-neg-2 {
  margin-bottom: -0.5rem !important;
}

.ml-neg-2,
.mx-neg-2 {
  margin-left: -0.5rem !important;
}

.m-neg-3 {
  margin: 1rem !important;
}

.mt-neg-3,
.my-neg-3 {
  margin-top: -1rem !important;
}

.mr-neg-3,
.mx-neg-3 {
  margin-right: -1rem !important;
}

.mb-neg-3,
.my-neg-3 {
  margin-bottom: -1rem !important;
}

.ml-neg-3,
.mx-neg-3 {
  margin-left: -1rem !important;
}

.m-neg-4 {
  margin: 1.5rem !important;
}

.mt-neg-4,
.my-neg-4 {
  margin-top: -1.5rem !important;
}

.mr-neg-4,
.mx-neg-4 {
  margin-right: -1.5rem !important;
}

.mb-neg-4,
.my-neg-4 {
  margin-bottom: -1.5rem !important;
}

.ml-neg-4,
.mx-neg-4 {
  margin-left: -1.5rem !important;
}

.m-neg-5 {
  margin: 3rem !important;
}

.mt-neg-5,
.my-neg-5 {
  margin-top: -3rem !important;
}

.mr-neg-5,
.mx-neg-5 {
  margin-right: -3rem !important;
}

.mb-neg-5,
.my-neg-5 {
  margin-bottom: -3rem !important;
}

.ml-neg-5,
.mx-neg-5 {
  margin-left: -3rem !important;
}

.m-neg-6 {
  margin: 4.5rem !important;
}

.mt-neg-6,
.my-neg-6 {
  margin-top: -4.5rem !important;
}

.mr-neg-6,
.mx-neg-6 {
  margin-right: -4.5rem !important;
}

.mb-neg-6,
.my-neg-6 {
  margin-bottom: -4.5rem !important;
}

.ml-neg-6,
.mx-neg-6 {
  margin-left: -4.5rem !important;
}

.m-neg-7 {
  margin: 6rem !important;
}

.mt-neg-7,
.my-neg-7 {
  margin-top: -6rem !important;
}

.mr-neg-7,
.mx-neg-7 {
  margin-right: -6rem !important;
}

.mb-neg-7,
.my-neg-7 {
  margin-bottom: -6rem !important;
}

.ml-neg-7,
.mx-neg-7 {
  margin-left: -6rem !important;
}

.m-neg-8 {
  margin: 7.5rem !important;
}

.mt-neg-8,
.my-neg-8 {
  margin-top: -7.5rem !important;
}

.mr-neg-8,
.mx-neg-8 {
  margin-right: -7.5rem !important;
}

.mb-neg-8,
.my-neg-8 {
  margin-bottom: -7.5rem !important;
}

.ml-neg-8,
.mx-neg-8 {
  margin-left: -7.5rem !important;
}

.m-neg-9 {
  margin: 9rem !important;
}

.mt-neg-9,
.my-neg-9 {
  margin-top: -9rem !important;
}

.mr-neg-9,
.mx-neg-9 {
  margin-right: -9rem !important;
}

.mb-neg-9,
.my-neg-9 {
  margin-bottom: -9rem !important;
}

.ml-neg-9,
.mx-neg-9 {
  margin-left: -9rem !important;
}

.m-neg-10 {
  margin: 10rem !important;
}

.mt-neg-10,
.my-neg-10 {
  margin-top: -10rem !important;
}

.mr-neg-10,
.mx-neg-10 {
  margin-right: -10rem !important;
}

.mb-neg-10,
.my-neg-10 {
  margin-bottom: -10rem !important;
}

.ml-neg-10,
.mx-neg-10 {
  margin-left: -10rem !important;
}

.m-neg-11 {
  margin: 12rem !important;
}

.mt-neg-11,
.my-neg-11 {
  margin-top: -12rem !important;
}

.mr-neg-11,
.mx-neg-11 {
  margin-right: -12rem !important;
}

.mb-neg-11,
.my-neg-11 {
  margin-bottom: -12rem !important;
}

.ml-neg-11,
.mx-neg-11 {
  margin-left: -12rem !important;
}

.m-neg-12 {
  margin: 14.5rem !important;
}

.mt-neg-12,
.my-neg-12 {
  margin-top: -14.5rem !important;
}

.mr-neg-12,
.mx-neg-12 {
  margin-right: -14.5rem !important;
}

.mb-neg-12,
.my-neg-12 {
  margin-bottom: -14.5rem !important;
}

.ml-neg-12,
.mx-neg-12 {
  margin-left: -14.5rem !important;
}

.m-neg-13 {
  margin: 15rem !important;
}

.mt-neg-13,
.my-neg-13 {
  margin-top: -15rem !important;
}

.mr-neg-13,
.mx-neg-13 {
  margin-right: -15rem !important;
}

.mb-neg-13,
.my-neg-13 {
  margin-bottom: -15rem !important;
}

.ml-neg-13,
.mx-neg-13 {
  margin-left: -15rem !important;
}

.m-neg-14 {
  margin: 16rem !important;
}

.mt-neg-14,
.my-neg-14 {
  margin-top: -16rem !important;
}

.mr-neg-14,
.mx-neg-14 {
  margin-right: -16rem !important;
}

.mb-neg-14,
.my-neg-14 {
  margin-bottom: -16rem !important;
}

.ml-neg-14,
.mx-neg-14 {
  margin-left: -16rem !important;
}

.m-neg-15 {
  margin: 17rem !important;
}

.mt-neg-15,
.my-neg-15 {
  margin-top: -17rem !important;
}

.mr-neg-15,
.mx-neg-15 {
  margin-right: -17rem !important;
}

.mb-neg-15,
.my-neg-15 {
  margin-bottom: -17rem !important;
}

.ml-neg-15,
.mx-neg-15 {
  margin-left: -17rem !important;
}

@media (min-width: 576px) {
  .m-sm-neg-0 {
    margin: 0 !important;
  }
  .mt-sm-neg-0,
  .my-sm-neg-0 {
    margin-top: 0 !important;
  }
  .mr-sm-neg-0,
  .mx-sm-neg-0 {
    margin-right: 0 !important;
  }
  .mb-sm-neg-0,
  .my-sm-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-neg-0,
  .mx-sm-neg-0 {
    margin-left: 0 !important;
  }
  .m-sm-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-neg-1,
  .my-sm-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-neg-1,
  .mx-sm-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-neg-1,
  .my-sm-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-neg-1,
  .mx-sm-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-neg-2,
  .my-sm-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-neg-2,
  .mx-sm-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-neg-2,
  .my-sm-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-neg-2,
  .mx-sm-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-neg-3 {
    margin: 1rem !important;
  }
  .mt-sm-neg-3,
  .my-sm-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-sm-neg-3,
  .mx-sm-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-sm-neg-3,
  .my-sm-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-neg-3,
  .mx-sm-neg-3 {
    margin-left: -1rem !important;
  }
  .m-sm-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-neg-4,
  .my-sm-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-neg-4,
  .mx-sm-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-neg-4,
  .my-sm-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-neg-4,
  .mx-sm-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-neg-5 {
    margin: 3rem !important;
  }
  .mt-sm-neg-5,
  .my-sm-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-sm-neg-5,
  .mx-sm-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-sm-neg-5,
  .my-sm-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-neg-5,
  .mx-sm-neg-5 {
    margin-left: -3rem !important;
  }
  .m-sm-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-neg-6,
  .my-sm-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-neg-6,
  .mx-sm-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-neg-6,
  .my-sm-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-neg-6,
  .mx-sm-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-sm-neg-7 {
    margin: 6rem !important;
  }
  .mt-sm-neg-7,
  .my-sm-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-sm-neg-7,
  .mx-sm-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-sm-neg-7,
  .my-sm-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-neg-7,
  .mx-sm-neg-7 {
    margin-left: -6rem !important;
  }
  .m-sm-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-sm-neg-8,
  .my-sm-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-neg-8,
  .mx-sm-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-neg-8,
  .my-sm-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-neg-8,
  .mx-sm-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-sm-neg-9 {
    margin: 9rem !important;
  }
  .mt-sm-neg-9,
  .my-sm-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-sm-neg-9,
  .mx-sm-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-sm-neg-9,
  .my-sm-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-neg-9,
  .mx-sm-neg-9 {
    margin-left: -9rem !important;
  }
  .m-sm-neg-10 {
    margin: 10rem !important;
  }
  .mt-sm-neg-10,
  .my-sm-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-sm-neg-10,
  .mx-sm-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-sm-neg-10,
  .my-sm-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-neg-10,
  .mx-sm-neg-10 {
    margin-left: -10rem !important;
  }
  .m-sm-neg-11 {
    margin: 12rem !important;
  }
  .mt-sm-neg-11,
  .my-sm-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-sm-neg-11,
  .mx-sm-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-sm-neg-11,
  .my-sm-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-neg-11,
  .mx-sm-neg-11 {
    margin-left: -12rem !important;
  }
  .m-sm-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-sm-neg-12,
  .my-sm-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-sm-neg-12,
  .mx-sm-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-sm-neg-12,
  .my-sm-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-sm-neg-12,
  .mx-sm-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-sm-neg-13 {
    margin: 15rem !important;
  }
  .mt-sm-neg-13,
  .my-sm-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-sm-neg-13,
  .mx-sm-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-sm-neg-13,
  .my-sm-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-sm-neg-13,
  .mx-sm-neg-13 {
    margin-left: -15rem !important;
  }
  .m-sm-neg-14 {
    margin: 16rem !important;
  }
  .mt-sm-neg-14,
  .my-sm-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-sm-neg-14,
  .mx-sm-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-sm-neg-14,
  .my-sm-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-sm-neg-14,
  .mx-sm-neg-14 {
    margin-left: -16rem !important;
  }
  .m-sm-neg-15 {
    margin: 17rem !important;
  }
  .mt-sm-neg-15,
  .my-sm-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-sm-neg-15,
  .mx-sm-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-sm-neg-15,
  .my-sm-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-sm-neg-15,
  .mx-sm-neg-15 {
    margin-left: -17rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-neg-0 {
    margin: 0 !important;
  }
  .mt-md-neg-0,
  .my-md-neg-0 {
    margin-top: 0 !important;
  }
  .mr-md-neg-0,
  .mx-md-neg-0 {
    margin-right: 0 !important;
  }
  .mb-md-neg-0,
  .my-md-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-neg-0,
  .mx-md-neg-0 {
    margin-left: 0 !important;
  }
  .m-md-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-md-neg-1,
  .my-md-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-neg-1,
  .mx-md-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-neg-1,
  .my-md-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-neg-1,
  .mx-md-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-md-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-md-neg-2,
  .my-md-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-neg-2,
  .mx-md-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-neg-2,
  .my-md-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-neg-2,
  .mx-md-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-md-neg-3 {
    margin: 1rem !important;
  }
  .mt-md-neg-3,
  .my-md-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-md-neg-3,
  .mx-md-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-md-neg-3,
  .my-md-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-neg-3,
  .mx-md-neg-3 {
    margin-left: -1rem !important;
  }
  .m-md-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-md-neg-4,
  .my-md-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-neg-4,
  .mx-md-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-neg-4,
  .my-md-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-neg-4,
  .mx-md-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-md-neg-5 {
    margin: 3rem !important;
  }
  .mt-md-neg-5,
  .my-md-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-md-neg-5,
  .mx-md-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-md-neg-5,
  .my-md-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-neg-5,
  .mx-md-neg-5 {
    margin-left: -3rem !important;
  }
  .m-md-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-md-neg-6,
  .my-md-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-md-neg-6,
  .mx-md-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-md-neg-6,
  .my-md-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-neg-6,
  .mx-md-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-md-neg-7 {
    margin: 6rem !important;
  }
  .mt-md-neg-7,
  .my-md-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-md-neg-7,
  .mx-md-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-md-neg-7,
  .my-md-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-md-neg-7,
  .mx-md-neg-7 {
    margin-left: -6rem !important;
  }
  .m-md-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-md-neg-8,
  .my-md-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-md-neg-8,
  .mx-md-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-md-neg-8,
  .my-md-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-neg-8,
  .mx-md-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-md-neg-9 {
    margin: 9rem !important;
  }
  .mt-md-neg-9,
  .my-md-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-md-neg-9,
  .mx-md-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-md-neg-9,
  .my-md-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-md-neg-9,
  .mx-md-neg-9 {
    margin-left: -9rem !important;
  }
  .m-md-neg-10 {
    margin: 10rem !important;
  }
  .mt-md-neg-10,
  .my-md-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-md-neg-10,
  .mx-md-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-md-neg-10,
  .my-md-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-md-neg-10,
  .mx-md-neg-10 {
    margin-left: -10rem !important;
  }
  .m-md-neg-11 {
    margin: 12rem !important;
  }
  .mt-md-neg-11,
  .my-md-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-md-neg-11,
  .mx-md-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-md-neg-11,
  .my-md-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-md-neg-11,
  .mx-md-neg-11 {
    margin-left: -12rem !important;
  }
  .m-md-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-md-neg-12,
  .my-md-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-md-neg-12,
  .mx-md-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-md-neg-12,
  .my-md-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-md-neg-12,
  .mx-md-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-md-neg-13 {
    margin: 15rem !important;
  }
  .mt-md-neg-13,
  .my-md-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-md-neg-13,
  .mx-md-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-md-neg-13,
  .my-md-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-md-neg-13,
  .mx-md-neg-13 {
    margin-left: -15rem !important;
  }
  .m-md-neg-14 {
    margin: 16rem !important;
  }
  .mt-md-neg-14,
  .my-md-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-md-neg-14,
  .mx-md-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-md-neg-14,
  .my-md-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-md-neg-14,
  .mx-md-neg-14 {
    margin-left: -16rem !important;
  }
  .m-md-neg-15 {
    margin: 17rem !important;
  }
  .mt-md-neg-15,
  .my-md-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-md-neg-15,
  .mx-md-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-md-neg-15,
  .my-md-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-md-neg-15,
  .mx-md-neg-15 {
    margin-left: -17rem !important;
  }
}
@media (min-width: 992px) {
  .m-lg-neg-0 {
    margin: 0 !important;
  }
  .mt-lg-neg-0,
  .my-lg-neg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-neg-0,
  .mx-lg-neg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-neg-0,
  .my-lg-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-neg-0,
  .mx-lg-neg-0 {
    margin-left: 0 !important;
  }
  .m-lg-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-neg-1,
  .my-lg-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-neg-1,
  .mx-lg-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-neg-1,
  .my-lg-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-neg-1,
  .mx-lg-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-neg-2,
  .my-lg-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-neg-2,
  .mx-lg-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-neg-2,
  .my-lg-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-neg-2,
  .mx-lg-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-neg-3 {
    margin: 1rem !important;
  }
  .mt-lg-neg-3,
  .my-lg-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-lg-neg-3,
  .mx-lg-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-lg-neg-3,
  .my-lg-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-neg-3,
  .mx-lg-neg-3 {
    margin-left: -1rem !important;
  }
  .m-lg-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-neg-4,
  .my-lg-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-neg-4,
  .mx-lg-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-neg-4,
  .my-lg-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-neg-4,
  .mx-lg-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-neg-5 {
    margin: 3rem !important;
  }
  .mt-lg-neg-5,
  .my-lg-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-lg-neg-5,
  .mx-lg-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-lg-neg-5,
  .my-lg-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-neg-5,
  .mx-lg-neg-5 {
    margin-left: -3rem !important;
  }
  .m-lg-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-neg-6,
  .my-lg-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-neg-6,
  .mx-lg-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-neg-6,
  .my-lg-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-neg-6,
  .mx-lg-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-lg-neg-7 {
    margin: 6rem !important;
  }
  .mt-lg-neg-7,
  .my-lg-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-lg-neg-7,
  .mx-lg-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-lg-neg-7,
  .my-lg-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-neg-7,
  .mx-lg-neg-7 {
    margin-left: -6rem !important;
  }
  .m-lg-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-lg-neg-8,
  .my-lg-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-neg-8,
  .mx-lg-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-neg-8,
  .my-lg-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-neg-8,
  .mx-lg-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-lg-neg-9 {
    margin: 9rem !important;
  }
  .mt-lg-neg-9,
  .my-lg-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-lg-neg-9,
  .mx-lg-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-lg-neg-9,
  .my-lg-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-neg-9,
  .mx-lg-neg-9 {
    margin-left: -9rem !important;
  }
  .m-lg-neg-10 {
    margin: 10rem !important;
  }
  .mt-lg-neg-10,
  .my-lg-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-lg-neg-10,
  .mx-lg-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-lg-neg-10,
  .my-lg-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-neg-10,
  .mx-lg-neg-10 {
    margin-left: -10rem !important;
  }
  .m-lg-neg-11 {
    margin: 12rem !important;
  }
  .mt-lg-neg-11,
  .my-lg-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-lg-neg-11,
  .mx-lg-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-lg-neg-11,
  .my-lg-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-neg-11,
  .mx-lg-neg-11 {
    margin-left: -12rem !important;
  }
  .m-lg-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-lg-neg-12,
  .my-lg-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-lg-neg-12,
  .mx-lg-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-lg-neg-12,
  .my-lg-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-lg-neg-12,
  .mx-lg-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-lg-neg-13 {
    margin: 15rem !important;
  }
  .mt-lg-neg-13,
  .my-lg-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-lg-neg-13,
  .mx-lg-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-lg-neg-13,
  .my-lg-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-lg-neg-13,
  .mx-lg-neg-13 {
    margin-left: -15rem !important;
  }
  .m-lg-neg-14 {
    margin: 16rem !important;
  }
  .mt-lg-neg-14,
  .my-lg-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-lg-neg-14,
  .mx-lg-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-lg-neg-14,
  .my-lg-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-lg-neg-14,
  .mx-lg-neg-14 {
    margin-left: -16rem !important;
  }
  .m-lg-neg-15 {
    margin: 17rem !important;
  }
  .mt-lg-neg-15,
  .my-lg-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-lg-neg-15,
  .mx-lg-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-lg-neg-15,
  .my-lg-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-lg-neg-15,
  .mx-lg-neg-15 {
    margin-left: -17rem !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-neg-0 {
    margin: 0 !important;
  }
  .mt-xl-neg-0,
  .my-xl-neg-0 {
    margin-top: 0 !important;
  }
  .mr-xl-neg-0,
  .mx-xl-neg-0 {
    margin-right: 0 !important;
  }
  .mb-xl-neg-0,
  .my-xl-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-neg-0,
  .mx-xl-neg-0 {
    margin-left: 0 !important;
  }
  .m-xl-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-neg-1,
  .my-xl-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-neg-1,
  .mx-xl-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-neg-1,
  .my-xl-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-neg-1,
  .mx-xl-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-neg-2,
  .my-xl-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-neg-2,
  .mx-xl-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-neg-2,
  .my-xl-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-neg-2,
  .mx-xl-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-neg-3 {
    margin: 1rem !important;
  }
  .mt-xl-neg-3,
  .my-xl-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-xl-neg-3,
  .mx-xl-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-xl-neg-3,
  .my-xl-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-neg-3,
  .mx-xl-neg-3 {
    margin-left: -1rem !important;
  }
  .m-xl-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-neg-4,
  .my-xl-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-neg-4,
  .mx-xl-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-neg-4,
  .my-xl-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-neg-4,
  .mx-xl-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-neg-5 {
    margin: 3rem !important;
  }
  .mt-xl-neg-5,
  .my-xl-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-xl-neg-5,
  .mx-xl-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-xl-neg-5,
  .my-xl-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-neg-5,
  .mx-xl-neg-5 {
    margin-left: -3rem !important;
  }
  .m-xl-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-neg-6,
  .my-xl-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-neg-6,
  .mx-xl-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-neg-6,
  .my-xl-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-neg-6,
  .mx-xl-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-xl-neg-7 {
    margin: 6rem !important;
  }
  .mt-xl-neg-7,
  .my-xl-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-xl-neg-7,
  .mx-xl-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-xl-neg-7,
  .my-xl-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-neg-7,
  .mx-xl-neg-7 {
    margin-left: -6rem !important;
  }
  .m-xl-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-xl-neg-8,
  .my-xl-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-neg-8,
  .mx-xl-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-neg-8,
  .my-xl-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-neg-8,
  .mx-xl-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-xl-neg-9 {
    margin: 9rem !important;
  }
  .mt-xl-neg-9,
  .my-xl-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-xl-neg-9,
  .mx-xl-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-xl-neg-9,
  .my-xl-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-neg-9,
  .mx-xl-neg-9 {
    margin-left: -9rem !important;
  }
  .m-xl-neg-10 {
    margin: 10rem !important;
  }
  .mt-xl-neg-10,
  .my-xl-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-xl-neg-10,
  .mx-xl-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-xl-neg-10,
  .my-xl-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-neg-10,
  .mx-xl-neg-10 {
    margin-left: -10rem !important;
  }
  .m-xl-neg-11 {
    margin: 12rem !important;
  }
  .mt-xl-neg-11,
  .my-xl-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-xl-neg-11,
  .mx-xl-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-xl-neg-11,
  .my-xl-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-neg-11,
  .mx-xl-neg-11 {
    margin-left: -12rem !important;
  }
  .m-xl-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-xl-neg-12,
  .my-xl-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-xl-neg-12,
  .mx-xl-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-xl-neg-12,
  .my-xl-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-xl-neg-12,
  .mx-xl-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-xl-neg-13 {
    margin: 15rem !important;
  }
  .mt-xl-neg-13,
  .my-xl-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-xl-neg-13,
  .mx-xl-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-xl-neg-13,
  .my-xl-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-xl-neg-13,
  .mx-xl-neg-13 {
    margin-left: -15rem !important;
  }
  .m-xl-neg-14 {
    margin: 16rem !important;
  }
  .mt-xl-neg-14,
  .my-xl-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-xl-neg-14,
  .mx-xl-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-xl-neg-14,
  .my-xl-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-xl-neg-14,
  .mx-xl-neg-14 {
    margin-left: -16rem !important;
  }
  .m-xl-neg-15 {
    margin: 17rem !important;
  }
  .mt-xl-neg-15,
  .my-xl-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-xl-neg-15,
  .mx-xl-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-xl-neg-15,
  .my-xl-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-xl-neg-15,
  .mx-xl-neg-15 {
    margin-left: -17rem !important;
  }
}
@media (min-width: 1600px) {
  .m-xxl-neg-0 {
    margin: 0 !important;
  }
  .mt-xxl-neg-0,
  .my-xxl-neg-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-neg-0,
  .mx-xxl-neg-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-neg-0,
  .my-xxl-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-neg-0,
  .mx-xxl-neg-0 {
    margin-left: 0 !important;
  }
  .m-xxl-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-neg-1,
  .my-xxl-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-neg-1,
  .mx-xxl-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-neg-1,
  .my-xxl-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-neg-1,
  .mx-xxl-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-neg-2,
  .my-xxl-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-neg-2,
  .mx-xxl-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-neg-2,
  .my-xxl-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-neg-2,
  .mx-xxl-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-neg-3 {
    margin: 1rem !important;
  }
  .mt-xxl-neg-3,
  .my-xxl-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-neg-3,
  .mx-xxl-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-neg-3,
  .my-xxl-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-neg-3,
  .mx-xxl-neg-3 {
    margin-left: -1rem !important;
  }
  .m-xxl-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-neg-4,
  .my-xxl-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-neg-4,
  .mx-xxl-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-neg-4,
  .my-xxl-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-neg-4,
  .mx-xxl-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-neg-5 {
    margin: 3rem !important;
  }
  .mt-xxl-neg-5,
  .my-xxl-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-neg-5,
  .mx-xxl-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-neg-5,
  .my-xxl-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-neg-5,
  .mx-xxl-neg-5 {
    margin-left: -3rem !important;
  }
  .m-xxl-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-xxl-neg-6,
  .my-xxl-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-xxl-neg-6,
  .mx-xxl-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-neg-6,
  .my-xxl-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xxl-neg-6,
  .mx-xxl-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-xxl-neg-7 {
    margin: 6rem !important;
  }
  .mt-xxl-neg-7,
  .my-xxl-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-xxl-neg-7,
  .mx-xxl-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-xxl-neg-7,
  .my-xxl-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-xxl-neg-7,
  .mx-xxl-neg-7 {
    margin-left: -6rem !important;
  }
  .m-xxl-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-xxl-neg-8,
  .my-xxl-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-xxl-neg-8,
  .mx-xxl-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-xxl-neg-8,
  .my-xxl-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xxl-neg-8,
  .mx-xxl-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-xxl-neg-9 {
    margin: 9rem !important;
  }
  .mt-xxl-neg-9,
  .my-xxl-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-xxl-neg-9,
  .mx-xxl-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-xxl-neg-9,
  .my-xxl-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-xxl-neg-9,
  .mx-xxl-neg-9 {
    margin-left: -9rem !important;
  }
  .m-xxl-neg-10 {
    margin: 10rem !important;
  }
  .mt-xxl-neg-10,
  .my-xxl-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-xxl-neg-10,
  .mx-xxl-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-xxl-neg-10,
  .my-xxl-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-xxl-neg-10,
  .mx-xxl-neg-10 {
    margin-left: -10rem !important;
  }
  .m-xxl-neg-11 {
    margin: 12rem !important;
  }
  .mt-xxl-neg-11,
  .my-xxl-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-xxl-neg-11,
  .mx-xxl-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-xxl-neg-11,
  .my-xxl-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-xxl-neg-11,
  .mx-xxl-neg-11 {
    margin-left: -12rem !important;
  }
  .m-xxl-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-xxl-neg-12,
  .my-xxl-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-xxl-neg-12,
  .mx-xxl-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-xxl-neg-12,
  .my-xxl-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-xxl-neg-12,
  .mx-xxl-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-xxl-neg-13 {
    margin: 15rem !important;
  }
  .mt-xxl-neg-13,
  .my-xxl-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-xxl-neg-13,
  .mx-xxl-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-xxl-neg-13,
  .my-xxl-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-xxl-neg-13,
  .mx-xxl-neg-13 {
    margin-left: -15rem !important;
  }
  .m-xxl-neg-14 {
    margin: 16rem !important;
  }
  .mt-xxl-neg-14,
  .my-xxl-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-xxl-neg-14,
  .mx-xxl-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-xxl-neg-14,
  .my-xxl-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-xxl-neg-14,
  .mx-xxl-neg-14 {
    margin-left: -16rem !important;
  }
  .m-xxl-neg-15 {
    margin: 17rem !important;
  }
  .mt-xxl-neg-15,
  .my-xxl-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-xxl-neg-15,
  .mx-xxl-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-xxl-neg-15,
  .my-xxl-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-xxl-neg-15,
  .mx-xxl-neg-15 {
    margin-left: -17rem !important;
  }
}
/* ==========================================================================
   Text
   ========================================================================== */
/* Color
========================================================================== */
.text-100 {
  color: #f5f5f5 !important;
}

.text-200 {
  color: #e8e8e9 !important;
}

.text-300 {
  color: #d2d4db !important;
}

.text-400 {
  color: #cdced3 !important;
}

.text-500 {
  color: #87888d !important;
}

.text-600 {
  color: #6c757d !important;
}

.text-700 {
  color: #5a5c63 !important;
}

.text-800 {
  color: #343a40 !important;
}

.text-900 {
  color: #1a1e2c !important;
}

/* Weight
   ========================================================================== */
.fw-thin {
  font-weight: 100 !important;
}

.fw-extra-light {
  font-weight: 200 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-extra-bold {
  font-weight: 800 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.lh-10 {
  line-height: 1;
}

.lh-11 {
  line-height: 1.1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-13 {
  line-height: 1.3;
}

.lh-14 {
  line-height: 1.4;
}

.lh-15 {
  line-height: 1.5;
}

.lh-16 {
  line-height: 1.6;
}

.lh-17 {
  line-height: 1.7;
}

.lh-18 {
  line-height: 1.8;
}

.lh-19 {
  line-height: 1.9;
}

.lh-20 {
  line-height: 2;
}

/* ==========================================================================
   Z-index
   ========================================================================== */
.zindex--1 {
  z-index: -1 !important;
}

.zindex-0 {
  z-index: 0 !important;
}

.zindex-1 {
  z-index: 1 !important;
}

:root {
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #f55454;
  --bs-orange: #fd7e14;
  --bs-yellow: #fbcc5d;
  --bs-green: #2ecd75;
  --bs-teal: #20c997;
  --bs-cyan: #41c6f2;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-blue-light: #f3f8fc;
  --bs-gray-100: #f5f5f5;
  --bs-gray-200: #e8e8e9;
  --bs-gray-300: #d2d4db;
  --bs-gray-400: #cdced3;
  --bs-gray-500: #87888d;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #5a5c63;
  --bs-gray-800: #343a40;
  --bs-gray-900: #1a1e2c;
  --bs-primary: #f12639;
  --bs-secondary: #1f2749;
  --bs-success: #2ecd75;
  --bs-info: #41c6f2;
  --bs-warning: #fbcc5d;
  --bs-danger: #f55454;
  --bs-light: #f5f5f5;
  --bs-dark: #343a40;
  --bs-blue-light: #f3f8fc;
  --bs-facebook: #3b5998;
  --bs-twitter: #1da1f2;
  --bs-google-plus: #db4437;
  --bs-instagram: #c32aa3;
  --bs-pinterest: #bd081c;
  --bs-youtube: #ff0000;
  --bs-vimeo: #1ab7ea;
  --bs-slack: #3eb890;
  --bs-dribbble: #ea4c89;
  --bs-linkedin: #007bb5;
  --bs-flickr: #f40083;
  --bs-primary-rgb: 241, 38, 57;
  --bs-secondary-rgb: 31, 39, 73;
  --bs-success-rgb: 46, 205, 117;
  --bs-info-rgb: 65, 198, 242;
  --bs-warning-rgb: 251, 204, 93;
  --bs-danger-rgb: 245, 84, 84;
  --bs-light-rgb: 245, 245, 245;
  --bs-dark-rgb: 52, 58, 64;
  --bs-blue-light-rgb: 243, 248, 252;
  --bs-facebook-rgb: 59, 89, 152;
  --bs-twitter-rgb: 29, 161, 242;
  --bs-google-plus-rgb: 219, 68, 55;
  --bs-instagram-rgb: 195, 42, 163;
  --bs-pinterest-rgb: 189, 8, 28;
  --bs-youtube-rgb: 255, 0, 0;
  --bs-vimeo-rgb: 26, 183, 234;
  --bs-slack-rgb: 62, 184, 144;
  --bs-dribbble-rgb: 234, 76, 137;
  --bs-linkedin-rgb: 0, 123, 181;
  --bs-flickr-rgb: 244, 0, 131;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 26, 30, 44;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Roboto", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Roboto, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1.125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1a1e2c;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d2d4db;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.3rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #f12639;
  --bs-link-hover-color: #b51d2b;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fcf8e3;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Oswald", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.8125rem;
}

h2, .h2 {
  font-size: 2.25rem;
}

h3, .h3 {
  font-size: 1.96875rem;
}

h4, .h4 {
  font-size: 1.6875rem;
}

h5, .h5 {
  font-size: 1.40625rem;
}

h6, .h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 83.35%;
}

mark, .mark {
  padding: 0.2em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%;
  color: #1a1e2c;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 87.5%;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #1a1e2c;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.40625rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-5 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-6 {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 83.35%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 83.35%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #d2d4db;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.9375rem;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1600px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1300px;
  }
}
.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 6rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 6rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 7.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 7.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 9rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 9rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 10rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 10rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 12rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 12rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 14.5rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 14.5rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 15rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 15rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 16rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 16rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 17rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 17rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 9rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 9rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 12rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 12rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 14.5rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 14.5rem;
  }
  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 15rem;
  }
  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 15rem;
  }
  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 16rem;
  }
  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 16rem;
  }
  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 17rem;
  }
  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 17rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 6rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 6rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 9rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 9rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 10rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 10rem;
  }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 12rem;
  }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 12rem;
  }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 14.5rem;
  }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 14.5rem;
  }
  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 15rem;
  }
  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 15rem;
  }
  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 16rem;
  }
  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 16rem;
  }
  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 17rem;
  }
  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 17rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 9rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 9rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 12rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 12rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 14.5rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 14.5rem;
  }
  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 15rem;
  }
  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 15rem;
  }
  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 16rem;
  }
  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 16rem;
  }
  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 17rem;
  }
  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 17rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 9rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 9rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 10rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 12rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 12rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 14.5rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 14.5rem;
  }
  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 15rem;
  }
  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 15rem;
  }
  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 16rem;
  }
  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 16rem;
  }
  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 17rem;
  }
  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 17rem;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 9rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 9rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 12rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 12rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 14.5rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 14.5rem;
  }
  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 15rem;
  }
  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 15rem;
  }
  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 16rem;
  }
  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 16rem;
  }
  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 17rem;
  }
  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 17rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: #d2d4db;
  --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.075);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #1a1e2c;
  --bs-table-bg: #fcd4d7;
  --bs-table-border-color: #e5c2c6;
  --bs-table-striped-bg: #f1cbce;
  --bs-table-striped-color: #1a1e2c;
  --bs-table-active-bg: #e5c2c6;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #ebc6ca;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #1a1e2c;
  --bs-table-bg: #d2d4db;
  --bs-table-border-color: #c0c2ca;
  --bs-table-striped-bg: #c9cbd2;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #c0c2ca;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #c4c6ce;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #1a1e2c;
  --bs-table-bg: #d5f5e3;
  --bs-table-border-color: #c2e0d1;
  --bs-table-striped-bg: #cceada;
  --bs-table-striped-color: #1a1e2c;
  --bs-table-active-bg: #c2e0d1;
  --bs-table-active-color: #1a1e2c;
  --bs-table-hover-bg: #c7e5d5;
  --bs-table-hover-color: #1a1e2c;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #1a1e2c;
  --bs-table-bg: #d9f4fc;
  --bs-table-border-color: #c6dfe7;
  --bs-table-striped-bg: #cfe9f2;
  --bs-table-striped-color: #1a1e2c;
  --bs-table-active-bg: #c6dfe7;
  --bs-table-active-color: #1a1e2c;
  --bs-table-hover-bg: #cbe4ec;
  --bs-table-hover-color: #1a1e2c;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #1a1e2c;
  --bs-table-bg: #fef5df;
  --bs-table-border-color: #e7e0cd;
  --bs-table-striped-bg: #f3ead6;
  --bs-table-striped-color: #1a1e2c;
  --bs-table-active-bg: #e7e0cd;
  --bs-table-active-color: #1a1e2c;
  --bs-table-hover-bg: #ede5d2;
  --bs-table-hover-color: #1a1e2c;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #1a1e2c;
  --bs-table-bg: #fddddd;
  --bs-table-border-color: #e6cacb;
  --bs-table-striped-bg: #f2d3d4;
  --bs-table-striped-color: #1a1e2c;
  --bs-table-active-bg: #e6cacb;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #eccfd0;
  --bs-table-hover-color: #1a1e2c;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #1a1e2c;
  --bs-table-bg: #f5f5f5;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #eaeaeb;
  --bs-table-striped-color: #1a1e2c;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #1a1e2c;
  --bs-table-hover-bg: #e5e5e6;
  --bs-table-hover-color: #1a1e2c;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #343a40;
  --bs-table-border-color: #484e53;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.2;
}

.col-form-label-lg {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  font-size: 1.125rem;
}

.col-form-label-sm {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 83.35%;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.8rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #343a40;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cdced3;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #343a40;
  background-color: #fff;
  border-color: #f55c6b;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(241, 38, 57, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.2em;
}
.form-control::placeholder {
  color: #87888d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e8e8e9;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.8rem 0.75rem;
  margin: -0.8rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #343a40;
  background-color: #e8e8e9;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dcdcdd;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.8rem 0;
  margin-bottom: 0;
  line-height: 1.2;
  color: #1a1e2c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(2.18828125rem + 2px);
  padding: 0.8rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0;
}
.form-control-sm::file-selector-button {
  padding: 0.8rem 0.5rem;
  margin: -0.8rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(3.750375rem + 2px);
  padding: 0.8rem 1rem;
  font-size: 1.125rem;
  border-radius: 0;
}
.form-control-lg::file-selector-button {
  padding: 0.8rem 1rem;
  margin: -0.8rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(2.9375rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(2.18828125rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(3.750375rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(2.9375rem + 2px);
  padding: 0.8rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0;
}
.form-control-color.form-control-sm {
  height: calc(2.18828125rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(3.750375rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.8rem 2.25rem 0.8rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #343a40;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #cdced3;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #f55c6b;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(241, 38, 57, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e8e8e9;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #343a40;
}

.form-select-sm {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0;
}

.form-select-lg {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  font-size: 1.125rem;
  border-radius: 0;
}

.form-check {
  display: block;
  min-height: 1.6875rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #f55c6b;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(241, 38, 57, 0.25);
}
.form-check-input:checked {
  background-color: #f12639;
  border-color: #f12639;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #f12639;
  border-color: #f12639;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f55c6b'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(241, 38, 57, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(241, 38, 57, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #f12639;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fbbec4;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d2d4db;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #f12639;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fbbec4;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d2d4db;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #87888d;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #87888d;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #343a40;
  text-align: center;
  white-space: nowrap;
  background-color: #e8e8e9;
  border: 1px solid #cdced3;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.8rem 1rem;
  font-size: 1.125rem;
  border-radius: 0;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.8rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 83.35%;
  color: #2ecd75;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.9421875rem;
  color: #fff;
  background-color: rgba(46, 205, 117, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2ecd75;
  padding-right: 2.9375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ecd75' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.4rem) center;
  background-size: calc(0.6em + 0.8rem) calc(0.6em + 0.8rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #2ecd75;
  box-shadow: 0 0 0 0.2rem rgba(46, 205, 117, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.9375rem;
  background-position: top calc(0.3em + 0.4rem) right calc(0.3em + 0.4rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #2ecd75;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ecd75' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.6em + 0.8rem) calc(0.6em + 0.8rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #2ecd75;
  box-shadow: 0 0 0 0.2rem rgba(46, 205, 117, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: 5.9375rem;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #2ecd75;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #2ecd75;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 205, 117, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2ecd75;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 83.35%;
  color: #f55454;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.9421875rem;
  color: #fff;
  background-color: rgba(245, 84, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f55454;
  padding-right: 2.9375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f55454' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f55454' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.3em + 0.4rem) center;
  background-size: calc(0.6em + 0.8rem) calc(0.6em + 0.8rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f55454;
  box-shadow: 0 0 0 0.2rem rgba(245, 84, 84, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.9375rem;
  background-position: top calc(0.3em + 0.4rem) right calc(0.3em + 0.4rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f55454;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f55454' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f55454' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.6em + 0.8rem) calc(0.6em + 0.8rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #f55454;
  box-shadow: 0 0 0 0.2rem rgba(245, 84, 84, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: 5.9375rem;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f55454;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #f55454;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 84, 84, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f55454;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.625rem;
  --bs-btn-font-family: Oswald, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #1a1e2c;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f12639;
  --bs-btn-border-color: #f12639;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cd2030;
  --bs-btn-hover-border-color: #c11e2e;
  --bs-btn-focus-shadow-rgb: 243, 71, 87;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c11e2e;
  --bs-btn-active-border-color: #b51d2b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f12639;
  --bs-btn-disabled-border-color: #f12639;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1f2749;
  --bs-btn-border-color: #1f2749;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1a213e;
  --bs-btn-hover-border-color: #191f3a;
  --bs-btn-focus-shadow-rgb: 65, 71, 100;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #191f3a;
  --bs-btn-active-border-color: #171d37;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1f2749;
  --bs-btn-disabled-border-color: #1f2749;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2ecd75;
  --bs-btn-border-color: #2ecd75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #27ae63;
  --bs-btn-hover-border-color: #25a45e;
  --bs-btn-focus-shadow-rgb: 77, 213, 138;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #25a45e;
  --bs-btn-active-border-color: #239a58;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2ecd75;
  --bs-btn-disabled-border-color: #2ecd75;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #41c6f2;
  --bs-btn-border-color: #41c6f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #37a8ce;
  --bs-btn-hover-border-color: #349ec2;
  --bs-btn-focus-shadow-rgb: 94, 207, 244;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #349ec2;
  --bs-btn-active-border-color: #3195b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #41c6f2;
  --bs-btn-disabled-border-color: #41c6f2;
}

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fbcc5d;
  --bs-btn-border-color: #fbcc5d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d5ad4f;
  --bs-btn-hover-border-color: #c9a34a;
  --bs-btn-focus-shadow-rgb: 252, 212, 117;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c9a34a;
  --bs-btn-active-border-color: #bc9946;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fbcc5d;
  --bs-btn-disabled-border-color: #fbcc5d;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f55454;
  --bs-btn-border-color: #f55454;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d04747;
  --bs-btn-hover-border-color: #c44343;
  --bs-btn-focus-shadow-rgb: 247, 110, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c44343;
  --bs-btn-active-border-color: #b83f3f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f55454;
  --bs-btn-disabled-border-color: #f55454;
}

.btn-light {
  --bs-btn-color: #1a1e2c;
  --bs-btn-bg: #f5f5f5;
  --bs-btn-border-color: #f5f5f5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d0d0d0;
  --bs-btn-hover-border-color: #c4c4c4;
  --bs-btn-focus-shadow-rgb: 212, 213, 215;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c4c4c4;
  --bs-btn-active-border-color: #b8b8b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1a1e2c;
  --bs-btn-disabled-bg: #f5f5f5;
  --bs-btn-disabled-border-color: #f5f5f5;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #52585d;
  --bs-btn-hover-border-color: #484e53;
  --bs-btn-focus-shadow-rgb: 82, 88, 93;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5d6166;
  --bs-btn-active-border-color: #484e53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #343a40;
  --bs-btn-disabled-border-color: #343a40;
}

.btn-blue-light {
  --bs-btn-color: #1a1e2c;
  --bs-btn-bg: #f3f8fc;
  --bs-btn-border-color: #f3f8fc;
  --bs-btn-hover-color: #1a1e2c;
  --bs-btn-hover-bg: #f5f9fc;
  --bs-btn-hover-border-color: #f4f9fc;
  --bs-btn-focus-shadow-rgb: 210, 215, 221;
  --bs-btn-active-color: #1a1e2c;
  --bs-btn-active-bg: #f5f9fd;
  --bs-btn-active-border-color: #f4f9fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1a1e2c;
  --bs-btn-disabled-bg: #f3f8fc;
  --bs-btn-disabled-border-color: #f3f8fc;
}

.btn-facebook {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3b5998;
  --bs-btn-border-color: #3b5998;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #324c81;
  --bs-btn-hover-border-color: #2f477a;
  --bs-btn-focus-shadow-rgb: 88, 114, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2f477a;
  --bs-btn-active-border-color: #2c4372;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3b5998;
  --bs-btn-disabled-border-color: #3b5998;
}

.btn-twitter {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1da1f2;
  --bs-btn-border-color: #1da1f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1989ce;
  --bs-btn-hover-border-color: #1781c2;
  --bs-btn-focus-shadow-rgb: 63, 175, 244;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1781c2;
  --bs-btn-active-border-color: #1679b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1da1f2;
  --bs-btn-disabled-border-color: #1da1f2;
}

.btn-google-plus {
  --bs-btn-color: #fff;
  --bs-btn-bg: #db4437;
  --bs-btn-border-color: #db4437;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ba3a2f;
  --bs-btn-hover-border-color: #af362c;
  --bs-btn-focus-shadow-rgb: 224, 96, 85;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #af362c;
  --bs-btn-active-border-color: #a43329;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #db4437;
  --bs-btn-disabled-border-color: #db4437;
}

.btn-instagram {
  --bs-btn-color: #fff;
  --bs-btn-bg: #c32aa3;
  --bs-btn-border-color: #c32aa3;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a6248b;
  --bs-btn-hover-border-color: #9c2282;
  --bs-btn-focus-shadow-rgb: 204, 74, 177;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #9c2282;
  --bs-btn-active-border-color: #92207a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #c32aa3;
  --bs-btn-disabled-border-color: #c32aa3;
}

.btn-pinterest {
  --bs-btn-color: #fff;
  --bs-btn-bg: #bd081c;
  --bs-btn-border-color: #bd081c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a10718;
  --bs-btn-hover-border-color: #970616;
  --bs-btn-focus-shadow-rgb: 199, 45, 62;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #970616;
  --bs-btn-active-border-color: #8e0615;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #bd081c;
  --bs-btn-disabled-border-color: #bd081c;
}

.btn-youtube {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ff0000;
  --bs-btn-border-color: #ff0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d90000;
  --bs-btn-hover-border-color: #cc0000;
  --bs-btn-focus-shadow-rgb: 255, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc0000;
  --bs-btn-active-border-color: #bf0000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ff0000;
  --bs-btn-disabled-border-color: #ff0000;
}

.btn-vimeo {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1ab7ea;
  --bs-btn-border-color: #1ab7ea;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #169cc7;
  --bs-btn-hover-border-color: #1592bb;
  --bs-btn-focus-shadow-rgb: 60, 194, 237;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1592bb;
  --bs-btn-active-border-color: #1489b0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1ab7ea;
  --bs-btn-disabled-border-color: #1ab7ea;
}

.btn-slack {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3eb890;
  --bs-btn-border-color: #3eb890;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #359c7a;
  --bs-btn-hover-border-color: #329373;
  --bs-btn-focus-shadow-rgb: 91, 195, 161;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #329373;
  --bs-btn-active-border-color: #2f8a6c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3eb890;
  --bs-btn-disabled-border-color: #3eb890;
}

.btn-dribbble {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ea4c89;
  --bs-btn-border-color: #ea4c89;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c74174;
  --bs-btn-hover-border-color: #bb3d6e;
  --bs-btn-focus-shadow-rgb: 237, 103, 155;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #bb3d6e;
  --bs-btn-active-border-color: #b03967;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ea4c89;
  --bs-btn-disabled-border-color: #ea4c89;
}

.btn-linkedin {
  --bs-btn-color: #fff;
  --bs-btn-bg: #007bb5;
  --bs-btn-border-color: #007bb5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00699a;
  --bs-btn-hover-border-color: #006291;
  --bs-btn-focus-shadow-rgb: 38, 143, 192;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #006291;
  --bs-btn-active-border-color: #005c88;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #007bb5;
  --bs-btn-disabled-border-color: #007bb5;
}

.btn-flickr {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f40083;
  --bs-btn-border-color: #f40083;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cf006f;
  --bs-btn-hover-border-color: #c30069;
  --bs-btn-focus-shadow-rgb: 246, 38, 150;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c30069;
  --bs-btn-active-border-color: #b70062;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f40083;
  --bs-btn-disabled-border-color: #f40083;
}

.btn-outline-primary {
  --bs-btn-color: #f12639;
  --bs-btn-border-color: #f12639;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f12639;
  --bs-btn-hover-border-color: #f12639;
  --bs-btn-focus-shadow-rgb: 241, 38, 57;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f12639;
  --bs-btn-active-border-color: #f12639;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f12639;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f12639;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #1f2749;
  --bs-btn-border-color: #1f2749;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1f2749;
  --bs-btn-hover-border-color: #1f2749;
  --bs-btn-focus-shadow-rgb: 31, 39, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1f2749;
  --bs-btn-active-border-color: #1f2749;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1f2749;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1f2749;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #2ecd75;
  --bs-btn-border-color: #2ecd75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2ecd75;
  --bs-btn-hover-border-color: #2ecd75;
  --bs-btn-focus-shadow-rgb: 46, 205, 117;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2ecd75;
  --bs-btn-active-border-color: #2ecd75;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2ecd75;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2ecd75;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #41c6f2;
  --bs-btn-border-color: #41c6f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #41c6f2;
  --bs-btn-hover-border-color: #41c6f2;
  --bs-btn-focus-shadow-rgb: 65, 198, 242;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #41c6f2;
  --bs-btn-active-border-color: #41c6f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #41c6f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #41c6f2;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #fbcc5d;
  --bs-btn-border-color: #fbcc5d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fbcc5d;
  --bs-btn-hover-border-color: #fbcc5d;
  --bs-btn-focus-shadow-rgb: 251, 204, 93;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fbcc5d;
  --bs-btn-active-border-color: #fbcc5d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fbcc5d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fbcc5d;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #f55454;
  --bs-btn-border-color: #f55454;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f55454;
  --bs-btn-hover-border-color: #f55454;
  --bs-btn-focus-shadow-rgb: 245, 84, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f55454;
  --bs-btn-active-border-color: #f55454;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f55454;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f55454;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f5f5f5;
  --bs-btn-border-color: #f5f5f5;
  --bs-btn-hover-color: #1a1e2c;
  --bs-btn-hover-bg: #f5f5f5;
  --bs-btn-hover-border-color: #f5f5f5;
  --bs-btn-focus-shadow-rgb: 245, 245, 245;
  --bs-btn-active-color: #1a1e2c;
  --bs-btn-active-bg: #f5f5f5;
  --bs-btn-active-border-color: #f5f5f5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5f5f5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5f5f5;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #343a40;
  --bs-btn-hover-border-color: #343a40;
  --bs-btn-focus-shadow-rgb: 52, 58, 64;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #343a40;
  --bs-btn-active-border-color: #343a40;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #343a40;
  --bs-gradient: none;
}

.btn-outline-blue-light {
  --bs-btn-color: #f3f8fc;
  --bs-btn-border-color: #f3f8fc;
  --bs-btn-hover-color: #1a1e2c;
  --bs-btn-hover-bg: #f3f8fc;
  --bs-btn-hover-border-color: #f3f8fc;
  --bs-btn-focus-shadow-rgb: 243, 248, 252;
  --bs-btn-active-color: #1a1e2c;
  --bs-btn-active-bg: #f3f8fc;
  --bs-btn-active-border-color: #f3f8fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f3f8fc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f3f8fc;
  --bs-gradient: none;
}

.btn-outline-facebook {
  --bs-btn-color: #3b5998;
  --bs-btn-border-color: #3b5998;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3b5998;
  --bs-btn-hover-border-color: #3b5998;
  --bs-btn-focus-shadow-rgb: 59, 89, 152;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3b5998;
  --bs-btn-active-border-color: #3b5998;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3b5998;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3b5998;
  --bs-gradient: none;
}

.btn-outline-twitter {
  --bs-btn-color: #1da1f2;
  --bs-btn-border-color: #1da1f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1da1f2;
  --bs-btn-hover-border-color: #1da1f2;
  --bs-btn-focus-shadow-rgb: 29, 161, 242;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1da1f2;
  --bs-btn-active-border-color: #1da1f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1da1f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1da1f2;
  --bs-gradient: none;
}

.btn-outline-google-plus {
  --bs-btn-color: #db4437;
  --bs-btn-border-color: #db4437;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #db4437;
  --bs-btn-hover-border-color: #db4437;
  --bs-btn-focus-shadow-rgb: 219, 68, 55;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #db4437;
  --bs-btn-active-border-color: #db4437;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #db4437;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #db4437;
  --bs-gradient: none;
}

.btn-outline-instagram {
  --bs-btn-color: #c32aa3;
  --bs-btn-border-color: #c32aa3;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c32aa3;
  --bs-btn-hover-border-color: #c32aa3;
  --bs-btn-focus-shadow-rgb: 195, 42, 163;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c32aa3;
  --bs-btn-active-border-color: #c32aa3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c32aa3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c32aa3;
  --bs-gradient: none;
}

.btn-outline-pinterest {
  --bs-btn-color: #bd081c;
  --bs-btn-border-color: #bd081c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bd081c;
  --bs-btn-hover-border-color: #bd081c;
  --bs-btn-focus-shadow-rgb: 189, 8, 28;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #bd081c;
  --bs-btn-active-border-color: #bd081c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #bd081c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #bd081c;
  --bs-gradient: none;
}

.btn-outline-youtube {
  --bs-btn-color: #ff0000;
  --bs-btn-border-color: #ff0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff0000;
  --bs-btn-hover-border-color: #ff0000;
  --bs-btn-focus-shadow-rgb: 255, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff0000;
  --bs-btn-active-border-color: #ff0000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff0000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff0000;
  --bs-gradient: none;
}

.btn-outline-vimeo {
  --bs-btn-color: #1ab7ea;
  --bs-btn-border-color: #1ab7ea;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1ab7ea;
  --bs-btn-hover-border-color: #1ab7ea;
  --bs-btn-focus-shadow-rgb: 26, 183, 234;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1ab7ea;
  --bs-btn-active-border-color: #1ab7ea;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1ab7ea;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1ab7ea;
  --bs-gradient: none;
}

.btn-outline-slack {
  --bs-btn-color: #3eb890;
  --bs-btn-border-color: #3eb890;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3eb890;
  --bs-btn-hover-border-color: #3eb890;
  --bs-btn-focus-shadow-rgb: 62, 184, 144;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3eb890;
  --bs-btn-active-border-color: #3eb890;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3eb890;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3eb890;
  --bs-gradient: none;
}

.btn-outline-dribbble {
  --bs-btn-color: #ea4c89;
  --bs-btn-border-color: #ea4c89;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ea4c89;
  --bs-btn-hover-border-color: #ea4c89;
  --bs-btn-focus-shadow-rgb: 234, 76, 137;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ea4c89;
  --bs-btn-active-border-color: #ea4c89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ea4c89;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ea4c89;
  --bs-gradient: none;
}

.btn-outline-linkedin {
  --bs-btn-color: #007bb5;
  --bs-btn-border-color: #007bb5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #007bb5;
  --bs-btn-hover-border-color: #007bb5;
  --bs-btn-focus-shadow-rgb: 0, 123, 181;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #007bb5;
  --bs-btn-active-border-color: #007bb5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #007bb5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #007bb5;
  --bs-gradient: none;
}

.btn-outline-flickr {
  --bs-btn-color: #f40083;
  --bs-btn-border-color: #f40083;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f40083;
  --bs-btn-hover-border-color: #f40083;
  --bs-btn-focus-shadow-rgb: 244, 0, 131;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f40083;
  --bs-btn-active-border-color: #f40083;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f40083;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f40083;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 243, 71, 87;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1.032rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.40625rem;
  --bs-btn-border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.9421875rem;
  --bs-btn-border-radius: 0;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1.125rem;
  --bs-dropdown-color: #1a1e2c;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-border-radius: 0.25rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.25rem - 1px);
  --bs-dropdown-divider-bg: #e8e8e9;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  --bs-dropdown-link-color: #1a1e2c;
  --bs-dropdown-link-hover-color: #191d2a;
  --bs-dropdown-link-hover-bg: #f5f5f5;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #f12639;
  --bs-dropdown-link-disabled-color: #6c757d;
  --bs-dropdown-item-padding-x: 1.5rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1.5rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.9421875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #d2d4db;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #d2d4db;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #e8e8e9;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #f12639;
  --bs-dropdown-link-disabled-color: #87888d;
  --bs-dropdown-header-color: #87888d;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #d2d4db;
  --bs-nav-tabs-border-radius: 0.25rem;
  --bs-nav-tabs-link-hover-border-color: #e8e8e9 #e8e8e9 #d2d4db;
  --bs-nav-tabs-link-active-color: #5a5c63;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #d2d4db #d2d4db #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.25rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #f12639;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 1rem;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: #1a1e2c;
  --bs-navbar-hover-color: #1a1e2c;
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: #f12639;
  --bs-navbar-brand-padding-y: 0;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.40625rem;
  --bs-navbar-brand-color: #f12639;
  --bs-navbar-brand-hover-color: #f12639;
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.40625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%231a1e2c' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0;
  --bs-navbar-toggler-focus-width: 0.2rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: #fff;
  --bs-navbar-hover-color: #fff;
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.card {
  --bs-card-spacer-y: 0.75rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 0;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 0;
  --bs-card-cap-padding-y: 0.375rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #1a1e2c;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.25rem;
  --bs-accordion-inner-border-radius: calc(0.25rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #1a1e2c;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231a1e2c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23d92233'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #f55c6b;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(241, 38, 57, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #d92233;
  --bs-accordion-active-bg: #fee9eb;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1.125rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 1rem;
  --bs-breadcrumb-padding-y: 0.75rem;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: #e8e8e9;
  --bs-breadcrumb-border-radius: 0.25rem;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.5rem;
  --bs-pagination-font-size: 1.125rem;
  --bs-pagination-color: #5a5c63;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #d2d4db;
  --bs-pagination-border-radius: 0.25rem;
  --bs-pagination-hover-color: #1a1e2c;
  --bs-pagination-hover-bg: #f5f5f5;
  --bs-pagination-hover-border-color: #d2d4db;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e8e8e9;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(241, 38, 57, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #5a5c63;
  --bs-pagination-active-border-color: #5a5c63;
  --bs-pagination-disabled-color: #87888d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #d2d4db;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.40625rem;
  --bs-pagination-border-radius: 0.3rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.9421875rem;
  --bs-pagination-border-radius: 0.2rem;
}

.badge {
  --bs-badge-padding-x: 0.4em;
  --bs-badge-padding-y: 0.25em;
  --bs-badge-font-size: 75%;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1.25rem;
}

.alert-primary {
  --bs-alert-color: #911722;
  --bs-alert-bg: #fcd4d7;
  --bs-alert-border-color: #fbbec4;
}
.alert-primary .alert-link {
  color: #74121b;
}

.alert-secondary {
  --bs-alert-color: #13172c;
  --bs-alert-bg: #d2d4db;
  --bs-alert-border-color: #bcbec8;
}
.alert-secondary .alert-link {
  color: #0f1223;
}

.alert-success {
  --bs-alert-color: #1c7b46;
  --bs-alert-bg: #d5f5e3;
  --bs-alert-border-color: #c0f0d6;
}
.alert-success .alert-link {
  color: #166238;
}

.alert-info {
  --bs-alert-color: #277791;
  --bs-alert-bg: #d9f4fc;
  --bs-alert-border-color: #c6eefb;
}
.alert-info .alert-link {
  color: #1f5f74;
}

.alert-warning {
  --bs-alert-color: #977a38;
  --bs-alert-bg: #fef5df;
  --bs-alert-border-color: #fef0ce;
}
.alert-warning .alert-link {
  color: #79622d;
}

.alert-danger {
  --bs-alert-color: #933232;
  --bs-alert-bg: #fddddd;
  --bs-alert-border-color: #fccccc;
}
.alert-danger .alert-link {
  color: #762828;
}

.alert-light {
  --bs-alert-color: #939393;
  --bs-alert-bg: #fdfdfd;
  --bs-alert-border-color: #fcfcfc;
}
.alert-light .alert-link {
  color: #767676;
}

.alert-dark {
  --bs-alert-color: #1f2326;
  --bs-alert-bg: #d6d8d9;
  --bs-alert-border-color: #c2c4c6;
}
.alert-dark .alert-link {
  color: #191c1e;
}

.alert-blue-light {
  --bs-alert-color: #929597;
  --bs-alert-bg: #fdfefe;
  --bs-alert-border-color: #fbfdfe;
}
.alert-blue-light .alert-link {
  color: #757779;
}

.alert-facebook {
  --bs-alert-color: #23355b;
  --bs-alert-bg: #d8deea;
  --bs-alert-border-color: #c4cde0;
}
.alert-facebook .alert-link {
  color: #1c2a49;
}

.alert-twitter {
  --bs-alert-color: #116191;
  --bs-alert-bg: #d2ecfc;
  --bs-alert-border-color: #bbe3fb;
}
.alert-twitter .alert-link {
  color: #0e4e74;
}

.alert-google-plus {
  --bs-alert-color: #832921;
  --bs-alert-bg: #f8dad7;
  --bs-alert-border-color: #f4c7c3;
}
.alert-google-plus .alert-link {
  color: #69211a;
}

.alert-instagram {
  --bs-alert-color: #751962;
  --bs-alert-bg: #f3d4ed;
  --bs-alert-border-color: #edbfe3;
}
.alert-instagram .alert-link {
  color: #5e144e;
}

.alert-pinterest {
  --bs-alert-color: #710511;
  --bs-alert-bg: #f2ced2;
  --bs-alert-border-color: #ebb5bb;
}
.alert-pinterest .alert-link {
  color: #5a040e;
}

.alert-youtube {
  --bs-alert-color: #990000;
  --bs-alert-bg: #ffcccc;
  --bs-alert-border-color: #ffb3b3;
}
.alert-youtube .alert-link {
  color: #7a0000;
}

.alert-vimeo {
  --bs-alert-color: #106e8c;
  --bs-alert-bg: #d1f1fb;
  --bs-alert-border-color: #bae9f9;
}
.alert-vimeo .alert-link {
  color: #0d5870;
}

.alert-slack {
  --bs-alert-color: #256e56;
  --bs-alert-bg: #d8f1e9;
  --bs-alert-border-color: #c5eade;
}
.alert-slack .alert-link {
  color: #1e5845;
}

.alert-dribbble {
  --bs-alert-color: #8c2e52;
  --bs-alert-bg: #fbdbe7;
  --bs-alert-border-color: #f9c9dc;
}
.alert-dribbble .alert-link {
  color: #702542;
}

.alert-linkedin {
  --bs-alert-color: #004a6d;
  --bs-alert-bg: #cce5f0;
  --bs-alert-border-color: #b3d7e9;
}
.alert-linkedin .alert-link {
  color: #003b57;
}

.alert-flickr {
  --bs-alert-color: #92004f;
  --bs-alert-bg: #fdcce6;
  --bs-alert-border-color: #fcb3da;
}
.alert-flickr .alert-link {
  color: #75003f;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.84375rem;
  --bs-progress-bg: #e8e8e9;
  --bs-progress-border-radius: 0.25rem;
  --bs-progress-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #f12639;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #1a1e2c;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0;
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: 0.75rem;
  --bs-list-group-action-color: #5a5c63;
  --bs-list-group-action-hover-color: #5a5c63;
  --bs-list-group-action-hover-bg: #f5f5f5;
  --bs-list-group-action-active-color: #1a1e2c;
  --bs-list-group-action-active-bg: #e8e8e9;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #f12639;
  --bs-list-group-active-border-color: #f12639;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #911722;
  background-color: #fcd4d7;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #911722;
  background-color: #e3bfc2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #911722;
  border-color: #911722;
}

.list-group-item-secondary {
  color: #13172c;
  background-color: #d2d4db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #13172c;
  background-color: #bdbfc5;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #13172c;
  border-color: #13172c;
}

.list-group-item-success {
  color: #1c7b46;
  background-color: #d5f5e3;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #1c7b46;
  background-color: #c0ddcc;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1c7b46;
  border-color: #1c7b46;
}

.list-group-item-info {
  color: #277791;
  background-color: #d9f4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #277791;
  background-color: #c3dce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #277791;
  border-color: #277791;
}

.list-group-item-warning {
  color: #977a38;
  background-color: #fef5df;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #977a38;
  background-color: #e5ddc9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #977a38;
  border-color: #977a38;
}

.list-group-item-danger {
  color: #933232;
  background-color: #fddddd;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #933232;
  background-color: #e4c7c7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #933232;
  border-color: #933232;
}

.list-group-item-light {
  color: #939393;
  background-color: #fdfdfd;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #939393;
  background-color: #e4e4e4;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #939393;
  border-color: #939393;
}

.list-group-item-dark {
  color: #1f2326;
  background-color: #d6d8d9;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1f2326;
  background-color: #c1c2c3;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f2326;
  border-color: #1f2326;
}

.list-group-item-blue-light {
  color: #929597;
  background-color: #fdfefe;
}
.list-group-item-blue-light.list-group-item-action:hover, .list-group-item-blue-light.list-group-item-action:focus {
  color: #929597;
  background-color: #e4e5e5;
}
.list-group-item-blue-light.list-group-item-action.active {
  color: #fff;
  background-color: #929597;
  border-color: #929597;
}

.list-group-item-facebook {
  color: #23355b;
  background-color: #d8deea;
}
.list-group-item-facebook.list-group-item-action:hover, .list-group-item-facebook.list-group-item-action:focus {
  color: #23355b;
  background-color: #c2c8d3;
}
.list-group-item-facebook.list-group-item-action.active {
  color: #fff;
  background-color: #23355b;
  border-color: #23355b;
}

.list-group-item-twitter {
  color: #116191;
  background-color: #d2ecfc;
}
.list-group-item-twitter.list-group-item-action:hover, .list-group-item-twitter.list-group-item-action:focus {
  color: #116191;
  background-color: #bdd4e3;
}
.list-group-item-twitter.list-group-item-action.active {
  color: #fff;
  background-color: #116191;
  border-color: #116191;
}

.list-group-item-google-plus {
  color: #832921;
  background-color: #f8dad7;
}
.list-group-item-google-plus.list-group-item-action:hover, .list-group-item-google-plus.list-group-item-action:focus {
  color: #832921;
  background-color: #dfc4c2;
}
.list-group-item-google-plus.list-group-item-action.active {
  color: #fff;
  background-color: #832921;
  border-color: #832921;
}

.list-group-item-instagram {
  color: #751962;
  background-color: #f3d4ed;
}
.list-group-item-instagram.list-group-item-action:hover, .list-group-item-instagram.list-group-item-action:focus {
  color: #751962;
  background-color: #dbbfd5;
}
.list-group-item-instagram.list-group-item-action.active {
  color: #fff;
  background-color: #751962;
  border-color: #751962;
}

.list-group-item-pinterest {
  color: #710511;
  background-color: #f2ced2;
}
.list-group-item-pinterest.list-group-item-action:hover, .list-group-item-pinterest.list-group-item-action:focus {
  color: #710511;
  background-color: #dab9bd;
}
.list-group-item-pinterest.list-group-item-action.active {
  color: #fff;
  background-color: #710511;
  border-color: #710511;
}

.list-group-item-youtube {
  color: #990000;
  background-color: #ffcccc;
}
.list-group-item-youtube.list-group-item-action:hover, .list-group-item-youtube.list-group-item-action:focus {
  color: #990000;
  background-color: #e6b8b8;
}
.list-group-item-youtube.list-group-item-action.active {
  color: #fff;
  background-color: #990000;
  border-color: #990000;
}

.list-group-item-vimeo {
  color: #106e8c;
  background-color: #d1f1fb;
}
.list-group-item-vimeo.list-group-item-action:hover, .list-group-item-vimeo.list-group-item-action:focus {
  color: #106e8c;
  background-color: #bcd9e2;
}
.list-group-item-vimeo.list-group-item-action.active {
  color: #fff;
  background-color: #106e8c;
  border-color: #106e8c;
}

.list-group-item-slack {
  color: #256e56;
  background-color: #d8f1e9;
}
.list-group-item-slack.list-group-item-action:hover, .list-group-item-slack.list-group-item-action:focus {
  color: #256e56;
  background-color: #c2d9d2;
}
.list-group-item-slack.list-group-item-action.active {
  color: #fff;
  background-color: #256e56;
  border-color: #256e56;
}

.list-group-item-dribbble {
  color: #8c2e52;
  background-color: #fbdbe7;
}
.list-group-item-dribbble.list-group-item-action:hover, .list-group-item-dribbble.list-group-item-action:focus {
  color: #8c2e52;
  background-color: #e2c5d0;
}
.list-group-item-dribbble.list-group-item-action.active {
  color: #fff;
  background-color: #8c2e52;
  border-color: #8c2e52;
}

.list-group-item-linkedin {
  color: #004a6d;
  background-color: #cce5f0;
}
.list-group-item-linkedin.list-group-item-action:hover, .list-group-item-linkedin.list-group-item-action:focus {
  color: #004a6d;
  background-color: #b8ced8;
}
.list-group-item-linkedin.list-group-item-action.active {
  color: #fff;
  background-color: #004a6d;
  border-color: #004a6d;
}

.list-group-item-flickr {
  color: #92004f;
  background-color: #fdcce6;
}
.list-group-item-flickr.list-group-item-action:hover, .list-group-item-flickr.list-group-item-action:focus {
  color: #92004f;
  background-color: #e4b8cf;
}
.list-group-item-flickr.list-group-item-action.active {
  color: #fff;
  background-color: #92004f;
  border-color: #92004f;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(241, 38, 57, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.25rem;
  --bs-toast-spacing: 30px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-border-radius: 0.25rem;
  --bs-toast-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1050;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: rgba(0, 0, 0, 0.2);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0;
  --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  --bs-modal-inner-border-radius: -1px;
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem;
  --bs-modal-header-border-color: #e8e8e9;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #e8e8e9;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1040;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1070;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.9421875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.25rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "Roboto", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1060;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.9421875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: rgba(0, 0, 0, 0.2);
  --bs-popover-border-radius: 0.3rem;
  --bs-popover-inner-border-radius: calc(0.3rem - 1px);
  --bs-popover-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  --bs-popover-header-padding-x: 0.75rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1.125rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: #f7f7f7;
  --bs-popover-body-padding-x: 0.75rem;
  --bs-popover-body-padding-y: 0.5rem;
  --bs-popover-body-color: #1a1e2c;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Roboto", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1599.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1600px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(241, 38, 57, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(31, 39, 73, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(46, 205, 117, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(65, 198, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(251, 204, 93, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(245, 84, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #1a1e2c !important;
  background-color: RGBA(245, 245, 245, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(52, 58, 64, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-light {
  color: #1a1e2c !important;
  background-color: RGBA(243, 248, 252, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-facebook {
  color: #fff !important;
  background-color: RGBA(59, 89, 152, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-twitter {
  color: #fff !important;
  background-color: RGBA(29, 161, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-google-plus {
  color: #fff !important;
  background-color: RGBA(219, 68, 55, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-instagram {
  color: #fff !important;
  background-color: RGBA(195, 42, 163, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pinterest {
  color: #fff !important;
  background-color: RGBA(189, 8, 28, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-youtube {
  color: #fff !important;
  background-color: RGBA(255, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-vimeo {
  color: #fff !important;
  background-color: RGBA(26, 183, 234, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-slack {
  color: #fff !important;
  background-color: RGBA(62, 184, 144, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dribbble {
  color: #fff !important;
  background-color: RGBA(234, 76, 137, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-linkedin {
  color: #fff !important;
  background-color: RGBA(0, 123, 181, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-flickr {
  color: #fff !important;
  background-color: RGBA(244, 0, 131, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #f12639 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #b51d2b !important;
}

.link-secondary {
  color: #1f2749 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #171d37 !important;
}

.link-success {
  color: #2ecd75 !important;
}
.link-success:hover, .link-success:focus {
  color: #239a58 !important;
}

.link-info {
  color: #41c6f2 !important;
}
.link-info:hover, .link-info:focus {
  color: #3195b6 !important;
}

.link-warning {
  color: #fbcc5d !important;
}
.link-warning:hover, .link-warning:focus {
  color: #bc9946 !important;
}

.link-danger {
  color: #f55454 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b83f3f !important;
}

.link-light {
  color: #f5f5f5 !important;
}
.link-light:hover, .link-light:focus {
  color: #f8f8f8 !important;
}

.link-dark {
  color: #343a40 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #272c30 !important;
}

.link-blue-light {
  color: #f3f8fc !important;
}
.link-blue-light:hover, .link-blue-light:focus {
  color: #f6fafd !important;
}

.link-facebook {
  color: #3b5998 !important;
}
.link-facebook:hover, .link-facebook:focus {
  color: #2c4372 !important;
}

.link-twitter {
  color: #1da1f2 !important;
}
.link-twitter:hover, .link-twitter:focus {
  color: #1679b6 !important;
}

.link-google-plus {
  color: #db4437 !important;
}
.link-google-plus:hover, .link-google-plus:focus {
  color: #a43329 !important;
}

.link-instagram {
  color: #c32aa3 !important;
}
.link-instagram:hover, .link-instagram:focus {
  color: #92207a !important;
}

.link-pinterest {
  color: #bd081c !important;
}
.link-pinterest:hover, .link-pinterest:focus {
  color: #8e0615 !important;
}

.link-youtube {
  color: #ff0000 !important;
}
.link-youtube:hover, .link-youtube:focus {
  color: #bf0000 !important;
}

.link-vimeo {
  color: #1ab7ea !important;
}
.link-vimeo:hover, .link-vimeo:focus {
  color: #1489b0 !important;
}

.link-slack {
  color: #3eb890 !important;
}
.link-slack:hover, .link-slack:focus {
  color: #2f8a6c !important;
}

.link-dribbble {
  color: #ea4c89 !important;
}
.link-dribbble:hover, .link-dribbble:focus {
  color: #b03967 !important;
}

.link-linkedin {
  color: #007bb5 !important;
}
.link-linkedin:hover, .link-linkedin:focus {
  color: #005c88 !important;
}

.link-flickr {
  color: #f40083 !important;
}
.link-flickr:hover, .link-flickr:focus {
  color: #b70062 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-light-rgb), var(--bs-border-opacity)) !important;
}

.border-facebook {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-facebook-rgb), var(--bs-border-opacity)) !important;
}

.border-twitter {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-twitter-rgb), var(--bs-border-opacity)) !important;
}

.border-google-plus {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-google-plus-rgb), var(--bs-border-opacity)) !important;
}

.border-instagram {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-instagram-rgb), var(--bs-border-opacity)) !important;
}

.border-pinterest {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pinterest-rgb), var(--bs-border-opacity)) !important;
}

.border-youtube {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-youtube-rgb), var(--bs-border-opacity)) !important;
}

.border-vimeo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-vimeo-rgb), var(--bs-border-opacity)) !important;
}

.border-slack {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-slack-rgb), var(--bs-border-opacity)) !important;
}

.border-dribbble {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dribbble-rgb), var(--bs-border-opacity)) !important;
}

.border-linkedin {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-linkedin-rgb), var(--bs-border-opacity)) !important;
}

.border-flickr {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-flickr-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.m-9 {
  margin: 9rem !important;
}

.m-10 {
  margin: 10rem !important;
}

.m-11 {
  margin: 12rem !important;
}

.m-12 {
  margin: 14.5rem !important;
}

.m-13 {
  margin: 15rem !important;
}

.m-14 {
  margin: 16rem !important;
}

.m-15 {
  margin: 17rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-8 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-9 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-10 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-11 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-12 {
  margin-right: 14.5rem !important;
  margin-left: 14.5rem !important;
}

.mx-13 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.mx-14 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-15 {
  margin-right: 17rem !important;
  margin-left: 17rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-8 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-10 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-11 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-12 {
  margin-top: 14.5rem !important;
  margin-bottom: 14.5rem !important;
}

.my-13 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.my-14 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-15 {
  margin-top: 17rem !important;
  margin-bottom: 17rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4.5rem !important;
}

.mt-7 {
  margin-top: 6rem !important;
}

.mt-8 {
  margin-top: 7.5rem !important;
}

.mt-9 {
  margin-top: 9rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mt-11 {
  margin-top: 12rem !important;
}

.mt-12 {
  margin-top: 14.5rem !important;
}

.mt-13 {
  margin-top: 15rem !important;
}

.mt-14 {
  margin-top: 16rem !important;
}

.mt-15 {
  margin-top: 17rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4.5rem !important;
}

.me-7 {
  margin-right: 6rem !important;
}

.me-8 {
  margin-right: 7.5rem !important;
}

.me-9 {
  margin-right: 9rem !important;
}

.me-10 {
  margin-right: 10rem !important;
}

.me-11 {
  margin-right: 12rem !important;
}

.me-12 {
  margin-right: 14.5rem !important;
}

.me-13 {
  margin-right: 15rem !important;
}

.me-14 {
  margin-right: 16rem !important;
}

.me-15 {
  margin-right: 17rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4.5rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-8 {
  margin-bottom: 7.5rem !important;
}

.mb-9 {
  margin-bottom: 9rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.mb-11 {
  margin-bottom: 12rem !important;
}

.mb-12 {
  margin-bottom: 14.5rem !important;
}

.mb-13 {
  margin-bottom: 15rem !important;
}

.mb-14 {
  margin-bottom: 16rem !important;
}

.mb-15 {
  margin-bottom: 17rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4.5rem !important;
}

.ms-7 {
  margin-left: 6rem !important;
}

.ms-8 {
  margin-left: 7.5rem !important;
}

.ms-9 {
  margin-left: 9rem !important;
}

.ms-10 {
  margin-left: 10rem !important;
}

.ms-11 {
  margin-left: 12rem !important;
}

.ms-12 {
  margin-left: 14.5rem !important;
}

.ms-13 {
  margin-left: 15rem !important;
}

.ms-14 {
  margin-left: 16rem !important;
}

.ms-15 {
  margin-left: 17rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.m-n8 {
  margin: -7.5rem !important;
}

.m-n9 {
  margin: -9rem !important;
}

.m-n10 {
  margin: -10rem !important;
}

.m-n11 {
  margin: -12rem !important;
}

.m-n12 {
  margin: -14.5rem !important;
}

.m-n13 {
  margin: -15rem !important;
}

.m-n14 {
  margin: -16rem !important;
}

.m-n15 {
  margin: -17rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n7 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n8 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

.mx-n9 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

.mx-n10 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n11 {
  margin-right: -12rem !important;
  margin-left: -12rem !important;
}

.mx-n12 {
  margin-right: -14.5rem !important;
  margin-left: -14.5rem !important;
}

.mx-n13 {
  margin-right: -15rem !important;
  margin-left: -15rem !important;
}

.mx-n14 {
  margin-right: -16rem !important;
  margin-left: -16rem !important;
}

.mx-n15 {
  margin-right: -17rem !important;
  margin-left: -17rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n7 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n8 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.my-n9 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.my-n10 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n11 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.my-n12 {
  margin-top: -14.5rem !important;
  margin-bottom: -14.5rem !important;
}

.my-n13 {
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.my-n14 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.my-n15 {
  margin-top: -17rem !important;
  margin-bottom: -17rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -4.5rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.mt-n8 {
  margin-top: -7.5rem !important;
}

.mt-n9 {
  margin-top: -9rem !important;
}

.mt-n10 {
  margin-top: -10rem !important;
}

.mt-n11 {
  margin-top: -12rem !important;
}

.mt-n12 {
  margin-top: -14.5rem !important;
}

.mt-n13 {
  margin-top: -15rem !important;
}

.mt-n14 {
  margin-top: -16rem !important;
}

.mt-n15 {
  margin-top: -17rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -4.5rem !important;
}

.me-n7 {
  margin-right: -6rem !important;
}

.me-n8 {
  margin-right: -7.5rem !important;
}

.me-n9 {
  margin-right: -9rem !important;
}

.me-n10 {
  margin-right: -10rem !important;
}

.me-n11 {
  margin-right: -12rem !important;
}

.me-n12 {
  margin-right: -14.5rem !important;
}

.me-n13 {
  margin-right: -15rem !important;
}

.me-n14 {
  margin-right: -16rem !important;
}

.me-n15 {
  margin-right: -17rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -4.5rem !important;
}

.mb-n7 {
  margin-bottom: -6rem !important;
}

.mb-n8 {
  margin-bottom: -7.5rem !important;
}

.mb-n9 {
  margin-bottom: -9rem !important;
}

.mb-n10 {
  margin-bottom: -10rem !important;
}

.mb-n11 {
  margin-bottom: -12rem !important;
}

.mb-n12 {
  margin-bottom: -14.5rem !important;
}

.mb-n13 {
  margin-bottom: -15rem !important;
}

.mb-n14 {
  margin-bottom: -16rem !important;
}

.mb-n15 {
  margin-bottom: -17rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -4.5rem !important;
}

.ms-n7 {
  margin-left: -6rem !important;
}

.ms-n8 {
  margin-left: -7.5rem !important;
}

.ms-n9 {
  margin-left: -9rem !important;
}

.ms-n10 {
  margin-left: -10rem !important;
}

.ms-n11 {
  margin-left: -12rem !important;
}

.ms-n12 {
  margin-left: -14.5rem !important;
}

.ms-n13 {
  margin-left: -15rem !important;
}

.ms-n14 {
  margin-left: -16rem !important;
}

.ms-n15 {
  margin-left: -17rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.p-9 {
  padding: 9rem !important;
}

.p-10 {
  padding: 10rem !important;
}

.p-11 {
  padding: 12rem !important;
}

.p-12 {
  padding: 14.5rem !important;
}

.p-13 {
  padding: 15rem !important;
}

.p-14 {
  padding: 16rem !important;
}

.p-15 {
  padding: 17rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-8 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-9 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-10 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-11 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-12 {
  padding-right: 14.5rem !important;
  padding-left: 14.5rem !important;
}

.px-13 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.px-14 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.px-15 {
  padding-right: 17rem !important;
  padding-left: 17rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-8 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-11 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-12 {
  padding-top: 14.5rem !important;
  padding-bottom: 14.5rem !important;
}

.py-13 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.py-14 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-15 {
  padding-top: 17rem !important;
  padding-bottom: 17rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4.5rem !important;
}

.pt-7 {
  padding-top: 6rem !important;
}

.pt-8 {
  padding-top: 7.5rem !important;
}

.pt-9 {
  padding-top: 9rem !important;
}

.pt-10 {
  padding-top: 10rem !important;
}

.pt-11 {
  padding-top: 12rem !important;
}

.pt-12 {
  padding-top: 14.5rem !important;
}

.pt-13 {
  padding-top: 15rem !important;
}

.pt-14 {
  padding-top: 16rem !important;
}

.pt-15 {
  padding-top: 17rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4.5rem !important;
}

.pe-7 {
  padding-right: 6rem !important;
}

.pe-8 {
  padding-right: 7.5rem !important;
}

.pe-9 {
  padding-right: 9rem !important;
}

.pe-10 {
  padding-right: 10rem !important;
}

.pe-11 {
  padding-right: 12rem !important;
}

.pe-12 {
  padding-right: 14.5rem !important;
}

.pe-13 {
  padding-right: 15rem !important;
}

.pe-14 {
  padding-right: 16rem !important;
}

.pe-15 {
  padding-right: 17rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4.5rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.pb-8 {
  padding-bottom: 7.5rem !important;
}

.pb-9 {
  padding-bottom: 9rem !important;
}

.pb-10 {
  padding-bottom: 10rem !important;
}

.pb-11 {
  padding-bottom: 12rem !important;
}

.pb-12 {
  padding-bottom: 14.5rem !important;
}

.pb-13 {
  padding-bottom: 15rem !important;
}

.pb-14 {
  padding-bottom: 16rem !important;
}

.pb-15 {
  padding-bottom: 17rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4.5rem !important;
}

.ps-7 {
  padding-left: 6rem !important;
}

.ps-8 {
  padding-left: 7.5rem !important;
}

.ps-9 {
  padding-left: 9rem !important;
}

.ps-10 {
  padding-left: 10rem !important;
}

.ps-11 {
  padding-left: 12rem !important;
}

.ps-12 {
  padding-left: 14.5rem !important;
}

.ps-13 {
  padding-left: 15rem !important;
}

.ps-14 {
  padding-left: 16rem !important;
}

.ps-15 {
  padding-left: 17rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 4.5rem !important;
}

.gap-7 {
  gap: 6rem !important;
}

.gap-8 {
  gap: 7.5rem !important;
}

.gap-9 {
  gap: 9rem !important;
}

.gap-10 {
  gap: 10rem !important;
}

.gap-11 {
  gap: 12rem !important;
}

.gap-12 {
  gap: 14.5rem !important;
}

.gap-13 {
  gap: 15rem !important;
}

.gap-14 {
  gap: 16rem !important;
}

.gap-15 {
  gap: 17rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs--3 {
  font-size: 0.75rem !important;
}

.fs--2 {
  font-size: 0.8125rem !important;
}

.fs--1 {
  font-size: 0.9375rem !important;
}

.fs-0 {
  font-size: 1.125rem !important;
}

.fs-1 {
  font-size: 1.5rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-3 {
  font-size: 2.5rem !important;
}

.fs-4 {
  font-size: 3rem !important;
}

.fs-5 {
  font-size: 3.5rem !important;
}

.fs-6 {
  font-size: 4rem !important;
}

.fs-7 {
  font-size: 4.5rem !important;
}

.fs-8 {
  font-size: 5.125rem !important;
}

.fs-9 {
  font-size: 6rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.5 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.5 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-light-rgb), var(--bs-text-opacity)) !important;
}

.text-facebook {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-facebook-rgb), var(--bs-text-opacity)) !important;
}

.text-twitter {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-twitter-rgb), var(--bs-text-opacity)) !important;
}

.text-google-plus {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-google-plus-rgb), var(--bs-text-opacity)) !important;
}

.text-instagram {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-instagram-rgb), var(--bs-text-opacity)) !important;
}

.text-pinterest {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pinterest-rgb), var(--bs-text-opacity)) !important;
}

.text-youtube {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-youtube-rgb), var(--bs-text-opacity)) !important;
}

.text-vimeo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-vimeo-rgb), var(--bs-text-opacity)) !important;
}

.text-slack {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-slack-rgb), var(--bs-text-opacity)) !important;
}

.text-dribbble {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dribbble-rgb), var(--bs-text-opacity)) !important;
}

.text-linkedin {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-linkedin-rgb), var(--bs-text-opacity)) !important;
}

.text-flickr {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-flickr-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-facebook {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-facebook-rgb), var(--bs-bg-opacity)) !important;
}

.bg-twitter {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-twitter-rgb), var(--bs-bg-opacity)) !important;
}

.bg-google-plus {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-google-plus-rgb), var(--bs-bg-opacity)) !important;
}

.bg-instagram {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-instagram-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pinterest {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pinterest-rgb), var(--bs-bg-opacity)) !important;
}

.bg-youtube {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-youtube-rgb), var(--bs-bg-opacity)) !important;
}

.bg-vimeo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-vimeo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-slack {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-slack-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dribbble {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dribbble-rgb), var(--bs-bg-opacity)) !important;
}

.bg-linkedin {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-linkedin-rgb), var(--bs-bg-opacity)) !important;
}

.bg-flickr {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-flickr-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .m-sm-8 {
    margin: 7.5rem !important;
  }
  .m-sm-9 {
    margin: 9rem !important;
  }
  .m-sm-10 {
    margin: 10rem !important;
  }
  .m-sm-11 {
    margin: 12rem !important;
  }
  .m-sm-12 {
    margin: 14.5rem !important;
  }
  .m-sm-13 {
    margin: 15rem !important;
  }
  .m-sm-14 {
    margin: 16rem !important;
  }
  .m-sm-15 {
    margin: 17rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-sm-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-11 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-12 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }
  .mx-sm-13 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-sm-14 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-sm-15 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-sm-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-11 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-12 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }
  .my-sm-13 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-sm-14 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-sm-15 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 6rem !important;
  }
  .mt-sm-8 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 9rem !important;
  }
  .mt-sm-10 {
    margin-top: 10rem !important;
  }
  .mt-sm-11 {
    margin-top: 12rem !important;
  }
  .mt-sm-12 {
    margin-top: 14.5rem !important;
  }
  .mt-sm-13 {
    margin-top: 15rem !important;
  }
  .mt-sm-14 {
    margin-top: 16rem !important;
  }
  .mt-sm-15 {
    margin-top: 17rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 4.5rem !important;
  }
  .me-sm-7 {
    margin-right: 6rem !important;
  }
  .me-sm-8 {
    margin-right: 7.5rem !important;
  }
  .me-sm-9 {
    margin-right: 9rem !important;
  }
  .me-sm-10 {
    margin-right: 10rem !important;
  }
  .me-sm-11 {
    margin-right: 12rem !important;
  }
  .me-sm-12 {
    margin-right: 14.5rem !important;
  }
  .me-sm-13 {
    margin-right: 15rem !important;
  }
  .me-sm-14 {
    margin-right: 16rem !important;
  }
  .me-sm-15 {
    margin-right: 17rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 9rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 14.5rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 15rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 16rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 17rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 6rem !important;
  }
  .ms-sm-8 {
    margin-left: 7.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 9rem !important;
  }
  .ms-sm-10 {
    margin-left: 10rem !important;
  }
  .ms-sm-11 {
    margin-left: 12rem !important;
  }
  .ms-sm-12 {
    margin-left: 14.5rem !important;
  }
  .ms-sm-13 {
    margin-left: 15rem !important;
  }
  .ms-sm-14 {
    margin-left: 16rem !important;
  }
  .ms-sm-15 {
    margin-left: 17rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .m-sm-n8 {
    margin: -7.5rem !important;
  }
  .m-sm-n9 {
    margin: -9rem !important;
  }
  .m-sm-n10 {
    margin: -10rem !important;
  }
  .m-sm-n11 {
    margin: -12rem !important;
  }
  .m-sm-n12 {
    margin: -14.5rem !important;
  }
  .m-sm-n13 {
    margin: -15rem !important;
  }
  .m-sm-n14 {
    margin: -16rem !important;
  }
  .m-sm-n15 {
    margin: -17rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-sm-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-sm-n11 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-sm-n12 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }
  .mx-sm-n13 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .mx-sm-n14 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-sm-n15 {
    margin-right: -17rem !important;
    margin-left: -17rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-sm-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-sm-n11 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-sm-n12 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }
  .my-sm-n13 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .my-sm-n14 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-sm-n15 {
    margin-top: -17rem !important;
    margin-bottom: -17rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -6rem !important;
  }
  .mt-sm-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -9rem !important;
  }
  .mt-sm-n10 {
    margin-top: -10rem !important;
  }
  .mt-sm-n11 {
    margin-top: -12rem !important;
  }
  .mt-sm-n12 {
    margin-top: -14.5rem !important;
  }
  .mt-sm-n13 {
    margin-top: -15rem !important;
  }
  .mt-sm-n14 {
    margin-top: -16rem !important;
  }
  .mt-sm-n15 {
    margin-top: -17rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -6rem !important;
  }
  .me-sm-n8 {
    margin-right: -7.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -9rem !important;
  }
  .me-sm-n10 {
    margin-right: -10rem !important;
  }
  .me-sm-n11 {
    margin-right: -12rem !important;
  }
  .me-sm-n12 {
    margin-right: -14.5rem !important;
  }
  .me-sm-n13 {
    margin-right: -15rem !important;
  }
  .me-sm-n14 {
    margin-right: -16rem !important;
  }
  .me-sm-n15 {
    margin-right: -17rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -9rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -12rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -14.5rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -15rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -16rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -17rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -6rem !important;
  }
  .ms-sm-n8 {
    margin-left: -7.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -9rem !important;
  }
  .ms-sm-n10 {
    margin-left: -10rem !important;
  }
  .ms-sm-n11 {
    margin-left: -12rem !important;
  }
  .ms-sm-n12 {
    margin-left: -14.5rem !important;
  }
  .ms-sm-n13 {
    margin-left: -15rem !important;
  }
  .ms-sm-n14 {
    margin-left: -16rem !important;
  }
  .ms-sm-n15 {
    margin-left: -17rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .p-sm-8 {
    padding: 7.5rem !important;
  }
  .p-sm-9 {
    padding: 9rem !important;
  }
  .p-sm-10 {
    padding: 10rem !important;
  }
  .p-sm-11 {
    padding: 12rem !important;
  }
  .p-sm-12 {
    padding: 14.5rem !important;
  }
  .p-sm-13 {
    padding: 15rem !important;
  }
  .p-sm-14 {
    padding: 16rem !important;
  }
  .p-sm-15 {
    padding: 17rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-sm-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-sm-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-11 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-sm-12 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }
  .px-sm-13 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-sm-14 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-sm-15 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-sm-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-sm-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-11 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-sm-12 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }
  .py-sm-13 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-sm-14 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-sm-15 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6rem !important;
  }
  .pt-sm-8 {
    padding-top: 7.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 9rem !important;
  }
  .pt-sm-10 {
    padding-top: 10rem !important;
  }
  .pt-sm-11 {
    padding-top: 12rem !important;
  }
  .pt-sm-12 {
    padding-top: 14.5rem !important;
  }
  .pt-sm-13 {
    padding-top: 15rem !important;
  }
  .pt-sm-14 {
    padding-top: 16rem !important;
  }
  .pt-sm-15 {
    padding-top: 17rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6rem !important;
  }
  .pe-sm-8 {
    padding-right: 7.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 9rem !important;
  }
  .pe-sm-10 {
    padding-right: 10rem !important;
  }
  .pe-sm-11 {
    padding-right: 12rem !important;
  }
  .pe-sm-12 {
    padding-right: 14.5rem !important;
  }
  .pe-sm-13 {
    padding-right: 15rem !important;
  }
  .pe-sm-14 {
    padding-right: 16rem !important;
  }
  .pe-sm-15 {
    padding-right: 17rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 9rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 12rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 14.5rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 15rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 16rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 17rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6rem !important;
  }
  .ps-sm-8 {
    padding-left: 7.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 9rem !important;
  }
  .ps-sm-10 {
    padding-left: 10rem !important;
  }
  .ps-sm-11 {
    padding-left: 12rem !important;
  }
  .ps-sm-12 {
    padding-left: 14.5rem !important;
  }
  .ps-sm-13 {
    padding-left: 15rem !important;
  }
  .ps-sm-14 {
    padding-left: 16rem !important;
  }
  .ps-sm-15 {
    padding-left: 17rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 4.5rem !important;
  }
  .gap-sm-7 {
    gap: 6rem !important;
  }
  .gap-sm-8 {
    gap: 7.5rem !important;
  }
  .gap-sm-9 {
    gap: 9rem !important;
  }
  .gap-sm-10 {
    gap: 10rem !important;
  }
  .gap-sm-11 {
    gap: 12rem !important;
  }
  .gap-sm-12 {
    gap: 14.5rem !important;
  }
  .gap-sm-13 {
    gap: 15rem !important;
  }
  .gap-sm-14 {
    gap: 16rem !important;
  }
  .gap-sm-15 {
    gap: 17rem !important;
  }
  .fs-sm--3 {
    font-size: 0.75rem !important;
  }
  .fs-sm--2 {
    font-size: 0.8125rem !important;
  }
  .fs-sm--1 {
    font-size: 0.9375rem !important;
  }
  .fs-sm-0 {
    font-size: 1.125rem !important;
  }
  .fs-sm-1 {
    font-size: 1.5rem !important;
  }
  .fs-sm-2 {
    font-size: 2rem !important;
  }
  .fs-sm-3 {
    font-size: 2.5rem !important;
  }
  .fs-sm-4 {
    font-size: 3rem !important;
  }
  .fs-sm-5 {
    font-size: 3.5rem !important;
  }
  .fs-sm-6 {
    font-size: 4rem !important;
  }
  .fs-sm-7 {
    font-size: 4.5rem !important;
  }
  .fs-sm-8 {
    font-size: 5.125rem !important;
  }
  .fs-sm-9 {
    font-size: 6rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .m-md-8 {
    margin: 7.5rem !important;
  }
  .m-md-9 {
    margin: 9rem !important;
  }
  .m-md-10 {
    margin: 10rem !important;
  }
  .m-md-11 {
    margin: 12rem !important;
  }
  .m-md-12 {
    margin: 14.5rem !important;
  }
  .m-md-13 {
    margin: 15rem !important;
  }
  .m-md-14 {
    margin: 16rem !important;
  }
  .m-md-15 {
    margin: 17rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-md-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-11 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-12 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }
  .mx-md-13 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-md-14 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-md-15 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-md-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-11 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-12 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }
  .my-md-13 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-md-14 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-md-15 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4.5rem !important;
  }
  .mt-md-7 {
    margin-top: 6rem !important;
  }
  .mt-md-8 {
    margin-top: 7.5rem !important;
  }
  .mt-md-9 {
    margin-top: 9rem !important;
  }
  .mt-md-10 {
    margin-top: 10rem !important;
  }
  .mt-md-11 {
    margin-top: 12rem !important;
  }
  .mt-md-12 {
    margin-top: 14.5rem !important;
  }
  .mt-md-13 {
    margin-top: 15rem !important;
  }
  .mt-md-14 {
    margin-top: 16rem !important;
  }
  .mt-md-15 {
    margin-top: 17rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 4.5rem !important;
  }
  .me-md-7 {
    margin-right: 6rem !important;
  }
  .me-md-8 {
    margin-right: 7.5rem !important;
  }
  .me-md-9 {
    margin-right: 9rem !important;
  }
  .me-md-10 {
    margin-right: 10rem !important;
  }
  .me-md-11 {
    margin-right: 12rem !important;
  }
  .me-md-12 {
    margin-right: 14.5rem !important;
  }
  .me-md-13 {
    margin-right: 15rem !important;
  }
  .me-md-14 {
    margin-right: 16rem !important;
  }
  .me-md-15 {
    margin-right: 17rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 6rem !important;
  }
  .mb-md-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 9rem !important;
  }
  .mb-md-10 {
    margin-bottom: 10rem !important;
  }
  .mb-md-11 {
    margin-bottom: 12rem !important;
  }
  .mb-md-12 {
    margin-bottom: 14.5rem !important;
  }
  .mb-md-13 {
    margin-bottom: 15rem !important;
  }
  .mb-md-14 {
    margin-bottom: 16rem !important;
  }
  .mb-md-15 {
    margin-bottom: 17rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 4.5rem !important;
  }
  .ms-md-7 {
    margin-left: 6rem !important;
  }
  .ms-md-8 {
    margin-left: 7.5rem !important;
  }
  .ms-md-9 {
    margin-left: 9rem !important;
  }
  .ms-md-10 {
    margin-left: 10rem !important;
  }
  .ms-md-11 {
    margin-left: 12rem !important;
  }
  .ms-md-12 {
    margin-left: 14.5rem !important;
  }
  .ms-md-13 {
    margin-left: 15rem !important;
  }
  .ms-md-14 {
    margin-left: 16rem !important;
  }
  .ms-md-15 {
    margin-left: 17rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .m-md-n8 {
    margin: -7.5rem !important;
  }
  .m-md-n9 {
    margin: -9rem !important;
  }
  .m-md-n10 {
    margin: -10rem !important;
  }
  .m-md-n11 {
    margin: -12rem !important;
  }
  .m-md-n12 {
    margin: -14.5rem !important;
  }
  .m-md-n13 {
    margin: -15rem !important;
  }
  .m-md-n14 {
    margin: -16rem !important;
  }
  .m-md-n15 {
    margin: -17rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-md-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-md-n11 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-md-n12 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }
  .mx-md-n13 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .mx-md-n14 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-md-n15 {
    margin-right: -17rem !important;
    margin-left: -17rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-md-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-md-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-md-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-md-n11 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-md-n12 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }
  .my-md-n13 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .my-md-n14 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-md-n15 {
    margin-top: -17rem !important;
    margin-bottom: -17rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -6rem !important;
  }
  .mt-md-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -9rem !important;
  }
  .mt-md-n10 {
    margin-top: -10rem !important;
  }
  .mt-md-n11 {
    margin-top: -12rem !important;
  }
  .mt-md-n12 {
    margin-top: -14.5rem !important;
  }
  .mt-md-n13 {
    margin-top: -15rem !important;
  }
  .mt-md-n14 {
    margin-top: -16rem !important;
  }
  .mt-md-n15 {
    margin-top: -17rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -4.5rem !important;
  }
  .me-md-n7 {
    margin-right: -6rem !important;
  }
  .me-md-n8 {
    margin-right: -7.5rem !important;
  }
  .me-md-n9 {
    margin-right: -9rem !important;
  }
  .me-md-n10 {
    margin-right: -10rem !important;
  }
  .me-md-n11 {
    margin-right: -12rem !important;
  }
  .me-md-n12 {
    margin-right: -14.5rem !important;
  }
  .me-md-n13 {
    margin-right: -15rem !important;
  }
  .me-md-n14 {
    margin-right: -16rem !important;
  }
  .me-md-n15 {
    margin-right: -17rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -9rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -12rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -14.5rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -15rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -16rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -17rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -6rem !important;
  }
  .ms-md-n8 {
    margin-left: -7.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -9rem !important;
  }
  .ms-md-n10 {
    margin-left: -10rem !important;
  }
  .ms-md-n11 {
    margin-left: -12rem !important;
  }
  .ms-md-n12 {
    margin-left: -14.5rem !important;
  }
  .ms-md-n13 {
    margin-left: -15rem !important;
  }
  .ms-md-n14 {
    margin-left: -16rem !important;
  }
  .ms-md-n15 {
    margin-left: -17rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .p-md-8 {
    padding: 7.5rem !important;
  }
  .p-md-9 {
    padding: 9rem !important;
  }
  .p-md-10 {
    padding: 10rem !important;
  }
  .p-md-11 {
    padding: 12rem !important;
  }
  .p-md-12 {
    padding: 14.5rem !important;
  }
  .p-md-13 {
    padding: 15rem !important;
  }
  .p-md-14 {
    padding: 16rem !important;
  }
  .p-md-15 {
    padding: 17rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-md-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-md-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-11 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-md-12 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }
  .px-md-13 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-md-14 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-md-15 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-md-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-11 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-md-12 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }
  .py-md-13 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-md-14 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-md-15 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6rem !important;
  }
  .pt-md-8 {
    padding-top: 7.5rem !important;
  }
  .pt-md-9 {
    padding-top: 9rem !important;
  }
  .pt-md-10 {
    padding-top: 10rem !important;
  }
  .pt-md-11 {
    padding-top: 12rem !important;
  }
  .pt-md-12 {
    padding-top: 14.5rem !important;
  }
  .pt-md-13 {
    padding-top: 15rem !important;
  }
  .pt-md-14 {
    padding-top: 16rem !important;
  }
  .pt-md-15 {
    padding-top: 17rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 4.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6rem !important;
  }
  .pe-md-8 {
    padding-right: 7.5rem !important;
  }
  .pe-md-9 {
    padding-right: 9rem !important;
  }
  .pe-md-10 {
    padding-right: 10rem !important;
  }
  .pe-md-11 {
    padding-right: 12rem !important;
  }
  .pe-md-12 {
    padding-right: 14.5rem !important;
  }
  .pe-md-13 {
    padding-right: 15rem !important;
  }
  .pe-md-14 {
    padding-right: 16rem !important;
  }
  .pe-md-15 {
    padding-right: 17rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6rem !important;
  }
  .pb-md-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 9rem !important;
  }
  .pb-md-10 {
    padding-bottom: 10rem !important;
  }
  .pb-md-11 {
    padding-bottom: 12rem !important;
  }
  .pb-md-12 {
    padding-bottom: 14.5rem !important;
  }
  .pb-md-13 {
    padding-bottom: 15rem !important;
  }
  .pb-md-14 {
    padding-bottom: 16rem !important;
  }
  .pb-md-15 {
    padding-bottom: 17rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6rem !important;
  }
  .ps-md-8 {
    padding-left: 7.5rem !important;
  }
  .ps-md-9 {
    padding-left: 9rem !important;
  }
  .ps-md-10 {
    padding-left: 10rem !important;
  }
  .ps-md-11 {
    padding-left: 12rem !important;
  }
  .ps-md-12 {
    padding-left: 14.5rem !important;
  }
  .ps-md-13 {
    padding-left: 15rem !important;
  }
  .ps-md-14 {
    padding-left: 16rem !important;
  }
  .ps-md-15 {
    padding-left: 17rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 4.5rem !important;
  }
  .gap-md-7 {
    gap: 6rem !important;
  }
  .gap-md-8 {
    gap: 7.5rem !important;
  }
  .gap-md-9 {
    gap: 9rem !important;
  }
  .gap-md-10 {
    gap: 10rem !important;
  }
  .gap-md-11 {
    gap: 12rem !important;
  }
  .gap-md-12 {
    gap: 14.5rem !important;
  }
  .gap-md-13 {
    gap: 15rem !important;
  }
  .gap-md-14 {
    gap: 16rem !important;
  }
  .gap-md-15 {
    gap: 17rem !important;
  }
  .fs-md--3 {
    font-size: 0.75rem !important;
  }
  .fs-md--2 {
    font-size: 0.8125rem !important;
  }
  .fs-md--1 {
    font-size: 0.9375rem !important;
  }
  .fs-md-0 {
    font-size: 1.125rem !important;
  }
  .fs-md-1 {
    font-size: 1.5rem !important;
  }
  .fs-md-2 {
    font-size: 2rem !important;
  }
  .fs-md-3 {
    font-size: 2.5rem !important;
  }
  .fs-md-4 {
    font-size: 3rem !important;
  }
  .fs-md-5 {
    font-size: 3.5rem !important;
  }
  .fs-md-6 {
    font-size: 4rem !important;
  }
  .fs-md-7 {
    font-size: 4.5rem !important;
  }
  .fs-md-8 {
    font-size: 5.125rem !important;
  }
  .fs-md-9 {
    font-size: 6rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .m-lg-8 {
    margin: 7.5rem !important;
  }
  .m-lg-9 {
    margin: 9rem !important;
  }
  .m-lg-10 {
    margin: 10rem !important;
  }
  .m-lg-11 {
    margin: 12rem !important;
  }
  .m-lg-12 {
    margin: 14.5rem !important;
  }
  .m-lg-13 {
    margin: 15rem !important;
  }
  .m-lg-14 {
    margin: 16rem !important;
  }
  .m-lg-15 {
    margin: 17rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-lg-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-11 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-12 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }
  .mx-lg-13 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-lg-14 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-lg-15 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-lg-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-11 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-12 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }
  .my-lg-13 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-lg-14 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-lg-15 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 6rem !important;
  }
  .mt-lg-8 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 9rem !important;
  }
  .mt-lg-10 {
    margin-top: 10rem !important;
  }
  .mt-lg-11 {
    margin-top: 12rem !important;
  }
  .mt-lg-12 {
    margin-top: 14.5rem !important;
  }
  .mt-lg-13 {
    margin-top: 15rem !important;
  }
  .mt-lg-14 {
    margin-top: 16rem !important;
  }
  .mt-lg-15 {
    margin-top: 17rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 4.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6rem !important;
  }
  .me-lg-8 {
    margin-right: 7.5rem !important;
  }
  .me-lg-9 {
    margin-right: 9rem !important;
  }
  .me-lg-10 {
    margin-right: 10rem !important;
  }
  .me-lg-11 {
    margin-right: 12rem !important;
  }
  .me-lg-12 {
    margin-right: 14.5rem !important;
  }
  .me-lg-13 {
    margin-right: 15rem !important;
  }
  .me-lg-14 {
    margin-right: 16rem !important;
  }
  .me-lg-15 {
    margin-right: 17rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 14.5rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 15rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 16rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 17rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6rem !important;
  }
  .ms-lg-8 {
    margin-left: 7.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 9rem !important;
  }
  .ms-lg-10 {
    margin-left: 10rem !important;
  }
  .ms-lg-11 {
    margin-left: 12rem !important;
  }
  .ms-lg-12 {
    margin-left: 14.5rem !important;
  }
  .ms-lg-13 {
    margin-left: 15rem !important;
  }
  .ms-lg-14 {
    margin-left: 16rem !important;
  }
  .ms-lg-15 {
    margin-left: 17rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .m-lg-n8 {
    margin: -7.5rem !important;
  }
  .m-lg-n9 {
    margin: -9rem !important;
  }
  .m-lg-n10 {
    margin: -10rem !important;
  }
  .m-lg-n11 {
    margin: -12rem !important;
  }
  .m-lg-n12 {
    margin: -14.5rem !important;
  }
  .m-lg-n13 {
    margin: -15rem !important;
  }
  .m-lg-n14 {
    margin: -16rem !important;
  }
  .m-lg-n15 {
    margin: -17rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-lg-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-lg-n11 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-lg-n12 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }
  .mx-lg-n13 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .mx-lg-n14 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-lg-n15 {
    margin-right: -17rem !important;
    margin-left: -17rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-lg-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-lg-n11 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-lg-n12 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }
  .my-lg-n13 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .my-lg-n14 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-lg-n15 {
    margin-top: -17rem !important;
    margin-bottom: -17rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -6rem !important;
  }
  .mt-lg-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -9rem !important;
  }
  .mt-lg-n10 {
    margin-top: -10rem !important;
  }
  .mt-lg-n11 {
    margin-top: -12rem !important;
  }
  .mt-lg-n12 {
    margin-top: -14.5rem !important;
  }
  .mt-lg-n13 {
    margin-top: -15rem !important;
  }
  .mt-lg-n14 {
    margin-top: -16rem !important;
  }
  .mt-lg-n15 {
    margin-top: -17rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -6rem !important;
  }
  .me-lg-n8 {
    margin-right: -7.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -9rem !important;
  }
  .me-lg-n10 {
    margin-right: -10rem !important;
  }
  .me-lg-n11 {
    margin-right: -12rem !important;
  }
  .me-lg-n12 {
    margin-right: -14.5rem !important;
  }
  .me-lg-n13 {
    margin-right: -15rem !important;
  }
  .me-lg-n14 {
    margin-right: -16rem !important;
  }
  .me-lg-n15 {
    margin-right: -17rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -9rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -12rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -14.5rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -15rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -16rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -17rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -6rem !important;
  }
  .ms-lg-n8 {
    margin-left: -7.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -9rem !important;
  }
  .ms-lg-n10 {
    margin-left: -10rem !important;
  }
  .ms-lg-n11 {
    margin-left: -12rem !important;
  }
  .ms-lg-n12 {
    margin-left: -14.5rem !important;
  }
  .ms-lg-n13 {
    margin-left: -15rem !important;
  }
  .ms-lg-n14 {
    margin-left: -16rem !important;
  }
  .ms-lg-n15 {
    margin-left: -17rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .p-lg-8 {
    padding: 7.5rem !important;
  }
  .p-lg-9 {
    padding: 9rem !important;
  }
  .p-lg-10 {
    padding: 10rem !important;
  }
  .p-lg-11 {
    padding: 12rem !important;
  }
  .p-lg-12 {
    padding: 14.5rem !important;
  }
  .p-lg-13 {
    padding: 15rem !important;
  }
  .p-lg-14 {
    padding: 16rem !important;
  }
  .p-lg-15 {
    padding: 17rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-lg-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-lg-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-11 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-lg-12 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }
  .px-lg-13 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-lg-14 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-lg-15 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-lg-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-lg-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-11 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-lg-12 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }
  .py-lg-13 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-lg-14 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-lg-15 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6rem !important;
  }
  .pt-lg-8 {
    padding-top: 7.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 9rem !important;
  }
  .pt-lg-10 {
    padding-top: 10rem !important;
  }
  .pt-lg-11 {
    padding-top: 12rem !important;
  }
  .pt-lg-12 {
    padding-top: 14.5rem !important;
  }
  .pt-lg-13 {
    padding-top: 15rem !important;
  }
  .pt-lg-14 {
    padding-top: 16rem !important;
  }
  .pt-lg-15 {
    padding-top: 17rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6rem !important;
  }
  .pe-lg-8 {
    padding-right: 7.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 9rem !important;
  }
  .pe-lg-10 {
    padding-right: 10rem !important;
  }
  .pe-lg-11 {
    padding-right: 12rem !important;
  }
  .pe-lg-12 {
    padding-right: 14.5rem !important;
  }
  .pe-lg-13 {
    padding-right: 15rem !important;
  }
  .pe-lg-14 {
    padding-right: 16rem !important;
  }
  .pe-lg-15 {
    padding-right: 17rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 14.5rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 15rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 16rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 17rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6rem !important;
  }
  .ps-lg-8 {
    padding-left: 7.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 9rem !important;
  }
  .ps-lg-10 {
    padding-left: 10rem !important;
  }
  .ps-lg-11 {
    padding-left: 12rem !important;
  }
  .ps-lg-12 {
    padding-left: 14.5rem !important;
  }
  .ps-lg-13 {
    padding-left: 15rem !important;
  }
  .ps-lg-14 {
    padding-left: 16rem !important;
  }
  .ps-lg-15 {
    padding-left: 17rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 4.5rem !important;
  }
  .gap-lg-7 {
    gap: 6rem !important;
  }
  .gap-lg-8 {
    gap: 7.5rem !important;
  }
  .gap-lg-9 {
    gap: 9rem !important;
  }
  .gap-lg-10 {
    gap: 10rem !important;
  }
  .gap-lg-11 {
    gap: 12rem !important;
  }
  .gap-lg-12 {
    gap: 14.5rem !important;
  }
  .gap-lg-13 {
    gap: 15rem !important;
  }
  .gap-lg-14 {
    gap: 16rem !important;
  }
  .gap-lg-15 {
    gap: 17rem !important;
  }
  .fs-lg--3 {
    font-size: 0.75rem !important;
  }
  .fs-lg--2 {
    font-size: 0.8125rem !important;
  }
  .fs-lg--1 {
    font-size: 0.9375rem !important;
  }
  .fs-lg-0 {
    font-size: 1.125rem !important;
  }
  .fs-lg-1 {
    font-size: 1.5rem !important;
  }
  .fs-lg-2 {
    font-size: 2rem !important;
  }
  .fs-lg-3 {
    font-size: 2.5rem !important;
  }
  .fs-lg-4 {
    font-size: 3rem !important;
  }
  .fs-lg-5 {
    font-size: 3.5rem !important;
  }
  .fs-lg-6 {
    font-size: 4rem !important;
  }
  .fs-lg-7 {
    font-size: 4.5rem !important;
  }
  .fs-lg-8 {
    font-size: 5.125rem !important;
  }
  .fs-lg-9 {
    font-size: 6rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .m-xl-8 {
    margin: 7.5rem !important;
  }
  .m-xl-9 {
    margin: 9rem !important;
  }
  .m-xl-10 {
    margin: 10rem !important;
  }
  .m-xl-11 {
    margin: 12rem !important;
  }
  .m-xl-12 {
    margin: 14.5rem !important;
  }
  .m-xl-13 {
    margin: 15rem !important;
  }
  .m-xl-14 {
    margin: 16rem !important;
  }
  .m-xl-15 {
    margin: 17rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-11 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-12 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }
  .mx-xl-13 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-xl-14 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xl-15 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-11 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-12 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }
  .my-xl-13 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-xl-14 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xl-15 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 6rem !important;
  }
  .mt-xl-8 {
    margin-top: 7.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 9rem !important;
  }
  .mt-xl-10 {
    margin-top: 10rem !important;
  }
  .mt-xl-11 {
    margin-top: 12rem !important;
  }
  .mt-xl-12 {
    margin-top: 14.5rem !important;
  }
  .mt-xl-13 {
    margin-top: 15rem !important;
  }
  .mt-xl-14 {
    margin-top: 16rem !important;
  }
  .mt-xl-15 {
    margin-top: 17rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xl-7 {
    margin-right: 6rem !important;
  }
  .me-xl-8 {
    margin-right: 7.5rem !important;
  }
  .me-xl-9 {
    margin-right: 9rem !important;
  }
  .me-xl-10 {
    margin-right: 10rem !important;
  }
  .me-xl-11 {
    margin-right: 12rem !important;
  }
  .me-xl-12 {
    margin-right: 14.5rem !important;
  }
  .me-xl-13 {
    margin-right: 15rem !important;
  }
  .me-xl-14 {
    margin-right: 16rem !important;
  }
  .me-xl-15 {
    margin-right: 17rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 14.5rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 15rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 16rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 17rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 6rem !important;
  }
  .ms-xl-8 {
    margin-left: 7.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 9rem !important;
  }
  .ms-xl-10 {
    margin-left: 10rem !important;
  }
  .ms-xl-11 {
    margin-left: 12rem !important;
  }
  .ms-xl-12 {
    margin-left: 14.5rem !important;
  }
  .ms-xl-13 {
    margin-left: 15rem !important;
  }
  .ms-xl-14 {
    margin-left: 16rem !important;
  }
  .ms-xl-15 {
    margin-left: 17rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .m-xl-n8 {
    margin: -7.5rem !important;
  }
  .m-xl-n9 {
    margin: -9rem !important;
  }
  .m-xl-n10 {
    margin: -10rem !important;
  }
  .m-xl-n11 {
    margin: -12rem !important;
  }
  .m-xl-n12 {
    margin: -14.5rem !important;
  }
  .m-xl-n13 {
    margin: -15rem !important;
  }
  .m-xl-n14 {
    margin: -16rem !important;
  }
  .m-xl-n15 {
    margin: -17rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xl-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xl-n11 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-xl-n12 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }
  .mx-xl-n13 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .mx-xl-n14 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-xl-n15 {
    margin-right: -17rem !important;
    margin-left: -17rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xl-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xl-n11 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-xl-n12 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }
  .my-xl-n13 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .my-xl-n14 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-xl-n15 {
    margin-top: -17rem !important;
    margin-bottom: -17rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xl-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -9rem !important;
  }
  .mt-xl-n10 {
    margin-top: -10rem !important;
  }
  .mt-xl-n11 {
    margin-top: -12rem !important;
  }
  .mt-xl-n12 {
    margin-top: -14.5rem !important;
  }
  .mt-xl-n13 {
    margin-top: -15rem !important;
  }
  .mt-xl-n14 {
    margin-top: -16rem !important;
  }
  .mt-xl-n15 {
    margin-top: -17rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -6rem !important;
  }
  .me-xl-n8 {
    margin-right: -7.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -9rem !important;
  }
  .me-xl-n10 {
    margin-right: -10rem !important;
  }
  .me-xl-n11 {
    margin-right: -12rem !important;
  }
  .me-xl-n12 {
    margin-right: -14.5rem !important;
  }
  .me-xl-n13 {
    margin-right: -15rem !important;
  }
  .me-xl-n14 {
    margin-right: -16rem !important;
  }
  .me-xl-n15 {
    margin-right: -17rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -9rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -10rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -12rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -14.5rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -15rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -16rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -17rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xl-n8 {
    margin-left: -7.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -9rem !important;
  }
  .ms-xl-n10 {
    margin-left: -10rem !important;
  }
  .ms-xl-n11 {
    margin-left: -12rem !important;
  }
  .ms-xl-n12 {
    margin-left: -14.5rem !important;
  }
  .ms-xl-n13 {
    margin-left: -15rem !important;
  }
  .ms-xl-n14 {
    margin-left: -16rem !important;
  }
  .ms-xl-n15 {
    margin-left: -17rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .p-xl-8 {
    padding: 7.5rem !important;
  }
  .p-xl-9 {
    padding: 9rem !important;
  }
  .p-xl-10 {
    padding: 10rem !important;
  }
  .p-xl-11 {
    padding: 12rem !important;
  }
  .p-xl-12 {
    padding: 14.5rem !important;
  }
  .p-xl-13 {
    padding: 15rem !important;
  }
  .p-xl-14 {
    padding: 16rem !important;
  }
  .p-xl-15 {
    padding: 17rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-11 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xl-12 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }
  .px-xl-13 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-xl-14 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xl-15 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-11 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xl-12 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }
  .py-xl-13 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-xl-14 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xl-15 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6rem !important;
  }
  .pt-xl-8 {
    padding-top: 7.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 9rem !important;
  }
  .pt-xl-10 {
    padding-top: 10rem !important;
  }
  .pt-xl-11 {
    padding-top: 12rem !important;
  }
  .pt-xl-12 {
    padding-top: 14.5rem !important;
  }
  .pt-xl-13 {
    padding-top: 15rem !important;
  }
  .pt-xl-14 {
    padding-top: 16rem !important;
  }
  .pt-xl-15 {
    padding-top: 17rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6rem !important;
  }
  .pe-xl-8 {
    padding-right: 7.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 9rem !important;
  }
  .pe-xl-10 {
    padding-right: 10rem !important;
  }
  .pe-xl-11 {
    padding-right: 12rem !important;
  }
  .pe-xl-12 {
    padding-right: 14.5rem !important;
  }
  .pe-xl-13 {
    padding-right: 15rem !important;
  }
  .pe-xl-14 {
    padding-right: 16rem !important;
  }
  .pe-xl-15 {
    padding-right: 17rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 9rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 12rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 14.5rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 15rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 16rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 17rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6rem !important;
  }
  .ps-xl-8 {
    padding-left: 7.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 9rem !important;
  }
  .ps-xl-10 {
    padding-left: 10rem !important;
  }
  .ps-xl-11 {
    padding-left: 12rem !important;
  }
  .ps-xl-12 {
    padding-left: 14.5rem !important;
  }
  .ps-xl-13 {
    padding-left: 15rem !important;
  }
  .ps-xl-14 {
    padding-left: 16rem !important;
  }
  .ps-xl-15 {
    padding-left: 17rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 4.5rem !important;
  }
  .gap-xl-7 {
    gap: 6rem !important;
  }
  .gap-xl-8 {
    gap: 7.5rem !important;
  }
  .gap-xl-9 {
    gap: 9rem !important;
  }
  .gap-xl-10 {
    gap: 10rem !important;
  }
  .gap-xl-11 {
    gap: 12rem !important;
  }
  .gap-xl-12 {
    gap: 14.5rem !important;
  }
  .gap-xl-13 {
    gap: 15rem !important;
  }
  .gap-xl-14 {
    gap: 16rem !important;
  }
  .gap-xl-15 {
    gap: 17rem !important;
  }
  .fs-xl--3 {
    font-size: 0.75rem !important;
  }
  .fs-xl--2 {
    font-size: 0.8125rem !important;
  }
  .fs-xl--1 {
    font-size: 0.9375rem !important;
  }
  .fs-xl-0 {
    font-size: 1.125rem !important;
  }
  .fs-xl-1 {
    font-size: 1.5rem !important;
  }
  .fs-xl-2 {
    font-size: 2rem !important;
  }
  .fs-xl-3 {
    font-size: 2.5rem !important;
  }
  .fs-xl-4 {
    font-size: 3rem !important;
  }
  .fs-xl-5 {
    font-size: 3.5rem !important;
  }
  .fs-xl-6 {
    font-size: 4rem !important;
  }
  .fs-xl-7 {
    font-size: 4.5rem !important;
  }
  .fs-xl-8 {
    font-size: 5.125rem !important;
  }
  .fs-xl-9 {
    font-size: 6rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4.5rem !important;
  }
  .m-xxl-7 {
    margin: 6rem !important;
  }
  .m-xxl-8 {
    margin: 7.5rem !important;
  }
  .m-xxl-9 {
    margin: 9rem !important;
  }
  .m-xxl-10 {
    margin: 10rem !important;
  }
  .m-xxl-11 {
    margin: 12rem !important;
  }
  .m-xxl-12 {
    margin: 14.5rem !important;
  }
  .m-xxl-13 {
    margin: 15rem !important;
  }
  .m-xxl-14 {
    margin: 16rem !important;
  }
  .m-xxl-15 {
    margin: 17rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xxl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-11 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xxl-12 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important;
  }
  .mx-xxl-13 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-xxl-14 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xxl-15 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xxl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-11 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xxl-12 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important;
  }
  .my-xxl-13 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-xxl-14 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xxl-15 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 6rem !important;
  }
  .mt-xxl-8 {
    margin-top: 7.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 9rem !important;
  }
  .mt-xxl-10 {
    margin-top: 10rem !important;
  }
  .mt-xxl-11 {
    margin-top: 12rem !important;
  }
  .mt-xxl-12 {
    margin-top: 14.5rem !important;
  }
  .mt-xxl-13 {
    margin-top: 15rem !important;
  }
  .mt-xxl-14 {
    margin-top: 16rem !important;
  }
  .mt-xxl-15 {
    margin-top: 17rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 6rem !important;
  }
  .me-xxl-8 {
    margin-right: 7.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 9rem !important;
  }
  .me-xxl-10 {
    margin-right: 10rem !important;
  }
  .me-xxl-11 {
    margin-right: 12rem !important;
  }
  .me-xxl-12 {
    margin-right: 14.5rem !important;
  }
  .me-xxl-13 {
    margin-right: 15rem !important;
  }
  .me-xxl-14 {
    margin-right: 16rem !important;
  }
  .me-xxl-15 {
    margin-right: 17rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 14.5rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 15rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 16rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 17rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 6rem !important;
  }
  .ms-xxl-8 {
    margin-left: 7.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 9rem !important;
  }
  .ms-xxl-10 {
    margin-left: 10rem !important;
  }
  .ms-xxl-11 {
    margin-left: 12rem !important;
  }
  .ms-xxl-12 {
    margin-left: 14.5rem !important;
  }
  .ms-xxl-13 {
    margin-left: 15rem !important;
  }
  .ms-xxl-14 {
    margin-left: 16rem !important;
  }
  .ms-xxl-15 {
    margin-left: 17rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .m-xxl-n7 {
    margin: -6rem !important;
  }
  .m-xxl-n8 {
    margin: -7.5rem !important;
  }
  .m-xxl-n9 {
    margin: -9rem !important;
  }
  .m-xxl-n10 {
    margin: -10rem !important;
  }
  .m-xxl-n11 {
    margin: -12rem !important;
  }
  .m-xxl-n12 {
    margin: -14.5rem !important;
  }
  .m-xxl-n13 {
    margin: -15rem !important;
  }
  .m-xxl-n14 {
    margin: -16rem !important;
  }
  .m-xxl-n15 {
    margin: -17rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  .mx-xxl-n12 {
    margin-right: -14.5rem !important;
    margin-left: -14.5rem !important;
  }
  .mx-xxl-n13 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .mx-xxl-n14 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  .mx-xxl-n15 {
    margin-right: -17rem !important;
    margin-left: -17rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xxl-n10 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-xxl-n11 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  .my-xxl-n12 {
    margin-top: -14.5rem !important;
    margin-bottom: -14.5rem !important;
  }
  .my-xxl-n13 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .my-xxl-n14 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  .my-xxl-n15 {
    margin-top: -17rem !important;
    margin-bottom: -17rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -7.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -9rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -10rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -12rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -14.5rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -15rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -16rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -17rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -6rem !important;
  }
  .me-xxl-n8 {
    margin-right: -7.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -9rem !important;
  }
  .me-xxl-n10 {
    margin-right: -10rem !important;
  }
  .me-xxl-n11 {
    margin-right: -12rem !important;
  }
  .me-xxl-n12 {
    margin-right: -14.5rem !important;
  }
  .me-xxl-n13 {
    margin-right: -15rem !important;
  }
  .me-xxl-n14 {
    margin-right: -16rem !important;
  }
  .me-xxl-n15 {
    margin-right: -17rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -7.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -9rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -10rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -12rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -14.5rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -15rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -16rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -17rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -7.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -9rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -10rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -12rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -14.5rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -15rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -16rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -17rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6rem !important;
  }
  .p-xxl-8 {
    padding: 7.5rem !important;
  }
  .p-xxl-9 {
    padding: 9rem !important;
  }
  .p-xxl-10 {
    padding: 10rem !important;
  }
  .p-xxl-11 {
    padding: 12rem !important;
  }
  .p-xxl-12 {
    padding: 14.5rem !important;
  }
  .p-xxl-13 {
    padding: 15rem !important;
  }
  .p-xxl-14 {
    padding: 16rem !important;
  }
  .p-xxl-15 {
    padding: 17rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xxl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-11 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xxl-12 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important;
  }
  .px-xxl-13 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-xxl-14 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xxl-15 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xxl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-11 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xxl-12 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important;
  }
  .py-xxl-13 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-xxl-14 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xxl-15 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6rem !important;
  }
  .pt-xxl-8 {
    padding-top: 7.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 9rem !important;
  }
  .pt-xxl-10 {
    padding-top: 10rem !important;
  }
  .pt-xxl-11 {
    padding-top: 12rem !important;
  }
  .pt-xxl-12 {
    padding-top: 14.5rem !important;
  }
  .pt-xxl-13 {
    padding-top: 15rem !important;
  }
  .pt-xxl-14 {
    padding-top: 16rem !important;
  }
  .pt-xxl-15 {
    padding-top: 17rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6rem !important;
  }
  .pe-xxl-8 {
    padding-right: 7.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 9rem !important;
  }
  .pe-xxl-10 {
    padding-right: 10rem !important;
  }
  .pe-xxl-11 {
    padding-right: 12rem !important;
  }
  .pe-xxl-12 {
    padding-right: 14.5rem !important;
  }
  .pe-xxl-13 {
    padding-right: 15rem !important;
  }
  .pe-xxl-14 {
    padding-right: 16rem !important;
  }
  .pe-xxl-15 {
    padding-right: 17rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 12rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 14.5rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 15rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 16rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 17rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6rem !important;
  }
  .ps-xxl-8 {
    padding-left: 7.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 9rem !important;
  }
  .ps-xxl-10 {
    padding-left: 10rem !important;
  }
  .ps-xxl-11 {
    padding-left: 12rem !important;
  }
  .ps-xxl-12 {
    padding-left: 14.5rem !important;
  }
  .ps-xxl-13 {
    padding-left: 15rem !important;
  }
  .ps-xxl-14 {
    padding-left: 16rem !important;
  }
  .ps-xxl-15 {
    padding-left: 17rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 4.5rem !important;
  }
  .gap-xxl-7 {
    gap: 6rem !important;
  }
  .gap-xxl-8 {
    gap: 7.5rem !important;
  }
  .gap-xxl-9 {
    gap: 9rem !important;
  }
  .gap-xxl-10 {
    gap: 10rem !important;
  }
  .gap-xxl-11 {
    gap: 12rem !important;
  }
  .gap-xxl-12 {
    gap: 14.5rem !important;
  }
  .gap-xxl-13 {
    gap: 15rem !important;
  }
  .gap-xxl-14 {
    gap: 16rem !important;
  }
  .gap-xxl-15 {
    gap: 17rem !important;
  }
  .fs-xxl--3 {
    font-size: 0.75rem !important;
  }
  .fs-xxl--2 {
    font-size: 0.8125rem !important;
  }
  .fs-xxl--1 {
    font-size: 0.9375rem !important;
  }
  .fs-xxl-0 {
    font-size: 1.125rem !important;
  }
  .fs-xxl-1 {
    font-size: 1.5rem !important;
  }
  .fs-xxl-2 {
    font-size: 2rem !important;
  }
  .fs-xxl-3 {
    font-size: 2.5rem !important;
  }
  .fs-xxl-4 {
    font-size: 3rem !important;
  }
  .fs-xxl-5 {
    font-size: 3.5rem !important;
  }
  .fs-xxl-6 {
    font-size: 4rem !important;
  }
  .fs-xxl-7 {
    font-size: 4.5rem !important;
  }
  .fs-xxl-8 {
    font-size: 5.125rem !important;
  }
  .fs-xxl-9 {
    font-size: 6rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* ==========================================================================
   Background
   ========================================================================== */
.bg-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  overflow: hidden;
}
.bg-container.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(31, 39, 73, 0.5);
}

/* ==========================================================================
   Background text
   ========================================================================== */
[data-background-text],
.bg-text {
  position: relative;
}
[data-background-text]::after,
.bg-text::after {
  content: attr(data-background-text);
  font-family: "Oswald", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* ==========================================================================
   Buttons
   ========================================================================== */
.btn {
  text-transform: uppercase;
}

/* Button sizes
   ========================================================================== */
.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1.032rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-border-radius: 0;
}

/* ==========================================================================
   Card
   ========================================================================== */
.card-info.active, .card-info:hover {
  border-color: #f12639;
  background-color: #f12639;
  cursor: pointer;
}
.card-info.active *, .card-info:hover * {
  color: #fff;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 4;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

/* ==========================================================================
   Faq
   ========================================================================== */
.faq .card {
  padding-bottom: 0.5rem;
  border-left: none;
  border-right: none;
}
.faq .card-header {
  padding-left: 0;
  padding-right: 0;
  border: none;
  background-color: transparent;
}
.faq .card-header button {
  padding: 0;
  font-size: 1.5rem;
  font-weight: medium;
  text-transform: none;
  color: #1f2749;
  text-align: left;
  text-decoration: none;
}
.faq .card-header button::before {
  content: "\f273";
  display: inline !important;
  margin-right: 0.35em;
  vertical-align: baseline;
  font-size: 1.5rem;
}
.faq .card-header button.collapsed::before {
  content: "\f278";
}

/* ==========================================================================
   Footer
   ========================================================================== */
.footer {
  position: relative;
  background-color: #1f2749;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(31, 39, 73, 0.97);
}

/* ==========================================================================
   Forms
   ========================================================================== */
.form-control {
  font-family: "Oswald", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
}

.form-control-sm {
  font-size: 0.875rem;
}

.form-control-lg {
  font-size: 1.125rem;
}

/* ==========================================================================
   Icons
   ========================================================================== */
.icon {
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.35)) !important;
  display: flex;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  text-decoration: none !important;
  transition: transform 0.3s ease;
}
.icon i {
  display: inline-flex;
  margin: auto;
  color: #fff;
}
.icon:hover {
  transform: scale(1.1);
}
.icon.icon-sm {
  width: 55px;
  height: 55px;
}
.icon.icon-lg {
  width: 150px;
  height: 150px;
}

/* ==========================================================================
   Links
   ========================================================================== */
ul li a.active {
  text-decoration: underline;
}

/* ==========================================================================
   Move element
   ========================================================================== */
.move {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  text-align: center;
}
.move a {
  color: #6c757d;
  line-height: 0;
  opacity: 0.3;
  transition: opacity 0.35s ease;
}
.move a:hover {
  opacity: 0.7;
}

/* ==========================================================================
   SPYRE Navbar
   ========================================================================== */
/* Navbar
   ========================================================================== */
.spyre-navbar {
  height: 60px;
  flex-wrap: nowrap;
}
.spyre-navbar .navbar-brand {
  margin-top: 20px;
}
.spyre-navbar .navbar-text {
  position: relative;
  font-family: "Oswald", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}
.spyre-navbar .navbar-text a {
  transition: color 0.3s ease;
}

@media (min-width: 992px) {
  .spyre-navbar {
    height: 80px;
  }
}
/* Toggler
   ========================================================================== */
.menu-toggle {
  position: relative;
  width: 37px;
  min-width: 37px;
  height: 37px;
  left: 3px;
  top: -1px;
  cursor: pointer;
}
.menu-toggle span {
  background: #fff;
  transition-property: top, left, width, height;
  transition: 0.25s ease-in-out;
}
.menu-toggle .hamburger {
  position: absolute;
  height: 37px;
  width: 37px;
}
.menu-toggle .hamburger span {
  position: absolute;
  right: 4px;
  width: 30px;
  height: 3px;
}
.menu-toggle .hamburger span:nth-child(1) {
  top: 8px;
  transition-delay: 0.5s;
}
.menu-toggle .hamburger span:nth-child(2) {
  width: 20px;
  top: 17px;
  transition-delay: 0.625s;
}
.menu-toggle .hamburger span:nth-child(3) {
  bottom: 8px;
  width: 24px;
  transition-delay: 0.75s;
}
.menu-toggle .cross {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotate(45deg);
}
.menu-toggle .cross span {
  position: absolute;
}
.menu-toggle .cross span:nth-child(1) {
  height: 0%;
  width: 3px;
  top: 0;
  left: 17px;
  transition-delay: 0s;
}
.menu-toggle .cross span:nth-child(2) {
  width: 0%;
  height: 3px;
  top: 17px;
  left: 0;
  transition-delay: 0.25s;
}
.menu-toggle.open .hamburger span {
  width: 0%;
}
.menu-toggle.open .hamburger span:nth-child(1) {
  transition-delay: 0s;
}
.menu-toggle.open .hamburger span:nth-child(2) {
  transition-delay: 0.1s;
}
.menu-toggle.open .hamburger span:nth-child(3) {
  transition-delay: 0.2s;
}
.menu-toggle.open .cross span {
  background: #f12639;
}
.menu-toggle.open .cross span:nth-child(1) {
  height: 37px;
  transition-delay: 0.5s;
}
.menu-toggle.open .cross span:nth-child(2) {
  width: 37px;
  transition-delay: 0.3s;
}

/* Search
   ========================================================================== */
.search {
  position: relative;
}
.search i {
  font-size: 2.3rem;
  color: #fff;
  cursor: pointer;
}
.search .form-control {
  position: absolute;
  right: 50px;
  width: 0;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  transition: width 0.5s ease-in-out;
}
.search.open {
  width: 100%;
}
.search.open i {
  color: #f12639;
}
.search.open .form-control {
  width: calc(100% - 100px);
}
.search.open .form-control:focus {
  border-color: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}

/* Navbar nav
   ========================================================================== */
.spyre-navbar-nav-container {
  height: 100vh;
}

.spyre-navbar-nav {
  display: flex;
  align-items: center;
  position: relative;
  top: 82px;
  width: 100%;
  height: calc(100vh - 82px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 15px;
  font-family: "Oswald", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  animation: scale-down-ver-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.spyre-navbar-nav.open {
  animation: scale-up-ver-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
}

.spyre-nav {
  width: 100%;
  height: auto;
  margin: auto 0;
  padding: 0;
  list-style: none;
  list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
  list-style-type: none;
  text-align: center;
}
.spyre-nav .show .spyre-nav-link.dropdown-toggle::after {
  content: "\f2fc";
}
.spyre-nav .active .spyre-nav-link,
.spyre-nav .show .spyre-nav-link {
  color: #f12639;
}

.spyre-nav-item {
  margin-bottom: 0.75rem;
}
.spyre-nav-item .dropdown-menu {
  position: relative;
  margin: 0.5rem 0.5rem 0.625rem;
  padding: 0 1.25rem;
  float: none;
  border: none;
  background: none;
  text-align: inherit;
}

.spyre-nav-link {
  position: relative;
  display: block;
  font-weight: 300;
  font-size: 1.875rem;
  color: #1f2749;
  line-height: 2.25rem;
  text-transform: uppercase;
  transition: color 0.3s ease;
}
.spyre-nav-link:hover {
  text-decoration: none;
  color: #f12639;
}
.spyre-nav-link:focus {
  outline: 0;
}
.spyre-nav-link.dropdown-toggle::after {
  content: "\f2f9";
  display: inline !important;
  margin-left: 0.35em;
  border: none;
  vertical-align: baseline;
  line-height: 0;
  font-size: 1.875rem;
}

.dropdown-menu-item {
  margin-bottom: 0.4rem;
}
.dropdown-menu-item.active .dropdown-menu-link, .dropdown-menu-item.show .dropdown-menu-link {
  color: #f12639;
}

.dropdown-menu-link {
  position: relative;
  display: block;
  font-weight: 300;
  font-family: "Roboto", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1f2749;
  line-height: 2.25rem;
  font-size: 1.25rem;
  line-height: 1.4rem;
  transition: color 0.3s ease;
}
.dropdown-menu-link:hover {
  text-decoration: none;
  color: #f12639;
}

@media (min-width: 768px) {
  .spyre-navbar-nav {
    top: 100px;
    height: calc(100vh - 100px);
  }
  .spyre-nav {
    text-align: left;
  }
  .spyre-nav-item {
    margin-bottom: 0.875rem;
  }
  .spyre-nav-item .dropdown-menu::before {
    content: "";
    position: absolute;
    top: 0.3rem;
    bottom: 0.2rem;
    left: 0;
    width: 1px;
    background-color: #f12639;
  }
  .spyre-nav-link {
    font-size: 2.875rem;
    line-height: 3.5rem;
  }
  .spyre-nav-link.dropdown-toggle::after {
    font-size: 2.875rem;
  }
}
/* Navbar Ovrelay
   ========================================================================== */
.spyre-navbar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
  overflow: hidden;
  background: rgba(31, 39, 73, 0.995);
}
.spyre-navbar-overlay.open {
  visibility: visible;
}

@media (max-width: 991.98px) {
  .spyre-navbar-overlay .container {
    width: 100%;
    max-width: none;
  }
}
/* Ovrelay Effetcs
   ========================================================================== */
/* Overlay effect: Slide */
.overlay-slide {
  width: 0;
  min-height: 100%;
  top: 0;
  left: auto;
  right: 0;
  height: auto;
  transform-origin: 100% 0;
  transition-property: min-height, width, top, right, visibility;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.7, 1, 0.3, 1);
}
.overlay-slide.open {
  width: 100%;
  min-height: 100%;
  top: 0px;
  right: 0px;
}

/* Overlay effect: Fade */
.overlay-fade {
  opacity: 0;
  transition: opacity 0.5s, visibility 0s 0.5s;
}
.overlay-fade.open {
  opacity: 1;
  transition: opacity 0.5s;
}

/* Overlay effect: Fall down */
.overlay-fall-down {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s, transform 0.5s, visibility 0s 0.5s;
}
.overlay-fall-down.open {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 0.5s, transform 0.5s;
}

/* Overlay effect: Scale up */
.overlay-scale-up {
  opacity: 0;
  transform: scale(0.9);
  transition: transform 0.5s, opacity 0.4s, visibility 0s 0.5s;
}
.overlay-scale-up.open {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.5s, opacity 0.5s;
}

/* Overlay effect: Scale down */
.overlay-scale-down {
  opacity: 0;
  transform: scale(1.1);
  transition: transform 0.5s, opacity 0.5s, visibility 0s 0.5s;
}
.overlay-scale-down.open {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.5s, opacity 0.5s;
}

/* Overlay effect: Corner */
.overlay-corner {
  width: 0;
  min-height: 0;
  top: 0;
  left: auto;
  right: 0;
  height: 0;
  opacity: 0;
  transform-origin: 100% 0;
  transition-property: min-height, width, top, right, opacity, visibility;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}
.overlay-corner.open {
  opacity: 1;
  width: 100%;
  min-height: 100%;
  top: 0px;
  right: 0px;
}

/* Keyframes */
@keyframes scale-up-ver-center {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes scale-down-ver-center {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
/* ==========================================================================
   Preloader
   ========================================================================== */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1080;
  background-color: #fff;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.spinner .circles {
  position: relative;
  margin: 50px auto;
  width: 50px;
  height: 50px;
  text-align: center;
  animation: dotsRotate 1.5s infinite linear;
}
.spinner .circles::before, .spinner .circles::after {
  content: "";
  position: absolute;
  top: 0;
  width: 60%;
  height: 60%;
  display: inline-block;
  border-radius: 100%;
  background-color: #f12639;
  animation: dotsBounce 1.5s infinite ease-in-out;
}
.spinner .circles::after {
  top: auto;
  bottom: 0;
  animation-delay: -0.75s;
}

/* Keyframes */
@keyframes dotsRotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dotsBounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.fade-out {
  transition: opacity 0.25s ease-out;
  transition-delay: 750ms;
  opacity: 0;
}

/* ==========================================================================
   Reboot
   ========================================================================== */
html {
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

/* Code */
pre,
code,
kbd,
samp {
  font-size: 87.5%;
}

::-moz-selection {
  background: #f12639;
  color: #fff;
}

::selection {
  background: #f12639;
  color: #fff;
}

/* ==========================================================================
   Root
   ========================================================================== */
:root {
  --alpha: 0;
}

/* ==========================================================================
   Section
   ========================================================================== */
section {
  position: relative;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

@media (min-width: 992px) {
  section {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}
/* ==========================================================================
   Shapes
   ========================================================================== */
.square::before,
.rectangle::before {
  content: "";
  display: block;
}

.square::before {
  padding-top: 100%;
}

.rectangle::before {
  padding-top: 50%;
}
.rectangle.standing::before {
  padding-top: 200%;
}

/* ==========================================================================
   Type
   ========================================================================== */
/* Title underline
   ========================================================================== */
.title-underline {
  position: relative;
}
.title-underline::after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 50px;
  height: 1px;
  background-color: #f12639;
}

/* Misc
   ========================================================================== */
/* Blockquotes */
.blockquote {
  position: relative;
  padding-left: 1.75rem;
  padding-top: 1.5rem;
}
.blockquote::before {
  content: "“";
  position: absolute;
  left: 0;
  font-size: 2em;
  line-height: 1em;
  top: 0;
  color: #1a1e2c;
  font-family: "Oswald", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.blockquote-footer {
  font-weight: 500;
}
.blockquote-footer::before {
  display: none;
}

/* ==========================================================================
   Utilities
   ========================================================================== */
/* ==========================================================================
   Borders
   ========================================================================== */
/* Centered borders
   ========================================================================== */
.border-top-centered-10,
.border-end-centered-10,
.border-bottom-centered-10,
.border-start-centered-10 {
  position: relative;
}
.border-top-centered-10::before, .border-top-centered-10::after,
.border-end-centered-10::before,
.border-end-centered-10::after,
.border-bottom-centered-10::before,
.border-bottom-centered-10::after,
.border-start-centered-10::before,
.border-start-centered-10::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-10::before {
  top: 0;
  left: 45%;
  width: 10%;
  height: 1px !important;
}
.border-top-centered-10.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-10::after {
  right: 0;
  top: 45%;
  height: 10%;
  width: 1px !important;
}
.border-end-centered-10.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-10::after {
  bottom: 0;
  left: 45%;
  width: 10%;
  height: 1px !important;
}
.border-bottom-centered-10.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-10::before {
  top: 45%;
  left: 0;
  height: 10%;
  width: 1px !important;
}
.border-start-centered-10.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-20,
.border-end-centered-20,
.border-bottom-centered-20,
.border-start-centered-20 {
  position: relative;
}
.border-top-centered-20::before, .border-top-centered-20::after,
.border-end-centered-20::before,
.border-end-centered-20::after,
.border-bottom-centered-20::before,
.border-bottom-centered-20::after,
.border-start-centered-20::before,
.border-start-centered-20::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-20::before {
  top: 0;
  left: 40%;
  width: 20%;
  height: 1px !important;
}
.border-top-centered-20.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-20::after {
  right: 0;
  top: 40%;
  height: 20%;
  width: 1px !important;
}
.border-end-centered-20.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-20::after {
  bottom: 0;
  left: 40%;
  width: 20%;
  height: 1px !important;
}
.border-bottom-centered-20.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-20::before {
  top: 40%;
  left: 0;
  height: 20%;
  width: 1px !important;
}
.border-start-centered-20.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-30,
.border-end-centered-30,
.border-bottom-centered-30,
.border-start-centered-30 {
  position: relative;
}
.border-top-centered-30::before, .border-top-centered-30::after,
.border-end-centered-30::before,
.border-end-centered-30::after,
.border-bottom-centered-30::before,
.border-bottom-centered-30::after,
.border-start-centered-30::before,
.border-start-centered-30::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-30::before {
  top: 0;
  left: 35%;
  width: 30%;
  height: 1px !important;
}
.border-top-centered-30.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-30::after {
  right: 0;
  top: 35%;
  height: 30%;
  width: 1px !important;
}
.border-end-centered-30.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-30::after {
  bottom: 0;
  left: 35%;
  width: 30%;
  height: 1px !important;
}
.border-bottom-centered-30.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-30::before {
  top: 35%;
  left: 0;
  height: 30%;
  width: 1px !important;
}
.border-start-centered-30.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-40,
.border-end-centered-40,
.border-bottom-centered-40,
.border-start-centered-40 {
  position: relative;
}
.border-top-centered-40::before, .border-top-centered-40::after,
.border-end-centered-40::before,
.border-end-centered-40::after,
.border-bottom-centered-40::before,
.border-bottom-centered-40::after,
.border-start-centered-40::before,
.border-start-centered-40::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-40::before {
  top: 0;
  left: 30%;
  width: 40%;
  height: 1px !important;
}
.border-top-centered-40.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-40::after {
  right: 0;
  top: 30%;
  height: 40%;
  width: 1px !important;
}
.border-end-centered-40.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-40::after {
  bottom: 0;
  left: 30%;
  width: 40%;
  height: 1px !important;
}
.border-bottom-centered-40.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-40::before {
  top: 30%;
  left: 0;
  height: 40%;
  width: 1px !important;
}
.border-start-centered-40.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-50,
.border-end-centered-50,
.border-bottom-centered-50,
.border-start-centered-50 {
  position: relative;
}
.border-top-centered-50::before, .border-top-centered-50::after,
.border-end-centered-50::before,
.border-end-centered-50::after,
.border-bottom-centered-50::before,
.border-bottom-centered-50::after,
.border-start-centered-50::before,
.border-start-centered-50::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-50::before {
  top: 0;
  left: 25%;
  width: 50%;
  height: 1px !important;
}
.border-top-centered-50.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-50::after {
  right: 0;
  top: 25%;
  height: 50%;
  width: 1px !important;
}
.border-end-centered-50.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-50::after {
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 1px !important;
}
.border-bottom-centered-50.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-50::before {
  top: 25%;
  left: 0;
  height: 50%;
  width: 1px !important;
}
.border-start-centered-50.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-60,
.border-end-centered-60,
.border-bottom-centered-60,
.border-start-centered-60 {
  position: relative;
}
.border-top-centered-60::before, .border-top-centered-60::after,
.border-end-centered-60::before,
.border-end-centered-60::after,
.border-bottom-centered-60::before,
.border-bottom-centered-60::after,
.border-start-centered-60::before,
.border-start-centered-60::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-60::before {
  top: 0;
  left: 20%;
  width: 60%;
  height: 1px !important;
}
.border-top-centered-60.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-60::after {
  right: 0;
  top: 20%;
  height: 60%;
  width: 1px !important;
}
.border-end-centered-60.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-60::after {
  bottom: 0;
  left: 20%;
  width: 60%;
  height: 1px !important;
}
.border-bottom-centered-60.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-60::before {
  top: 20%;
  left: 0;
  height: 60%;
  width: 1px !important;
}
.border-start-centered-60.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-70,
.border-end-centered-70,
.border-bottom-centered-70,
.border-start-centered-70 {
  position: relative;
}
.border-top-centered-70::before, .border-top-centered-70::after,
.border-end-centered-70::before,
.border-end-centered-70::after,
.border-bottom-centered-70::before,
.border-bottom-centered-70::after,
.border-start-centered-70::before,
.border-start-centered-70::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-70::before {
  top: 0;
  left: 15%;
  width: 70%;
  height: 1px !important;
}
.border-top-centered-70.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-70::after {
  right: 0;
  top: 15%;
  height: 70%;
  width: 1px !important;
}
.border-end-centered-70.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-70::after {
  bottom: 0;
  left: 15%;
  width: 70%;
  height: 1px !important;
}
.border-bottom-centered-70.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-70::before {
  top: 15%;
  left: 0;
  height: 70%;
  width: 1px !important;
}
.border-start-centered-70.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-80,
.border-end-centered-80,
.border-bottom-centered-80,
.border-start-centered-80 {
  position: relative;
}
.border-top-centered-80::before, .border-top-centered-80::after,
.border-end-centered-80::before,
.border-end-centered-80::after,
.border-bottom-centered-80::before,
.border-bottom-centered-80::after,
.border-start-centered-80::before,
.border-start-centered-80::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-80::before {
  top: 0;
  left: 10%;
  width: 80%;
  height: 1px !important;
}
.border-top-centered-80.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-80::after {
  right: 0;
  top: 10%;
  height: 80%;
  width: 1px !important;
}
.border-end-centered-80.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-80::after {
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 1px !important;
}
.border-bottom-centered-80.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-80::before {
  top: 10%;
  left: 0;
  height: 80%;
  width: 1px !important;
}
.border-start-centered-80.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-90,
.border-end-centered-90,
.border-bottom-centered-90,
.border-start-centered-90 {
  position: relative;
}
.border-top-centered-90::before, .border-top-centered-90::after,
.border-end-centered-90::before,
.border-end-centered-90::after,
.border-bottom-centered-90::before,
.border-bottom-centered-90::after,
.border-start-centered-90::before,
.border-start-centered-90::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-90::before {
  top: 0;
  left: 5%;
  width: 90%;
  height: 1px !important;
}
.border-top-centered-90.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-90::after {
  right: 0;
  top: 5%;
  height: 90%;
  width: 1px !important;
}
.border-end-centered-90.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-90::after {
  bottom: 0;
  left: 5%;
  width: 90%;
  height: 1px !important;
}
.border-bottom-centered-90.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-90::before {
  top: 5%;
  left: 0;
  height: 90%;
  width: 1px !important;
}
.border-start-centered-90.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-top-centered-100,
.border-end-centered-100,
.border-bottom-centered-100,
.border-start-centered-100 {
  position: relative;
}
.border-top-centered-100::before, .border-top-centered-100::after,
.border-end-centered-100::before,
.border-end-centered-100::after,
.border-bottom-centered-100::before,
.border-bottom-centered-100::after,
.border-start-centered-100::before,
.border-start-centered-100::after {
  content: "";
  position: absolute;
  background: #d2d4db !important;
}

.border-top-centered-100::before {
  top: 0;
  left: 0%;
  width: 100%;
  height: 1px !important;
}
.border-top-centered-100.border-top-centered-faded::before {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-end-centered-100::after {
  right: 0;
  top: 0%;
  height: 100%;
  width: 1px !important;
}
.border-end-centered-100.border-end-centered-faded::after {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-bottom-centered-100::after {
  bottom: 0;
  left: 0%;
  width: 100%;
  height: 1px !important;
}
.border-bottom-centered-100.border-bottom-centered-faded::after {
  background: linear-gradient(to right, rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

.border-start-centered-100::before {
  top: 0%;
  left: 0;
  height: 100%;
  width: 1px !important;
}
.border-start-centered-100.border-start-centered-faded::before {
  background: linear-gradient(rgba(210, 212, 219, 0), #d2d4db 50%, rgba(210, 212, 219, 0)) !important;
}

/* ==========================================================================
   Position
   ========================================================================== */
.top--10 {
  top: -10px;
}

.end--10 {
  top: -10px;
}

.bottom--10 {
  top: -10px;
}

.start--10 {
  top: -10px;
}

.top--9 {
  top: -9px;
}

.end--9 {
  top: -9px;
}

.bottom--9 {
  top: -9px;
}

.start--9 {
  top: -9px;
}

.top--8 {
  top: -8px;
}

.end--8 {
  top: -8px;
}

.bottom--8 {
  top: -8px;
}

.start--8 {
  top: -8px;
}

.top--7 {
  top: -7px;
}

.end--7 {
  top: -7px;
}

.bottom--7 {
  top: -7px;
}

.start--7 {
  top: -7px;
}

.top--6 {
  top: -6px;
}

.end--6 {
  top: -6px;
}

.bottom--6 {
  top: -6px;
}

.start--6 {
  top: -6px;
}

.top--5 {
  top: -5px;
}

.end--5 {
  top: -5px;
}

.bottom--5 {
  top: -5px;
}

.start--5 {
  top: -5px;
}

.top--4 {
  top: -4px;
}

.end--4 {
  top: -4px;
}

.bottom--4 {
  top: -4px;
}

.start--4 {
  top: -4px;
}

.top--3 {
  top: -3px;
}

.end--3 {
  top: -3px;
}

.bottom--3 {
  top: -3px;
}

.start--3 {
  top: -3px;
}

.top--2 {
  top: -2px;
}

.end--2 {
  top: -2px;
}

.bottom--2 {
  top: -2px;
}

.start--2 {
  top: -2px;
}

.top--1 {
  top: -1px;
}

.end--1 {
  top: -1px;
}

.bottom--1 {
  top: -1px;
}

.start--1 {
  top: -1px;
}

.top-0 {
  top: 0px;
}

.end-0 {
  top: 0px;
}

.bottom-0 {
  top: 0px;
}

.start-0 {
  top: 0px;
}

.top-1 {
  top: 1px;
}

.end-1 {
  top: 1px;
}

.bottom-1 {
  top: 1px;
}

.start-1 {
  top: 1px;
}

.top-2 {
  top: 2px;
}

.end-2 {
  top: 2px;
}

.bottom-2 {
  top: 2px;
}

.start-2 {
  top: 2px;
}

.top-3 {
  top: 3px;
}

.end-3 {
  top: 3px;
}

.bottom-3 {
  top: 3px;
}

.start-3 {
  top: 3px;
}

.top-4 {
  top: 4px;
}

.end-4 {
  top: 4px;
}

.bottom-4 {
  top: 4px;
}

.start-4 {
  top: 4px;
}

.top-5 {
  top: 5px;
}

.end-5 {
  top: 5px;
}

.bottom-5 {
  top: 5px;
}

.start-5 {
  top: 5px;
}

.top-6 {
  top: 6px;
}

.end-6 {
  top: 6px;
}

.bottom-6 {
  top: 6px;
}

.start-6 {
  top: 6px;
}

.top-7 {
  top: 7px;
}

.end-7 {
  top: 7px;
}

.bottom-7 {
  top: 7px;
}

.start-7 {
  top: 7px;
}

.top-8 {
  top: 8px;
}

.end-8 {
  top: 8px;
}

.bottom-8 {
  top: 8px;
}

.start-8 {
  top: 8px;
}

.top-9 {
  top: 9px;
}

.end-9 {
  top: 9px;
}

.bottom-9 {
  top: 9px;
}

.start-9 {
  top: 9px;
}

.top-10 {
  top: 10px;
}

.end-10 {
  top: 10px;
}

.bottom-10 {
  top: 10px;
}

.start-10 {
  top: 10px;
}

/* ==========================================================================
   Helpers
   ========================================================================== */
/* Overflows
   ========================================================================== */
.overflow-scroll,
.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-scroll,
.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-hidden,
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-hidden,
.overflow-y-hidden {
  overflow-y: hidden !important;
}

.oveflow-auto,
.oveflow-x-auto {
  overflow-x: auto !important;
}

.oveflow-auto,
.oveflow-y-auto {
  overflow-y: auto !important;
}

.overflow-visible,
.overflow-x-visible {
  overflow: visible !important;
}

.overflow-visible,
.overflow-y-visible {
  overflow: visible !important;
}

@media (min-width: 576px) {
  .overflow-sm-scroll,
  .overflow-x-sm-scroll {
    overflow-x: scroll !important;
  }
  .overflow-sm-scroll,
  .overflow-y-sm-scroll {
    overflow-y: scroll !important;
  }
  .overflow-sm-hidden,
  .overflow-x-sm-hidden {
    overflow-x: hidden !important;
  }
  .overflow-sm-hidden,
  .overflow-y-sm-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-sm-auto,
  .oveflow-x-sm-auto {
    overflow-x: auto !important;
  }
  .oveflow-sm-auto,
  .oveflow-y-sm-auto {
    overflow-y: auto !important;
  }
  .overflow-sm-visible,
  .overflow-x-sm-visible {
    overflow: visible !important;
  }
  .overflow-sm-visible,
  .overflow-y-sm-visible {
    overflow: visible !important;
  }
}
@media (min-width: 768px) {
  .overflow-md-scroll,
  .overflow-x-md-scroll {
    overflow-x: scroll !important;
  }
  .overflow-md-scroll,
  .overflow-y-md-scroll {
    overflow-y: scroll !important;
  }
  .overflow-md-hidden,
  .overflow-x-md-hidden {
    overflow-x: hidden !important;
  }
  .overflow-md-hidden,
  .overflow-y-md-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-md-auto,
  .oveflow-x-md-auto {
    overflow-x: auto !important;
  }
  .oveflow-md-auto,
  .oveflow-y-md-auto {
    overflow-y: auto !important;
  }
  .overflow-md-visible,
  .overflow-x-md-visible {
    overflow: visible !important;
  }
  .overflow-md-visible,
  .overflow-y-md-visible {
    overflow: visible !important;
  }
}
@media (min-width: 992px) {
  .overflow-lg-scroll,
  .overflow-x-lg-scroll {
    overflow-x: scroll !important;
  }
  .overflow-lg-scroll,
  .overflow-y-lg-scroll {
    overflow-y: scroll !important;
  }
  .overflow-lg-hidden,
  .overflow-x-lg-hidden {
    overflow-x: hidden !important;
  }
  .overflow-lg-hidden,
  .overflow-y-lg-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-lg-auto,
  .oveflow-x-lg-auto {
    overflow-x: auto !important;
  }
  .oveflow-lg-auto,
  .oveflow-y-lg-auto {
    overflow-y: auto !important;
  }
  .overflow-lg-visible,
  .overflow-x-lg-visible {
    overflow: visible !important;
  }
  .overflow-lg-visible,
  .overflow-y-lg-visible {
    overflow: visible !important;
  }
}
@media (min-width: 1200px) {
  .overflow-xl-scroll,
  .overflow-x-xl-scroll {
    overflow-x: scroll !important;
  }
  .overflow-xl-scroll,
  .overflow-y-xl-scroll {
    overflow-y: scroll !important;
  }
  .overflow-xl-hidden,
  .overflow-x-xl-hidden {
    overflow-x: hidden !important;
  }
  .overflow-xl-hidden,
  .overflow-y-xl-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-xl-auto,
  .oveflow-x-xl-auto {
    overflow-x: auto !important;
  }
  .oveflow-xl-auto,
  .oveflow-y-xl-auto {
    overflow-y: auto !important;
  }
  .overflow-xl-visible,
  .overflow-x-xl-visible {
    overflow: visible !important;
  }
  .overflow-xl-visible,
  .overflow-y-xl-visible {
    overflow: visible !important;
  }
}
@media (min-width: 1600px) {
  .overflow-xxl-scroll,
  .overflow-x-xxl-scroll {
    overflow-x: scroll !important;
  }
  .overflow-xxl-scroll,
  .overflow-y-xxl-scroll {
    overflow-y: scroll !important;
  }
  .overflow-xxl-hidden,
  .overflow-x-xxl-hidden {
    overflow-x: hidden !important;
  }
  .overflow-xxl-hidden,
  .overflow-y-xxl-hidden {
    overflow-y: hidden !important;
  }
  .oveflow-xxl-auto,
  .oveflow-x-xxl-auto {
    overflow-x: auto !important;
  }
  .oveflow-xxl-auto,
  .oveflow-y-xxl-auto {
    overflow-y: auto !important;
  }
  .overflow-xxl-visible,
  .overflow-x-xxl-visible {
    overflow: visible !important;
  }
  .overflow-xxl-visible,
  .overflow-y-xxl-visible {
    overflow: visible !important;
  }
}
/* Extends
   ========================================================================== */
/**
 * Extend the area outside of the container.
 * For example good for extend background color
 */
.ext-s::before, .ext-s::after,
.ext-e::before,
.ext-e::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: inherit;
}

.ext-s::before {
  right: 100%;
}
@media (min-width: 576px) {
  .ext-s::before {
    width: calc(50vw - 270px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-s::before {
    width: calc(50vw - 360px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-s::before {
    width: calc(50vw - 480px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-s::before {
    width: calc(50vw - 570px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-s::before {
    width: calc(50vw - 650px + 2px);
  }
}
.ext-s.ext-t::after, .ext-s.ext-b::after {
  right: 100%;
  bottom: 100%;
  top: auto;
  height: 100%;
}
@media (min-width: 576px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 270px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 360px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 480px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 570px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-s.ext-t::after, .ext-s.ext-b::after {
    width: calc(50vw - 650px + 2px);
  }
}
.ext-s.ext-t.ext-h-100::after, .ext-s.ext-b.ext-h-100::after {
  height: 100%;
}
.ext-s.ext-t.ext-h-125::after, .ext-s.ext-b.ext-h-125::after {
  height: 125%;
}
.ext-s.ext-t.ext-h-150::after, .ext-s.ext-b.ext-h-150::after {
  height: 150%;
}
.ext-s.ext-t.ext-h-175::after, .ext-s.ext-b.ext-h-175::after {
  height: 175%;
}
.ext-s.ext-t.ext-h-200::after, .ext-s.ext-b.ext-h-200::after {
  height: 200%;
}
.ext-s.ext-b::after {
  bottom: auto;
  top: 100%;
}
.ext-s.ext-s-no-gutters::before {
  right: 100%;
}
@media (min-width: 576px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 270px + 15px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 360px + 15px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 480px + 15px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 570px + 15px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-s.ext-s-no-gutters::before {
    width: calc(50vw - 650px + 15px + 2px);
  }
}
.ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
  right: 100%;
}
@media (min-width: 576px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 270px + 15px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 360px + 15px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 480px + 15px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 570px + 15px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-s.ext-s-no-gutters.ext-t::after, .ext-s.ext-s-no-gutters.ext-b::after {
    width: calc(50vw - 650px + 15px + 2px);
  }
}

.ext-e::after {
  left: 100%;
}
@media (min-width: 576px) {
  .ext-e::after {
    width: calc(50vw - 270px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-e::after {
    width: calc(50vw - 360px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-e::after {
    width: calc(50vw - 480px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-e::after {
    width: calc(50vw - 570px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-e::after {
    width: calc(50vw - 650px + 2px);
  }
}
.ext-e.ext-t::before, .ext-e.ext-b::before {
  left: 100%;
  bottom: 100%;
  top: auto;
  height: 100%;
}
@media (min-width: 576px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 270px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 360px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 480px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 570px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-e.ext-t::before, .ext-e.ext-b::before {
    width: calc(50vw - 650px + 2px);
  }
}
.ext-e.ext-t.ext-h-100::before, .ext-e.ext-b.ext-h-100::before {
  height: 100%;
}
.ext-e.ext-t.ext-h-125::before, .ext-e.ext-b.ext-h-125::before {
  height: 125%;
}
.ext-e.ext-t.ext-h-150::before, .ext-e.ext-b.ext-h-150::before {
  height: 150%;
}
.ext-e.ext-t.ext-h-175::before, .ext-e.ext-b.ext-h-175::before {
  height: 175%;
}
.ext-e.ext-t.ext-h-200::before, .ext-e.ext-b.ext-h-200::before {
  height: 200%;
}
.ext-e.ext-b::before {
  bottom: auto;
  top: 100%;
}
.ext-e.ext-e-no-gutters::after {
  left: 100%;
}
@media (min-width: 576px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 270px + 15px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 360px + 15px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 480px + 15px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 570px + 15px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-e.ext-e-no-gutters::after {
    width: calc(50vw - 650px + 15px + 2px);
  }
}
.ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
  left: 100%;
}
@media (min-width: 576px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 270px + 15px + 2px);
  }
}
@media (min-width: 768px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 360px + 15px + 2px);
  }
}
@media (min-width: 992px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 480px + 15px + 2px);
  }
}
@media (min-width: 1200px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 570px + 15px + 2px);
  }
}
@media (min-width: 1600px) {
  .ext-e.ext-e-no-gutters.ext-t::after, .ext-e.ext-e-no-gutters.ext-b::after {
    width: calc(50vw - 650px + 15px + 2px);
  }
}

.release-container {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.unrelease-container {
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 576px) {
  .release-sm-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-sm-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .release-md-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-md-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .release-lg-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-lg-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .release-xl-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-xl-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1600px) {
  .release-xxl-container {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .unrelease-xxl-container {
    margin-left: 0;
    margin-right: 0;
  }
}
/* ==========================================================================
   Opacity
   ========================================================================== */
.opacity-0 {
  opacity: 0;
}

.opacity-0-hover {
  transition: opacity 0.35s ease;
}
.opacity-0-hover:hover {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-1-hover {
  transition: opacity 0.35s ease;
}
.opacity-1-hover:hover {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-2-hover {
  transition: opacity 0.35s ease;
}
.opacity-2-hover:hover {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-3-hover {
  transition: opacity 0.35s ease;
}
.opacity-3-hover:hover {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-4-hover {
  transition: opacity 0.35s ease;
}
.opacity-4-hover:hover {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-5-hover {
  transition: opacity 0.35s ease;
}
.opacity-5-hover:hover {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-6-hover {
  transition: opacity 0.35s ease;
}
.opacity-6-hover:hover {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-7-hover {
  transition: opacity 0.35s ease;
}
.opacity-7-hover:hover {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-8-hover {
  transition: opacity 0.35s ease;
}
.opacity-8-hover:hover {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-9-hover {
  transition: opacity 0.35s ease;
}
.opacity-9-hover:hover {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

.opacity-10-hover {
  transition: opacity 0.35s ease;
}
.opacity-10-hover:hover {
  opacity: 1;
}

/* ==========================================================================
   Background overlays
   ========================================================================== */
.overlay-0::before {
  background-color: rgba(31, 39, 73, 0) !important;
}

.overlay-primary-0::before {
  background-color: rgba(241, 38, 57, 0) !important;
}

.overlay-secondary-0::before {
  background-color: rgba(31, 39, 73, 0) !important;
}

.overlay-success-0::before {
  background-color: rgba(46, 205, 117, 0) !important;
}

.overlay-info-0::before {
  background-color: rgba(65, 198, 242, 0) !important;
}

.overlay-warning-0::before {
  background-color: rgba(251, 204, 93, 0) !important;
}

.overlay-danger-0::before {
  background-color: rgba(245, 84, 84, 0) !important;
}

.overlay-light-0::before {
  background-color: rgba(245, 245, 245, 0) !important;
}

.overlay-dark-0::before {
  background-color: rgba(52, 58, 64, 0) !important;
}

.overlay-blue-light-0::before {
  background-color: rgba(243, 248, 252, 0) !important;
}

.overlay-facebook-0::before {
  background-color: rgba(59, 89, 152, 0) !important;
}

.overlay-twitter-0::before {
  background-color: rgba(29, 161, 242, 0) !important;
}

.overlay-google-plus-0::before {
  background-color: rgba(219, 68, 55, 0) !important;
}

.overlay-instagram-0::before {
  background-color: rgba(195, 42, 163, 0) !important;
}

.overlay-pinterest-0::before {
  background-color: rgba(189, 8, 28, 0) !important;
}

.overlay-youtube-0::before {
  background-color: rgba(255, 0, 0, 0) !important;
}

.overlay-vimeo-0::before {
  background-color: rgba(26, 183, 234, 0) !important;
}

.overlay-slack-0::before {
  background-color: rgba(62, 184, 144, 0) !important;
}

.overlay-dribbble-0::before {
  background-color: rgba(234, 76, 137, 0) !important;
}

.overlay-linkedin-0::before {
  background-color: rgba(0, 123, 181, 0) !important;
}

.overlay-flickr-0::before {
  background-color: rgba(244, 0, 131, 0) !important;
}

.overlay-10::before {
  background-color: rgba(31, 39, 73, 0.1) !important;
}

.overlay-primary-10::before {
  background-color: rgba(241, 38, 57, 0.1) !important;
}

.overlay-secondary-10::before {
  background-color: rgba(31, 39, 73, 0.1) !important;
}

.overlay-success-10::before {
  background-color: rgba(46, 205, 117, 0.1) !important;
}

.overlay-info-10::before {
  background-color: rgba(65, 198, 242, 0.1) !important;
}

.overlay-warning-10::before {
  background-color: rgba(251, 204, 93, 0.1) !important;
}

.overlay-danger-10::before {
  background-color: rgba(245, 84, 84, 0.1) !important;
}

.overlay-light-10::before {
  background-color: rgba(245, 245, 245, 0.1) !important;
}

.overlay-dark-10::before {
  background-color: rgba(52, 58, 64, 0.1) !important;
}

.overlay-blue-light-10::before {
  background-color: rgba(243, 248, 252, 0.1) !important;
}

.overlay-facebook-10::before {
  background-color: rgba(59, 89, 152, 0.1) !important;
}

.overlay-twitter-10::before {
  background-color: rgba(29, 161, 242, 0.1) !important;
}

.overlay-google-plus-10::before {
  background-color: rgba(219, 68, 55, 0.1) !important;
}

.overlay-instagram-10::before {
  background-color: rgba(195, 42, 163, 0.1) !important;
}

.overlay-pinterest-10::before {
  background-color: rgba(189, 8, 28, 0.1) !important;
}

.overlay-youtube-10::before {
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.overlay-vimeo-10::before {
  background-color: rgba(26, 183, 234, 0.1) !important;
}

.overlay-slack-10::before {
  background-color: rgba(62, 184, 144, 0.1) !important;
}

.overlay-dribbble-10::before {
  background-color: rgba(234, 76, 137, 0.1) !important;
}

.overlay-linkedin-10::before {
  background-color: rgba(0, 123, 181, 0.1) !important;
}

.overlay-flickr-10::before {
  background-color: rgba(244, 0, 131, 0.1) !important;
}

.overlay-20::before {
  background-color: rgba(31, 39, 73, 0.2) !important;
}

.overlay-primary-20::before {
  background-color: rgba(241, 38, 57, 0.2) !important;
}

.overlay-secondary-20::before {
  background-color: rgba(31, 39, 73, 0.2) !important;
}

.overlay-success-20::before {
  background-color: rgba(46, 205, 117, 0.2) !important;
}

.overlay-info-20::before {
  background-color: rgba(65, 198, 242, 0.2) !important;
}

.overlay-warning-20::before {
  background-color: rgba(251, 204, 93, 0.2) !important;
}

.overlay-danger-20::before {
  background-color: rgba(245, 84, 84, 0.2) !important;
}

.overlay-light-20::before {
  background-color: rgba(245, 245, 245, 0.2) !important;
}

.overlay-dark-20::before {
  background-color: rgba(52, 58, 64, 0.2) !important;
}

.overlay-blue-light-20::before {
  background-color: rgba(243, 248, 252, 0.2) !important;
}

.overlay-facebook-20::before {
  background-color: rgba(59, 89, 152, 0.2) !important;
}

.overlay-twitter-20::before {
  background-color: rgba(29, 161, 242, 0.2) !important;
}

.overlay-google-plus-20::before {
  background-color: rgba(219, 68, 55, 0.2) !important;
}

.overlay-instagram-20::before {
  background-color: rgba(195, 42, 163, 0.2) !important;
}

.overlay-pinterest-20::before {
  background-color: rgba(189, 8, 28, 0.2) !important;
}

.overlay-youtube-20::before {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.overlay-vimeo-20::before {
  background-color: rgba(26, 183, 234, 0.2) !important;
}

.overlay-slack-20::before {
  background-color: rgba(62, 184, 144, 0.2) !important;
}

.overlay-dribbble-20::before {
  background-color: rgba(234, 76, 137, 0.2) !important;
}

.overlay-linkedin-20::before {
  background-color: rgba(0, 123, 181, 0.2) !important;
}

.overlay-flickr-20::before {
  background-color: rgba(244, 0, 131, 0.2) !important;
}

.overlay-30::before {
  background-color: rgba(31, 39, 73, 0.3) !important;
}

.overlay-primary-30::before {
  background-color: rgba(241, 38, 57, 0.3) !important;
}

.overlay-secondary-30::before {
  background-color: rgba(31, 39, 73, 0.3) !important;
}

.overlay-success-30::before {
  background-color: rgba(46, 205, 117, 0.3) !important;
}

.overlay-info-30::before {
  background-color: rgba(65, 198, 242, 0.3) !important;
}

.overlay-warning-30::before {
  background-color: rgba(251, 204, 93, 0.3) !important;
}

.overlay-danger-30::before {
  background-color: rgba(245, 84, 84, 0.3) !important;
}

.overlay-light-30::before {
  background-color: rgba(245, 245, 245, 0.3) !important;
}

.overlay-dark-30::before {
  background-color: rgba(52, 58, 64, 0.3) !important;
}

.overlay-blue-light-30::before {
  background-color: rgba(243, 248, 252, 0.3) !important;
}

.overlay-facebook-30::before {
  background-color: rgba(59, 89, 152, 0.3) !important;
}

.overlay-twitter-30::before {
  background-color: rgba(29, 161, 242, 0.3) !important;
}

.overlay-google-plus-30::before {
  background-color: rgba(219, 68, 55, 0.3) !important;
}

.overlay-instagram-30::before {
  background-color: rgba(195, 42, 163, 0.3) !important;
}

.overlay-pinterest-30::before {
  background-color: rgba(189, 8, 28, 0.3) !important;
}

.overlay-youtube-30::before {
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.overlay-vimeo-30::before {
  background-color: rgba(26, 183, 234, 0.3) !important;
}

.overlay-slack-30::before {
  background-color: rgba(62, 184, 144, 0.3) !important;
}

.overlay-dribbble-30::before {
  background-color: rgba(234, 76, 137, 0.3) !important;
}

.overlay-linkedin-30::before {
  background-color: rgba(0, 123, 181, 0.3) !important;
}

.overlay-flickr-30::before {
  background-color: rgba(244, 0, 131, 0.3) !important;
}

.overlay-40::before {
  background-color: rgba(31, 39, 73, 0.4) !important;
}

.overlay-primary-40::before {
  background-color: rgba(241, 38, 57, 0.4) !important;
}

.overlay-secondary-40::before {
  background-color: rgba(31, 39, 73, 0.4) !important;
}

.overlay-success-40::before {
  background-color: rgba(46, 205, 117, 0.4) !important;
}

.overlay-info-40::before {
  background-color: rgba(65, 198, 242, 0.4) !important;
}

.overlay-warning-40::before {
  background-color: rgba(251, 204, 93, 0.4) !important;
}

.overlay-danger-40::before {
  background-color: rgba(245, 84, 84, 0.4) !important;
}

.overlay-light-40::before {
  background-color: rgba(245, 245, 245, 0.4) !important;
}

.overlay-dark-40::before {
  background-color: rgba(52, 58, 64, 0.4) !important;
}

.overlay-blue-light-40::before {
  background-color: rgba(243, 248, 252, 0.4) !important;
}

.overlay-facebook-40::before {
  background-color: rgba(59, 89, 152, 0.4) !important;
}

.overlay-twitter-40::before {
  background-color: rgba(29, 161, 242, 0.4) !important;
}

.overlay-google-plus-40::before {
  background-color: rgba(219, 68, 55, 0.4) !important;
}

.overlay-instagram-40::before {
  background-color: rgba(195, 42, 163, 0.4) !important;
}

.overlay-pinterest-40::before {
  background-color: rgba(189, 8, 28, 0.4) !important;
}

.overlay-youtube-40::before {
  background-color: rgba(255, 0, 0, 0.4) !important;
}

.overlay-vimeo-40::before {
  background-color: rgba(26, 183, 234, 0.4) !important;
}

.overlay-slack-40::before {
  background-color: rgba(62, 184, 144, 0.4) !important;
}

.overlay-dribbble-40::before {
  background-color: rgba(234, 76, 137, 0.4) !important;
}

.overlay-linkedin-40::before {
  background-color: rgba(0, 123, 181, 0.4) !important;
}

.overlay-flickr-40::before {
  background-color: rgba(244, 0, 131, 0.4) !important;
}

.overlay-50::before {
  background-color: rgba(31, 39, 73, 0.5) !important;
}

.overlay-primary-50::before {
  background-color: rgba(241, 38, 57, 0.5) !important;
}

.overlay-secondary-50::before {
  background-color: rgba(31, 39, 73, 0.5) !important;
}

.overlay-success-50::before {
  background-color: rgba(46, 205, 117, 0.5) !important;
}

.overlay-info-50::before {
  background-color: rgba(65, 198, 242, 0.5) !important;
}

.overlay-warning-50::before {
  background-color: rgba(251, 204, 93, 0.5) !important;
}

.overlay-danger-50::before {
  background-color: rgba(245, 84, 84, 0.5) !important;
}

.overlay-light-50::before {
  background-color: rgba(245, 245, 245, 0.5) !important;
}

.overlay-dark-50::before {
  background-color: rgba(52, 58, 64, 0.5) !important;
}

.overlay-blue-light-50::before {
  background-color: rgba(243, 248, 252, 0.5) !important;
}

.overlay-facebook-50::before {
  background-color: rgba(59, 89, 152, 0.5) !important;
}

.overlay-twitter-50::before {
  background-color: rgba(29, 161, 242, 0.5) !important;
}

.overlay-google-plus-50::before {
  background-color: rgba(219, 68, 55, 0.5) !important;
}

.overlay-instagram-50::before {
  background-color: rgba(195, 42, 163, 0.5) !important;
}

.overlay-pinterest-50::before {
  background-color: rgba(189, 8, 28, 0.5) !important;
}

.overlay-youtube-50::before {
  background-color: rgba(255, 0, 0, 0.5) !important;
}

.overlay-vimeo-50::before {
  background-color: rgba(26, 183, 234, 0.5) !important;
}

.overlay-slack-50::before {
  background-color: rgba(62, 184, 144, 0.5) !important;
}

.overlay-dribbble-50::before {
  background-color: rgba(234, 76, 137, 0.5) !important;
}

.overlay-linkedin-50::before {
  background-color: rgba(0, 123, 181, 0.5) !important;
}

.overlay-flickr-50::before {
  background-color: rgba(244, 0, 131, 0.5) !important;
}

.overlay-60::before {
  background-color: rgba(31, 39, 73, 0.6) !important;
}

.overlay-primary-60::before {
  background-color: rgba(241, 38, 57, 0.6) !important;
}

.overlay-secondary-60::before {
  background-color: rgba(31, 39, 73, 0.6) !important;
}

.overlay-success-60::before {
  background-color: rgba(46, 205, 117, 0.6) !important;
}

.overlay-info-60::before {
  background-color: rgba(65, 198, 242, 0.6) !important;
}

.overlay-warning-60::before {
  background-color: rgba(251, 204, 93, 0.6) !important;
}

.overlay-danger-60::before {
  background-color: rgba(245, 84, 84, 0.6) !important;
}

.overlay-light-60::before {
  background-color: rgba(245, 245, 245, 0.6) !important;
}

.overlay-dark-60::before {
  background-color: rgba(52, 58, 64, 0.6) !important;
}

.overlay-blue-light-60::before {
  background-color: rgba(243, 248, 252, 0.6) !important;
}

.overlay-facebook-60::before {
  background-color: rgba(59, 89, 152, 0.6) !important;
}

.overlay-twitter-60::before {
  background-color: rgba(29, 161, 242, 0.6) !important;
}

.overlay-google-plus-60::before {
  background-color: rgba(219, 68, 55, 0.6) !important;
}

.overlay-instagram-60::before {
  background-color: rgba(195, 42, 163, 0.6) !important;
}

.overlay-pinterest-60::before {
  background-color: rgba(189, 8, 28, 0.6) !important;
}

.overlay-youtube-60::before {
  background-color: rgba(255, 0, 0, 0.6) !important;
}

.overlay-vimeo-60::before {
  background-color: rgba(26, 183, 234, 0.6) !important;
}

.overlay-slack-60::before {
  background-color: rgba(62, 184, 144, 0.6) !important;
}

.overlay-dribbble-60::before {
  background-color: rgba(234, 76, 137, 0.6) !important;
}

.overlay-linkedin-60::before {
  background-color: rgba(0, 123, 181, 0.6) !important;
}

.overlay-flickr-60::before {
  background-color: rgba(244, 0, 131, 0.6) !important;
}

.overlay-70::before {
  background-color: rgba(31, 39, 73, 0.7) !important;
}

.overlay-primary-70::before {
  background-color: rgba(241, 38, 57, 0.7) !important;
}

.overlay-secondary-70::before {
  background-color: rgba(31, 39, 73, 0.7) !important;
}

.overlay-success-70::before {
  background-color: rgba(46, 205, 117, 0.7) !important;
}

.overlay-info-70::before {
  background-color: rgba(65, 198, 242, 0.7) !important;
}

.overlay-warning-70::before {
  background-color: rgba(251, 204, 93, 0.7) !important;
}

.overlay-danger-70::before {
  background-color: rgba(245, 84, 84, 0.7) !important;
}

.overlay-light-70::before {
  background-color: rgba(245, 245, 245, 0.7) !important;
}

.overlay-dark-70::before {
  background-color: rgba(52, 58, 64, 0.7) !important;
}

.overlay-blue-light-70::before {
  background-color: rgba(243, 248, 252, 0.7) !important;
}

.overlay-facebook-70::before {
  background-color: rgba(59, 89, 152, 0.7) !important;
}

.overlay-twitter-70::before {
  background-color: rgba(29, 161, 242, 0.7) !important;
}

.overlay-google-plus-70::before {
  background-color: rgba(219, 68, 55, 0.7) !important;
}

.overlay-instagram-70::before {
  background-color: rgba(195, 42, 163, 0.7) !important;
}

.overlay-pinterest-70::before {
  background-color: rgba(189, 8, 28, 0.7) !important;
}

.overlay-youtube-70::before {
  background-color: rgba(255, 0, 0, 0.7) !important;
}

.overlay-vimeo-70::before {
  background-color: rgba(26, 183, 234, 0.7) !important;
}

.overlay-slack-70::before {
  background-color: rgba(62, 184, 144, 0.7) !important;
}

.overlay-dribbble-70::before {
  background-color: rgba(234, 76, 137, 0.7) !important;
}

.overlay-linkedin-70::before {
  background-color: rgba(0, 123, 181, 0.7) !important;
}

.overlay-flickr-70::before {
  background-color: rgba(244, 0, 131, 0.7) !important;
}

.overlay-80::before {
  background-color: rgba(31, 39, 73, 0.8) !important;
}

.overlay-primary-80::before {
  background-color: rgba(241, 38, 57, 0.8) !important;
}

.overlay-secondary-80::before {
  background-color: rgba(31, 39, 73, 0.8) !important;
}

.overlay-success-80::before {
  background-color: rgba(46, 205, 117, 0.8) !important;
}

.overlay-info-80::before {
  background-color: rgba(65, 198, 242, 0.8) !important;
}

.overlay-warning-80::before {
  background-color: rgba(251, 204, 93, 0.8) !important;
}

.overlay-danger-80::before {
  background-color: rgba(245, 84, 84, 0.8) !important;
}

.overlay-light-80::before {
  background-color: rgba(245, 245, 245, 0.8) !important;
}

.overlay-dark-80::before {
  background-color: rgba(52, 58, 64, 0.8) !important;
}

.overlay-blue-light-80::before {
  background-color: rgba(243, 248, 252, 0.8) !important;
}

.overlay-facebook-80::before {
  background-color: rgba(59, 89, 152, 0.8) !important;
}

.overlay-twitter-80::before {
  background-color: rgba(29, 161, 242, 0.8) !important;
}

.overlay-google-plus-80::before {
  background-color: rgba(219, 68, 55, 0.8) !important;
}

.overlay-instagram-80::before {
  background-color: rgba(195, 42, 163, 0.8) !important;
}

.overlay-pinterest-80::before {
  background-color: rgba(189, 8, 28, 0.8) !important;
}

.overlay-youtube-80::before {
  background-color: rgba(255, 0, 0, 0.8) !important;
}

.overlay-vimeo-80::before {
  background-color: rgba(26, 183, 234, 0.8) !important;
}

.overlay-slack-80::before {
  background-color: rgba(62, 184, 144, 0.8) !important;
}

.overlay-dribbble-80::before {
  background-color: rgba(234, 76, 137, 0.8) !important;
}

.overlay-linkedin-80::before {
  background-color: rgba(0, 123, 181, 0.8) !important;
}

.overlay-flickr-80::before {
  background-color: rgba(244, 0, 131, 0.8) !important;
}

.overlay-90::before {
  background-color: rgba(31, 39, 73, 0.9) !important;
}

.overlay-primary-90::before {
  background-color: rgba(241, 38, 57, 0.9) !important;
}

.overlay-secondary-90::before {
  background-color: rgba(31, 39, 73, 0.9) !important;
}

.overlay-success-90::before {
  background-color: rgba(46, 205, 117, 0.9) !important;
}

.overlay-info-90::before {
  background-color: rgba(65, 198, 242, 0.9) !important;
}

.overlay-warning-90::before {
  background-color: rgba(251, 204, 93, 0.9) !important;
}

.overlay-danger-90::before {
  background-color: rgba(245, 84, 84, 0.9) !important;
}

.overlay-light-90::before {
  background-color: rgba(245, 245, 245, 0.9) !important;
}

.overlay-dark-90::before {
  background-color: rgba(52, 58, 64, 0.9) !important;
}

.overlay-blue-light-90::before {
  background-color: rgba(243, 248, 252, 0.9) !important;
}

.overlay-facebook-90::before {
  background-color: rgba(59, 89, 152, 0.9) !important;
}

.overlay-twitter-90::before {
  background-color: rgba(29, 161, 242, 0.9) !important;
}

.overlay-google-plus-90::before {
  background-color: rgba(219, 68, 55, 0.9) !important;
}

.overlay-instagram-90::before {
  background-color: rgba(195, 42, 163, 0.9) !important;
}

.overlay-pinterest-90::before {
  background-color: rgba(189, 8, 28, 0.9) !important;
}

.overlay-youtube-90::before {
  background-color: rgba(255, 0, 0, 0.9) !important;
}

.overlay-vimeo-90::before {
  background-color: rgba(26, 183, 234, 0.9) !important;
}

.overlay-slack-90::before {
  background-color: rgba(62, 184, 144, 0.9) !important;
}

.overlay-dribbble-90::before {
  background-color: rgba(234, 76, 137, 0.9) !important;
}

.overlay-linkedin-90::before {
  background-color: rgba(0, 123, 181, 0.9) !important;
}

.overlay-flickr-90::before {
  background-color: rgba(244, 0, 131, 0.9) !important;
}

.overlay-100::before {
  background-color: #1f2749 !important;
}

.overlay-primary-100::before {
  background-color: #f12639 !important;
}

.overlay-secondary-100::before {
  background-color: #1f2749 !important;
}

.overlay-success-100::before {
  background-color: #2ecd75 !important;
}

.overlay-info-100::before {
  background-color: #41c6f2 !important;
}

.overlay-warning-100::before {
  background-color: #fbcc5d !important;
}

.overlay-danger-100::before {
  background-color: #f55454 !important;
}

.overlay-light-100::before {
  background-color: whitesmoke !important;
}

.overlay-dark-100::before {
  background-color: #343a40 !important;
}

.overlay-blue-light-100::before {
  background-color: #f3f8fc !important;
}

.overlay-facebook-100::before {
  background-color: #3b5998 !important;
}

.overlay-twitter-100::before {
  background-color: #1da1f2 !important;
}

.overlay-google-plus-100::before {
  background-color: #db4437 !important;
}

.overlay-instagram-100::before {
  background-color: #c32aa3 !important;
}

.overlay-pinterest-100::before {
  background-color: #bd081c !important;
}

.overlay-youtube-100::before {
  background-color: red !important;
}

.overlay-vimeo-100::before {
  background-color: #1ab7ea !important;
}

.overlay-slack-100::before {
  background-color: #3eb890 !important;
}

.overlay-dribbble-100::before {
  background-color: #ea4c89 !important;
}

.overlay-linkedin-100::before {
  background-color: #007bb5 !important;
}

.overlay-flickr-100::before {
  background-color: #f40083 !important;
}

/* ==========================================================================
   Sizing
   ========================================================================== */
/* Height values in vh */
.minh-100vh {
  min-height: 100vh;
}

.minh-50vh {
  min-height: 50vh;
}

.h-100vh {
  height: 100vh;
}

.h-50vh {
  height: 50vh;
}

/* ==========================================================================
   Spacing
   ========================================================================== */
/* Negative margins
   ========================================================================== */
.m-neg-0 {
  margin: 0 !important;
}

.mt-neg-0,
.my-neg-0 {
  margin-top: 0 !important;
}

.mr-neg-0,
.mx-neg-0 {
  margin-right: 0 !important;
}

.mb-neg-0,
.my-neg-0 {
  margin-bottom: 0 !important;
}

.ml-neg-0,
.mx-neg-0 {
  margin-left: 0 !important;
}

.m-neg-1 {
  margin: 0.25rem !important;
}

.mt-neg-1,
.my-neg-1 {
  margin-top: -0.25rem !important;
}

.mr-neg-1,
.mx-neg-1 {
  margin-right: -0.25rem !important;
}

.mb-neg-1,
.my-neg-1 {
  margin-bottom: -0.25rem !important;
}

.ml-neg-1,
.mx-neg-1 {
  margin-left: -0.25rem !important;
}

.m-neg-2 {
  margin: 0.5rem !important;
}

.mt-neg-2,
.my-neg-2 {
  margin-top: -0.5rem !important;
}

.mr-neg-2,
.mx-neg-2 {
  margin-right: -0.5rem !important;
}

.mb-neg-2,
.my-neg-2 {
  margin-bottom: -0.5rem !important;
}

.ml-neg-2,
.mx-neg-2 {
  margin-left: -0.5rem !important;
}

.m-neg-3 {
  margin: 1rem !important;
}

.mt-neg-3,
.my-neg-3 {
  margin-top: -1rem !important;
}

.mr-neg-3,
.mx-neg-3 {
  margin-right: -1rem !important;
}

.mb-neg-3,
.my-neg-3 {
  margin-bottom: -1rem !important;
}

.ml-neg-3,
.mx-neg-3 {
  margin-left: -1rem !important;
}

.m-neg-4 {
  margin: 1.5rem !important;
}

.mt-neg-4,
.my-neg-4 {
  margin-top: -1.5rem !important;
}

.mr-neg-4,
.mx-neg-4 {
  margin-right: -1.5rem !important;
}

.mb-neg-4,
.my-neg-4 {
  margin-bottom: -1.5rem !important;
}

.ml-neg-4,
.mx-neg-4 {
  margin-left: -1.5rem !important;
}

.m-neg-5 {
  margin: 3rem !important;
}

.mt-neg-5,
.my-neg-5 {
  margin-top: -3rem !important;
}

.mr-neg-5,
.mx-neg-5 {
  margin-right: -3rem !important;
}

.mb-neg-5,
.my-neg-5 {
  margin-bottom: -3rem !important;
}

.ml-neg-5,
.mx-neg-5 {
  margin-left: -3rem !important;
}

.m-neg-6 {
  margin: 4.5rem !important;
}

.mt-neg-6,
.my-neg-6 {
  margin-top: -4.5rem !important;
}

.mr-neg-6,
.mx-neg-6 {
  margin-right: -4.5rem !important;
}

.mb-neg-6,
.my-neg-6 {
  margin-bottom: -4.5rem !important;
}

.ml-neg-6,
.mx-neg-6 {
  margin-left: -4.5rem !important;
}

.m-neg-7 {
  margin: 6rem !important;
}

.mt-neg-7,
.my-neg-7 {
  margin-top: -6rem !important;
}

.mr-neg-7,
.mx-neg-7 {
  margin-right: -6rem !important;
}

.mb-neg-7,
.my-neg-7 {
  margin-bottom: -6rem !important;
}

.ml-neg-7,
.mx-neg-7 {
  margin-left: -6rem !important;
}

.m-neg-8 {
  margin: 7.5rem !important;
}

.mt-neg-8,
.my-neg-8 {
  margin-top: -7.5rem !important;
}

.mr-neg-8,
.mx-neg-8 {
  margin-right: -7.5rem !important;
}

.mb-neg-8,
.my-neg-8 {
  margin-bottom: -7.5rem !important;
}

.ml-neg-8,
.mx-neg-8 {
  margin-left: -7.5rem !important;
}

.m-neg-9 {
  margin: 9rem !important;
}

.mt-neg-9,
.my-neg-9 {
  margin-top: -9rem !important;
}

.mr-neg-9,
.mx-neg-9 {
  margin-right: -9rem !important;
}

.mb-neg-9,
.my-neg-9 {
  margin-bottom: -9rem !important;
}

.ml-neg-9,
.mx-neg-9 {
  margin-left: -9rem !important;
}

.m-neg-10 {
  margin: 10rem !important;
}

.mt-neg-10,
.my-neg-10 {
  margin-top: -10rem !important;
}

.mr-neg-10,
.mx-neg-10 {
  margin-right: -10rem !important;
}

.mb-neg-10,
.my-neg-10 {
  margin-bottom: -10rem !important;
}

.ml-neg-10,
.mx-neg-10 {
  margin-left: -10rem !important;
}

.m-neg-11 {
  margin: 12rem !important;
}

.mt-neg-11,
.my-neg-11 {
  margin-top: -12rem !important;
}

.mr-neg-11,
.mx-neg-11 {
  margin-right: -12rem !important;
}

.mb-neg-11,
.my-neg-11 {
  margin-bottom: -12rem !important;
}

.ml-neg-11,
.mx-neg-11 {
  margin-left: -12rem !important;
}

.m-neg-12 {
  margin: 14.5rem !important;
}

.mt-neg-12,
.my-neg-12 {
  margin-top: -14.5rem !important;
}

.mr-neg-12,
.mx-neg-12 {
  margin-right: -14.5rem !important;
}

.mb-neg-12,
.my-neg-12 {
  margin-bottom: -14.5rem !important;
}

.ml-neg-12,
.mx-neg-12 {
  margin-left: -14.5rem !important;
}

.m-neg-13 {
  margin: 15rem !important;
}

.mt-neg-13,
.my-neg-13 {
  margin-top: -15rem !important;
}

.mr-neg-13,
.mx-neg-13 {
  margin-right: -15rem !important;
}

.mb-neg-13,
.my-neg-13 {
  margin-bottom: -15rem !important;
}

.ml-neg-13,
.mx-neg-13 {
  margin-left: -15rem !important;
}

.m-neg-14 {
  margin: 16rem !important;
}

.mt-neg-14,
.my-neg-14 {
  margin-top: -16rem !important;
}

.mr-neg-14,
.mx-neg-14 {
  margin-right: -16rem !important;
}

.mb-neg-14,
.my-neg-14 {
  margin-bottom: -16rem !important;
}

.ml-neg-14,
.mx-neg-14 {
  margin-left: -16rem !important;
}

.m-neg-15 {
  margin: 17rem !important;
}

.mt-neg-15,
.my-neg-15 {
  margin-top: -17rem !important;
}

.mr-neg-15,
.mx-neg-15 {
  margin-right: -17rem !important;
}

.mb-neg-15,
.my-neg-15 {
  margin-bottom: -17rem !important;
}

.ml-neg-15,
.mx-neg-15 {
  margin-left: -17rem !important;
}

@media (min-width: 576px) {
  .m-sm-neg-0 {
    margin: 0 !important;
  }
  .mt-sm-neg-0,
  .my-sm-neg-0 {
    margin-top: 0 !important;
  }
  .mr-sm-neg-0,
  .mx-sm-neg-0 {
    margin-right: 0 !important;
  }
  .mb-sm-neg-0,
  .my-sm-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-neg-0,
  .mx-sm-neg-0 {
    margin-left: 0 !important;
  }
  .m-sm-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-neg-1,
  .my-sm-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-neg-1,
  .mx-sm-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-neg-1,
  .my-sm-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-neg-1,
  .mx-sm-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-neg-2,
  .my-sm-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-neg-2,
  .mx-sm-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-neg-2,
  .my-sm-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-neg-2,
  .mx-sm-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-neg-3 {
    margin: 1rem !important;
  }
  .mt-sm-neg-3,
  .my-sm-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-sm-neg-3,
  .mx-sm-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-sm-neg-3,
  .my-sm-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-neg-3,
  .mx-sm-neg-3 {
    margin-left: -1rem !important;
  }
  .m-sm-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-neg-4,
  .my-sm-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-neg-4,
  .mx-sm-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-neg-4,
  .my-sm-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-neg-4,
  .mx-sm-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-neg-5 {
    margin: 3rem !important;
  }
  .mt-sm-neg-5,
  .my-sm-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-sm-neg-5,
  .mx-sm-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-sm-neg-5,
  .my-sm-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-neg-5,
  .mx-sm-neg-5 {
    margin-left: -3rem !important;
  }
  .m-sm-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-neg-6,
  .my-sm-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-neg-6,
  .mx-sm-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-neg-6,
  .my-sm-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-neg-6,
  .mx-sm-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-sm-neg-7 {
    margin: 6rem !important;
  }
  .mt-sm-neg-7,
  .my-sm-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-sm-neg-7,
  .mx-sm-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-sm-neg-7,
  .my-sm-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-neg-7,
  .mx-sm-neg-7 {
    margin-left: -6rem !important;
  }
  .m-sm-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-sm-neg-8,
  .my-sm-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-neg-8,
  .mx-sm-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-neg-8,
  .my-sm-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-neg-8,
  .mx-sm-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-sm-neg-9 {
    margin: 9rem !important;
  }
  .mt-sm-neg-9,
  .my-sm-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-sm-neg-9,
  .mx-sm-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-sm-neg-9,
  .my-sm-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-neg-9,
  .mx-sm-neg-9 {
    margin-left: -9rem !important;
  }
  .m-sm-neg-10 {
    margin: 10rem !important;
  }
  .mt-sm-neg-10,
  .my-sm-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-sm-neg-10,
  .mx-sm-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-sm-neg-10,
  .my-sm-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-neg-10,
  .mx-sm-neg-10 {
    margin-left: -10rem !important;
  }
  .m-sm-neg-11 {
    margin: 12rem !important;
  }
  .mt-sm-neg-11,
  .my-sm-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-sm-neg-11,
  .mx-sm-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-sm-neg-11,
  .my-sm-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-neg-11,
  .mx-sm-neg-11 {
    margin-left: -12rem !important;
  }
  .m-sm-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-sm-neg-12,
  .my-sm-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-sm-neg-12,
  .mx-sm-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-sm-neg-12,
  .my-sm-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-sm-neg-12,
  .mx-sm-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-sm-neg-13 {
    margin: 15rem !important;
  }
  .mt-sm-neg-13,
  .my-sm-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-sm-neg-13,
  .mx-sm-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-sm-neg-13,
  .my-sm-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-sm-neg-13,
  .mx-sm-neg-13 {
    margin-left: -15rem !important;
  }
  .m-sm-neg-14 {
    margin: 16rem !important;
  }
  .mt-sm-neg-14,
  .my-sm-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-sm-neg-14,
  .mx-sm-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-sm-neg-14,
  .my-sm-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-sm-neg-14,
  .mx-sm-neg-14 {
    margin-left: -16rem !important;
  }
  .m-sm-neg-15 {
    margin: 17rem !important;
  }
  .mt-sm-neg-15,
  .my-sm-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-sm-neg-15,
  .mx-sm-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-sm-neg-15,
  .my-sm-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-sm-neg-15,
  .mx-sm-neg-15 {
    margin-left: -17rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-neg-0 {
    margin: 0 !important;
  }
  .mt-md-neg-0,
  .my-md-neg-0 {
    margin-top: 0 !important;
  }
  .mr-md-neg-0,
  .mx-md-neg-0 {
    margin-right: 0 !important;
  }
  .mb-md-neg-0,
  .my-md-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-neg-0,
  .mx-md-neg-0 {
    margin-left: 0 !important;
  }
  .m-md-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-md-neg-1,
  .my-md-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-neg-1,
  .mx-md-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-neg-1,
  .my-md-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-neg-1,
  .mx-md-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-md-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-md-neg-2,
  .my-md-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-neg-2,
  .mx-md-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-neg-2,
  .my-md-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-neg-2,
  .mx-md-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-md-neg-3 {
    margin: 1rem !important;
  }
  .mt-md-neg-3,
  .my-md-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-md-neg-3,
  .mx-md-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-md-neg-3,
  .my-md-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-neg-3,
  .mx-md-neg-3 {
    margin-left: -1rem !important;
  }
  .m-md-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-md-neg-4,
  .my-md-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-neg-4,
  .mx-md-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-neg-4,
  .my-md-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-neg-4,
  .mx-md-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-md-neg-5 {
    margin: 3rem !important;
  }
  .mt-md-neg-5,
  .my-md-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-md-neg-5,
  .mx-md-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-md-neg-5,
  .my-md-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-neg-5,
  .mx-md-neg-5 {
    margin-left: -3rem !important;
  }
  .m-md-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-md-neg-6,
  .my-md-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-md-neg-6,
  .mx-md-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-md-neg-6,
  .my-md-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-neg-6,
  .mx-md-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-md-neg-7 {
    margin: 6rem !important;
  }
  .mt-md-neg-7,
  .my-md-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-md-neg-7,
  .mx-md-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-md-neg-7,
  .my-md-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-md-neg-7,
  .mx-md-neg-7 {
    margin-left: -6rem !important;
  }
  .m-md-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-md-neg-8,
  .my-md-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-md-neg-8,
  .mx-md-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-md-neg-8,
  .my-md-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-neg-8,
  .mx-md-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-md-neg-9 {
    margin: 9rem !important;
  }
  .mt-md-neg-9,
  .my-md-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-md-neg-9,
  .mx-md-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-md-neg-9,
  .my-md-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-md-neg-9,
  .mx-md-neg-9 {
    margin-left: -9rem !important;
  }
  .m-md-neg-10 {
    margin: 10rem !important;
  }
  .mt-md-neg-10,
  .my-md-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-md-neg-10,
  .mx-md-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-md-neg-10,
  .my-md-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-md-neg-10,
  .mx-md-neg-10 {
    margin-left: -10rem !important;
  }
  .m-md-neg-11 {
    margin: 12rem !important;
  }
  .mt-md-neg-11,
  .my-md-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-md-neg-11,
  .mx-md-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-md-neg-11,
  .my-md-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-md-neg-11,
  .mx-md-neg-11 {
    margin-left: -12rem !important;
  }
  .m-md-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-md-neg-12,
  .my-md-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-md-neg-12,
  .mx-md-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-md-neg-12,
  .my-md-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-md-neg-12,
  .mx-md-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-md-neg-13 {
    margin: 15rem !important;
  }
  .mt-md-neg-13,
  .my-md-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-md-neg-13,
  .mx-md-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-md-neg-13,
  .my-md-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-md-neg-13,
  .mx-md-neg-13 {
    margin-left: -15rem !important;
  }
  .m-md-neg-14 {
    margin: 16rem !important;
  }
  .mt-md-neg-14,
  .my-md-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-md-neg-14,
  .mx-md-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-md-neg-14,
  .my-md-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-md-neg-14,
  .mx-md-neg-14 {
    margin-left: -16rem !important;
  }
  .m-md-neg-15 {
    margin: 17rem !important;
  }
  .mt-md-neg-15,
  .my-md-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-md-neg-15,
  .mx-md-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-md-neg-15,
  .my-md-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-md-neg-15,
  .mx-md-neg-15 {
    margin-left: -17rem !important;
  }
}
@media (min-width: 992px) {
  .m-lg-neg-0 {
    margin: 0 !important;
  }
  .mt-lg-neg-0,
  .my-lg-neg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-neg-0,
  .mx-lg-neg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-neg-0,
  .my-lg-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-neg-0,
  .mx-lg-neg-0 {
    margin-left: 0 !important;
  }
  .m-lg-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-neg-1,
  .my-lg-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-neg-1,
  .mx-lg-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-neg-1,
  .my-lg-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-neg-1,
  .mx-lg-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-neg-2,
  .my-lg-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-neg-2,
  .mx-lg-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-neg-2,
  .my-lg-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-neg-2,
  .mx-lg-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-neg-3 {
    margin: 1rem !important;
  }
  .mt-lg-neg-3,
  .my-lg-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-lg-neg-3,
  .mx-lg-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-lg-neg-3,
  .my-lg-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-neg-3,
  .mx-lg-neg-3 {
    margin-left: -1rem !important;
  }
  .m-lg-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-neg-4,
  .my-lg-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-neg-4,
  .mx-lg-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-neg-4,
  .my-lg-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-neg-4,
  .mx-lg-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-neg-5 {
    margin: 3rem !important;
  }
  .mt-lg-neg-5,
  .my-lg-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-lg-neg-5,
  .mx-lg-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-lg-neg-5,
  .my-lg-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-neg-5,
  .mx-lg-neg-5 {
    margin-left: -3rem !important;
  }
  .m-lg-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-neg-6,
  .my-lg-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-neg-6,
  .mx-lg-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-neg-6,
  .my-lg-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-neg-6,
  .mx-lg-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-lg-neg-7 {
    margin: 6rem !important;
  }
  .mt-lg-neg-7,
  .my-lg-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-lg-neg-7,
  .mx-lg-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-lg-neg-7,
  .my-lg-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-neg-7,
  .mx-lg-neg-7 {
    margin-left: -6rem !important;
  }
  .m-lg-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-lg-neg-8,
  .my-lg-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-neg-8,
  .mx-lg-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-neg-8,
  .my-lg-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-neg-8,
  .mx-lg-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-lg-neg-9 {
    margin: 9rem !important;
  }
  .mt-lg-neg-9,
  .my-lg-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-lg-neg-9,
  .mx-lg-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-lg-neg-9,
  .my-lg-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-neg-9,
  .mx-lg-neg-9 {
    margin-left: -9rem !important;
  }
  .m-lg-neg-10 {
    margin: 10rem !important;
  }
  .mt-lg-neg-10,
  .my-lg-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-lg-neg-10,
  .mx-lg-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-lg-neg-10,
  .my-lg-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-neg-10,
  .mx-lg-neg-10 {
    margin-left: -10rem !important;
  }
  .m-lg-neg-11 {
    margin: 12rem !important;
  }
  .mt-lg-neg-11,
  .my-lg-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-lg-neg-11,
  .mx-lg-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-lg-neg-11,
  .my-lg-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-neg-11,
  .mx-lg-neg-11 {
    margin-left: -12rem !important;
  }
  .m-lg-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-lg-neg-12,
  .my-lg-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-lg-neg-12,
  .mx-lg-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-lg-neg-12,
  .my-lg-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-lg-neg-12,
  .mx-lg-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-lg-neg-13 {
    margin: 15rem !important;
  }
  .mt-lg-neg-13,
  .my-lg-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-lg-neg-13,
  .mx-lg-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-lg-neg-13,
  .my-lg-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-lg-neg-13,
  .mx-lg-neg-13 {
    margin-left: -15rem !important;
  }
  .m-lg-neg-14 {
    margin: 16rem !important;
  }
  .mt-lg-neg-14,
  .my-lg-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-lg-neg-14,
  .mx-lg-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-lg-neg-14,
  .my-lg-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-lg-neg-14,
  .mx-lg-neg-14 {
    margin-left: -16rem !important;
  }
  .m-lg-neg-15 {
    margin: 17rem !important;
  }
  .mt-lg-neg-15,
  .my-lg-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-lg-neg-15,
  .mx-lg-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-lg-neg-15,
  .my-lg-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-lg-neg-15,
  .mx-lg-neg-15 {
    margin-left: -17rem !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-neg-0 {
    margin: 0 !important;
  }
  .mt-xl-neg-0,
  .my-xl-neg-0 {
    margin-top: 0 !important;
  }
  .mr-xl-neg-0,
  .mx-xl-neg-0 {
    margin-right: 0 !important;
  }
  .mb-xl-neg-0,
  .my-xl-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-neg-0,
  .mx-xl-neg-0 {
    margin-left: 0 !important;
  }
  .m-xl-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-neg-1,
  .my-xl-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-neg-1,
  .mx-xl-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-neg-1,
  .my-xl-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-neg-1,
  .mx-xl-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-neg-2,
  .my-xl-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-neg-2,
  .mx-xl-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-neg-2,
  .my-xl-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-neg-2,
  .mx-xl-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-neg-3 {
    margin: 1rem !important;
  }
  .mt-xl-neg-3,
  .my-xl-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-xl-neg-3,
  .mx-xl-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-xl-neg-3,
  .my-xl-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-neg-3,
  .mx-xl-neg-3 {
    margin-left: -1rem !important;
  }
  .m-xl-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-neg-4,
  .my-xl-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-neg-4,
  .mx-xl-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-neg-4,
  .my-xl-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-neg-4,
  .mx-xl-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-neg-5 {
    margin: 3rem !important;
  }
  .mt-xl-neg-5,
  .my-xl-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-xl-neg-5,
  .mx-xl-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-xl-neg-5,
  .my-xl-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-neg-5,
  .mx-xl-neg-5 {
    margin-left: -3rem !important;
  }
  .m-xl-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-neg-6,
  .my-xl-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-neg-6,
  .mx-xl-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-neg-6,
  .my-xl-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-neg-6,
  .mx-xl-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-xl-neg-7 {
    margin: 6rem !important;
  }
  .mt-xl-neg-7,
  .my-xl-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-xl-neg-7,
  .mx-xl-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-xl-neg-7,
  .my-xl-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-neg-7,
  .mx-xl-neg-7 {
    margin-left: -6rem !important;
  }
  .m-xl-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-xl-neg-8,
  .my-xl-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-neg-8,
  .mx-xl-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-neg-8,
  .my-xl-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-neg-8,
  .mx-xl-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-xl-neg-9 {
    margin: 9rem !important;
  }
  .mt-xl-neg-9,
  .my-xl-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-xl-neg-9,
  .mx-xl-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-xl-neg-9,
  .my-xl-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-neg-9,
  .mx-xl-neg-9 {
    margin-left: -9rem !important;
  }
  .m-xl-neg-10 {
    margin: 10rem !important;
  }
  .mt-xl-neg-10,
  .my-xl-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-xl-neg-10,
  .mx-xl-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-xl-neg-10,
  .my-xl-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-neg-10,
  .mx-xl-neg-10 {
    margin-left: -10rem !important;
  }
  .m-xl-neg-11 {
    margin: 12rem !important;
  }
  .mt-xl-neg-11,
  .my-xl-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-xl-neg-11,
  .mx-xl-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-xl-neg-11,
  .my-xl-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-neg-11,
  .mx-xl-neg-11 {
    margin-left: -12rem !important;
  }
  .m-xl-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-xl-neg-12,
  .my-xl-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-xl-neg-12,
  .mx-xl-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-xl-neg-12,
  .my-xl-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-xl-neg-12,
  .mx-xl-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-xl-neg-13 {
    margin: 15rem !important;
  }
  .mt-xl-neg-13,
  .my-xl-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-xl-neg-13,
  .mx-xl-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-xl-neg-13,
  .my-xl-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-xl-neg-13,
  .mx-xl-neg-13 {
    margin-left: -15rem !important;
  }
  .m-xl-neg-14 {
    margin: 16rem !important;
  }
  .mt-xl-neg-14,
  .my-xl-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-xl-neg-14,
  .mx-xl-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-xl-neg-14,
  .my-xl-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-xl-neg-14,
  .mx-xl-neg-14 {
    margin-left: -16rem !important;
  }
  .m-xl-neg-15 {
    margin: 17rem !important;
  }
  .mt-xl-neg-15,
  .my-xl-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-xl-neg-15,
  .mx-xl-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-xl-neg-15,
  .my-xl-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-xl-neg-15,
  .mx-xl-neg-15 {
    margin-left: -17rem !important;
  }
}
@media (min-width: 1600px) {
  .m-xxl-neg-0 {
    margin: 0 !important;
  }
  .mt-xxl-neg-0,
  .my-xxl-neg-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-neg-0,
  .mx-xxl-neg-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-neg-0,
  .my-xxl-neg-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-neg-0,
  .mx-xxl-neg-0 {
    margin-left: 0 !important;
  }
  .m-xxl-neg-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-neg-1,
  .my-xxl-neg-1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-neg-1,
  .mx-xxl-neg-1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-neg-1,
  .my-xxl-neg-1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-neg-1,
  .mx-xxl-neg-1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-neg-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-neg-2,
  .my-xxl-neg-2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-neg-2,
  .mx-xxl-neg-2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-neg-2,
  .my-xxl-neg-2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-neg-2,
  .mx-xxl-neg-2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-neg-3 {
    margin: 1rem !important;
  }
  .mt-xxl-neg-3,
  .my-xxl-neg-3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-neg-3,
  .mx-xxl-neg-3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-neg-3,
  .my-xxl-neg-3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-neg-3,
  .mx-xxl-neg-3 {
    margin-left: -1rem !important;
  }
  .m-xxl-neg-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-neg-4,
  .my-xxl-neg-4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-neg-4,
  .mx-xxl-neg-4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-neg-4,
  .my-xxl-neg-4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-neg-4,
  .mx-xxl-neg-4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-neg-5 {
    margin: 3rem !important;
  }
  .mt-xxl-neg-5,
  .my-xxl-neg-5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-neg-5,
  .mx-xxl-neg-5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-neg-5,
  .my-xxl-neg-5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-neg-5,
  .mx-xxl-neg-5 {
    margin-left: -3rem !important;
  }
  .m-xxl-neg-6 {
    margin: 4.5rem !important;
  }
  .mt-xxl-neg-6,
  .my-xxl-neg-6 {
    margin-top: -4.5rem !important;
  }
  .mr-xxl-neg-6,
  .mx-xxl-neg-6 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-neg-6,
  .my-xxl-neg-6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xxl-neg-6,
  .mx-xxl-neg-6 {
    margin-left: -4.5rem !important;
  }
  .m-xxl-neg-7 {
    margin: 6rem !important;
  }
  .mt-xxl-neg-7,
  .my-xxl-neg-7 {
    margin-top: -6rem !important;
  }
  .mr-xxl-neg-7,
  .mx-xxl-neg-7 {
    margin-right: -6rem !important;
  }
  .mb-xxl-neg-7,
  .my-xxl-neg-7 {
    margin-bottom: -6rem !important;
  }
  .ml-xxl-neg-7,
  .mx-xxl-neg-7 {
    margin-left: -6rem !important;
  }
  .m-xxl-neg-8 {
    margin: 7.5rem !important;
  }
  .mt-xxl-neg-8,
  .my-xxl-neg-8 {
    margin-top: -7.5rem !important;
  }
  .mr-xxl-neg-8,
  .mx-xxl-neg-8 {
    margin-right: -7.5rem !important;
  }
  .mb-xxl-neg-8,
  .my-xxl-neg-8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xxl-neg-8,
  .mx-xxl-neg-8 {
    margin-left: -7.5rem !important;
  }
  .m-xxl-neg-9 {
    margin: 9rem !important;
  }
  .mt-xxl-neg-9,
  .my-xxl-neg-9 {
    margin-top: -9rem !important;
  }
  .mr-xxl-neg-9,
  .mx-xxl-neg-9 {
    margin-right: -9rem !important;
  }
  .mb-xxl-neg-9,
  .my-xxl-neg-9 {
    margin-bottom: -9rem !important;
  }
  .ml-xxl-neg-9,
  .mx-xxl-neg-9 {
    margin-left: -9rem !important;
  }
  .m-xxl-neg-10 {
    margin: 10rem !important;
  }
  .mt-xxl-neg-10,
  .my-xxl-neg-10 {
    margin-top: -10rem !important;
  }
  .mr-xxl-neg-10,
  .mx-xxl-neg-10 {
    margin-right: -10rem !important;
  }
  .mb-xxl-neg-10,
  .my-xxl-neg-10 {
    margin-bottom: -10rem !important;
  }
  .ml-xxl-neg-10,
  .mx-xxl-neg-10 {
    margin-left: -10rem !important;
  }
  .m-xxl-neg-11 {
    margin: 12rem !important;
  }
  .mt-xxl-neg-11,
  .my-xxl-neg-11 {
    margin-top: -12rem !important;
  }
  .mr-xxl-neg-11,
  .mx-xxl-neg-11 {
    margin-right: -12rem !important;
  }
  .mb-xxl-neg-11,
  .my-xxl-neg-11 {
    margin-bottom: -12rem !important;
  }
  .ml-xxl-neg-11,
  .mx-xxl-neg-11 {
    margin-left: -12rem !important;
  }
  .m-xxl-neg-12 {
    margin: 14.5rem !important;
  }
  .mt-xxl-neg-12,
  .my-xxl-neg-12 {
    margin-top: -14.5rem !important;
  }
  .mr-xxl-neg-12,
  .mx-xxl-neg-12 {
    margin-right: -14.5rem !important;
  }
  .mb-xxl-neg-12,
  .my-xxl-neg-12 {
    margin-bottom: -14.5rem !important;
  }
  .ml-xxl-neg-12,
  .mx-xxl-neg-12 {
    margin-left: -14.5rem !important;
  }
  .m-xxl-neg-13 {
    margin: 15rem !important;
  }
  .mt-xxl-neg-13,
  .my-xxl-neg-13 {
    margin-top: -15rem !important;
  }
  .mr-xxl-neg-13,
  .mx-xxl-neg-13 {
    margin-right: -15rem !important;
  }
  .mb-xxl-neg-13,
  .my-xxl-neg-13 {
    margin-bottom: -15rem !important;
  }
  .ml-xxl-neg-13,
  .mx-xxl-neg-13 {
    margin-left: -15rem !important;
  }
  .m-xxl-neg-14 {
    margin: 16rem !important;
  }
  .mt-xxl-neg-14,
  .my-xxl-neg-14 {
    margin-top: -16rem !important;
  }
  .mr-xxl-neg-14,
  .mx-xxl-neg-14 {
    margin-right: -16rem !important;
  }
  .mb-xxl-neg-14,
  .my-xxl-neg-14 {
    margin-bottom: -16rem !important;
  }
  .ml-xxl-neg-14,
  .mx-xxl-neg-14 {
    margin-left: -16rem !important;
  }
  .m-xxl-neg-15 {
    margin: 17rem !important;
  }
  .mt-xxl-neg-15,
  .my-xxl-neg-15 {
    margin-top: -17rem !important;
  }
  .mr-xxl-neg-15,
  .mx-xxl-neg-15 {
    margin-right: -17rem !important;
  }
  .mb-xxl-neg-15,
  .my-xxl-neg-15 {
    margin-bottom: -17rem !important;
  }
  .ml-xxl-neg-15,
  .mx-xxl-neg-15 {
    margin-left: -17rem !important;
  }
}
/* ==========================================================================
   Text
   ========================================================================== */
/* Color
========================================================================== */
.text-100 {
  color: #f5f5f5 !important;
}

.text-200 {
  color: #e8e8e9 !important;
}

.text-300 {
  color: #d2d4db !important;
}

.text-400 {
  color: #cdced3 !important;
}

.text-500 {
  color: #87888d !important;
}

.text-600 {
  color: #6c757d !important;
}

.text-700 {
  color: #5a5c63 !important;
}

.text-800 {
  color: #343a40 !important;
}

.text-900 {
  color: #1a1e2c !important;
}

/* Weight
   ========================================================================== */
.fw-thin {
  font-weight: 100 !important;
}

.fw-extra-light {
  font-weight: 200 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-extra-bold {
  font-weight: 800 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.lh-10 {
  line-height: 1;
}

.lh-11 {
  line-height: 1.1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-13 {
  line-height: 1.3;
}

.lh-14 {
  line-height: 1.4;
}

.lh-15 {
  line-height: 1.5;
}

.lh-16 {
  line-height: 1.6;
}

.lh-17 {
  line-height: 1.7;
}

.lh-18 {
  line-height: 1.8;
}

.lh-19 {
  line-height: 1.9;
}

.lh-20 {
  line-height: 2;
}

/* ==========================================================================
   Z-index
   ========================================================================== */
.zindex--1 {
  z-index: -1 !important;
}

.zindex-0 {
  z-index: 0 !important;
}

.zindex-1 {
  z-index: 1 !important;
}

/* ==========================================================================
   Background video
   ========================================================================== */
.bg-video {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 100%;
  pointer-events: none;
  object-fit: cover;
}

/* ==========================================================================
   AOS - Animate on scroll library
   ========================================================================== */
@media (max-width: 991.98px) {
  [data-aos] {
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}
/* ==========================================================================
   Material Icons
   ========================================================================== */
.zmdi, .faq .card-header button::before, .spyre-nav-link.dropdown-toggle::after {
  display: inline-block;
  font-family: "Material-Design-Iconic-Font";
  font-weight: normal;
  font-style: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ==========================================================================
   Prism Syntax highlighter
   ========================================================================== */
:not(pre) > code[class*=language-],
pre[class*=language-] {
  border: 1px solid #d2d4db;
  border-radius: 0;
  background: #f5f5f5;
}

code[class*=language-],
pre[class*=language-] {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 15px;
}

pre[class*=language-] {
  padding-top: 0;
  padding-bottom: 0.5rem;
}

div.code-toolbar {
  margin-bottom: 3rem;
}
div.code-toolbar > .toolbar {
  top: 2px;
  right: 4px;
  padding: 1px 1px 1px 10px;
  opacity: 1 !important;
  background: #f5f5f5;
}
div.code-toolbar > .toolbar .toolbar-item a,
div.code-toolbar > .toolbar .toolbar-item button,
div.code-toolbar > .toolbar .toolbar-item span {
  box-shadow: none;
  border-radius: 3px;
  background: none;
  color: #6c757d;
}
div.code-toolbar > .toolbar .toolbar-item a:hover,
div.code-toolbar > .toolbar .toolbar-item button:hover,
div.code-toolbar > .toolbar .toolbar-item span:hover {
  border-color: #1f2749;
  background: #1f2749;
  color: #fff;
}

/* ==========================================================================
   Carousel
   ========================================================================== */
.owl-carousel {
  /* Navs
  ========================================================================== */
  /* Nav arrows live inside the carousel in the corner */
  /* Nav arrows live on the two edges of the carousel */
  /* Nav arrows live on the two edges of the carousel, but inside */
  /* Nav arrows use different color and smaller size */
  /* Dots
  ========================================================================== */
}
.owl-carousel .owl-nav {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-tap-highlight-color: transparent;
}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem;
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(31, 39, 73, 0.8);
  font-size: 1.3125rem;
  cursor: pointer;
}
.owl-carousel .owl-nav button.owl-prev:hover, .owl-carousel .owl-nav button.owl-next:hover {
  background-color: rgba(255, 255, 255, 0.92);
  color: #1f2749;
  text-decoration: none;
}
.owl-carousel .owl-nav button.owl-prev {
  margin-right: 1px;
}
.owl-carousel .owl-nav .disabled {
  opacity: 0.3;
  cursor: default;
}
.owl-carousel.carousel-nav-edge .owl-nav {
  left: 0;
  right: 0;
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}
.owl-carousel.carousel-nav-edge .owl-nav button.owl-prev {
  margin-right: auto;
  margin-left: 1.5625rem;
}
.owl-carousel.carousel-nav-edge .owl-nav button.owl-next {
  float: right;
  margin-right: 1.5625rem;
}
.owl-carousel.carousel-nav-inside-container .owl-nav {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 100%;
  transform: translate(-50%, -50%);
}
@media (min-width: 576px) {
  .owl-carousel.carousel-nav-inside-container .owl-nav {
    width: 510px;
  }
}
@media (min-width: 768px) {
  .owl-carousel.carousel-nav-inside-container .owl-nav {
    width: 690px;
  }
}
@media (min-width: 992px) {
  .owl-carousel.carousel-nav-inside-container .owl-nav {
    width: 930px;
  }
}
@media (min-width: 1200px) {
  .owl-carousel.carousel-nav-inside-container .owl-nav {
    width: 1110px;
  }
}
@media (min-width: 1600px) {
  .owl-carousel.carousel-nav-inside-container .owl-nav {
    width: 1270px;
  }
}
@media (min-width: 992px) {
  .owl-carousel.carousel-nav-inside-container .owl-nav button.owl-prev {
    margin-right: auto;
    margin-left: -1.5625rem;
  }
  .owl-carousel.carousel-nav-inside-container .owl-nav button.owl-next {
    float: right;
    margin-right: -1.5625rem;
  }
}
.owl-carousel.carousel-nav-primary button.owl-prev, .owl-carousel.carousel-nav-primary button.owl-next {
  width: 4.1875rem;
  height: 2.3125rem;
  line-height: 2.3125rem;
  background-color: rgba(241, 38, 57, 0.7);
  color: rgba(255, 255, 255, 0.8);
}
.owl-carousel.carousel-nav-primary button.owl-prev:hover, .owl-carousel.carousel-nav-primary button.owl-next:hover {
  background-color: rgba(241, 38, 57, 0.92);
  color: #fff;
}
.owl-carousel.carousel-nav-primary button.owl-prev {
  margin-right: auto;
}
.owl-carousel.carousel-nav-primary button.owl-next {
  float: right;
}
@media (min-width: 992px) {
  .owl-carousel.carousel-nav-primary button.owl-prev {
    margin-left: -2.09375rem;
  }
  .owl-carousel.carousel-nav-primary button.owl-next {
    margin-right: -2.09375rem;
  }
}
.owl-carousel .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-carousel .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-carousel .owl-dots .owl-dot span {
  display: block;
  -webkit-backface-visibility: visible;
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  transition: opacity 0.2s ease;
}
.owl-carousel .owl-dots .owl-dot.active span {
  background: #fff;
}

/* ==========================================================================
   Fancybox
   ========================================================================== */
.fancybox-bg {
  background: #1a1e2c;
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.9;
}

.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited {
  background: rgba(26, 30, 44, 0);
  color: #fff;
}

.fancybox-caption {
  background: linear-gradient(to top, rgba(26, 30, 44, 0.85) 0%, rgba(26, 30, 44, 0.3) 50%, rgba(26, 30, 44, 0.15) 65%, rgba(26, 30, 44, 0.075) 75.5%, rgba(26, 30, 44, 0.037) 82.85%, rgba(26, 30, 44, 0.019) 88%, rgba(26, 30, 44, 0) 100%);
  color: #fff;
}

.fancybox-navigation .fancybox-button {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(31, 39, 73, 0.8);
  cursor: pointer;
}
.fancybox-navigation .fancybox-button:hover:not([disabled]) {
  background-color: rgba(255, 255, 255, 0.92);
  color: #1f2749;
}
.fancybox-navigation .fancybox-button[disabled] {
  opacity: 0.3;
  color: rgba(31, 39, 73, 0.8);
  cursor: default;
}
.fancybox-navigation .fancybox-button div {
  padding: 11px;
}

@media (min-width: 992px) {
  .fancybox-slide {
    padding: 80px 120px;
  }
}
/* ==========================================================================
   Rellax - Parallax library
   ========================================================================== */
@media (max-width: 991.98px) {
  .parallax {
    transform: translate3d(0, 0, 0) !important;
  }
}
/* ==========================================================================
   Custom styles
   ========================================================================== */
/**
 * Define you custome style here.
 * It will be merged with the default style.
 */